// @flow

import React, { useRef, useEffect } from 'react'
import glamorous from 'glamorous'
import getContent from 'pp-react-l10n'

import { grey200, HeadingText } from '@paypalcorp/pp-react'

import Events from 'lib/analytics/event-tags'
import { tracking } from 'lib/analytics'
import { phoneLandscapeMaxAndSmaller } from 'lib/styles/media-queries'

import { isPreDefinedSellerCoverPhoto } from 'utils/image-utils'
import { SELLER_FLOW } from 'utils/constants'

import Option from 'screens/shared/components/cover-option'
import {
  getBadPhotoError,
  getUploadPhotoError,
} from '../../seller/error/ui-error-states'

const l10n = getContent('pages/seller/photos')

const Wrapper = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})
const Container = glamorous.div({
  width: '34rem',

  '@media (max-width: 900px)': {
    width: '100%',
  },
})

const OptionsContainer = glamorous.div({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '2.5rem',
})

const OptionContainer = glamorous.div({
  flex: '50%',
  margin: '0.65rem 0',
  boxSizing: 'border-box',
  '&:nth-child(odd)': {
    paddingRight: '0.25rem',
  },
  '&:nth-child(even)': {
    paddingLeft: '0.25rem',
  },
  display: 'flex',
  justifyContent: 'flex-start',

  [phoneLandscapeMaxAndSmaller]: {
    flex: '100%',
    '&:nth-child(odd)': {
      paddingRight: 0,
    },
    '&:nth-child(even)': {
      paddingLeft: 0,
    },
  },
  '@media(max-width: 900px)': {
    justifyContent: 'center',
  },
})

const EditCurrentContainer = glamorous.div({
  borderTop: `0.15rem dotted ${grey200}`,
  paddingTop: '1.5rem',
  marginTop: '1.5rem',
})

type Props = {
  history: {
    push: () => any,
  },
  sellerProfileState: SellerProfileState,
}

function CoverSelect(props: Props) {
  const {
    sellerProfileState: {
      state: {
        photoSettings: {
          initialCoverPhoto,
          cachedCoverPhoto = initialCoverPhoto,
          coverPhotoPan = '50',
          suggestedCoverPhotos = [],
        } = {},
      } = {},
    } = {},
    location: { state: { flow = SELLER_FLOW.create } = {} } = {},
  } = props

  useEffect(() => {
    tracking(Events.public_identity_background_edit_screen_shown({}))
  }, [])

  const STOCK_PHOTOS_URLS = suggestedCoverPhotos.map(photo => {
    // mapping an a11y string for a particular cover photo
    const url = new URL(photo.reference_url)
    const path = url.pathname.split('/')
    const fileName = path[path.length - 1].split('.')[0]
    const a11yPropertyKey = `a11y.label.cover.${fileName}`

    return {
      photoUrl: photo && photo.reference_url,
      photoLabel: l10n(`${a11yPropertyKey}`), // l10n('a11y.label.cover.pink'),
      testId: `${fileName}-cover-photo`,
    }
  })

  const fileInput = useRef(null)

  const backToInitial = () => {
    props.history.push({
      pathname: '/seller/photos',
      state: {
        flow,
      },
    })
  }

  const advanceToPan = () => {
    props.sellerProfileState.setDraggableCoverPhoto(true)
    props.history.push({
      pathname: '/seller/photos/cover/pan',
      state: {
        flow,
      },
    })
  }

  const onImageScanError = decisionMsg => {
    const photoFlow = 'cover'
    props.history.push(getBadPhotoError(decisionMsg, photoFlow))
  }

  const onUploadError = () => {
    const isMediaservError = true,
      photoFlow = 'cover'
    props.history.push(getUploadPhotoError(isMediaservError, photoFlow))
  }

  const onUploadSelect = () => {
    // TODO: Add instrumentation
    fileInput.current.click()
  }

  const onUploadPhoto = (e: SyntheticInputEvent<HTMLInputElement>) => {
    tracking(Events.public_identity_background_edit_upload_pressed({}))
    props.sellerProfileState.pan('50', '0')
    props.sellerProfileState.loadImage(
      e,
      advanceToPan,
      onImageScanError,
      onUploadError,
    )
  }

  const onPaypalStockPhotoSelect = photoUrl => {
    props.sellerProfileState.pan('0', '0')
    props.sellerProfileState.userImagePreviewUrl(photoUrl, '', false)

    backToInitial()
  }

  const onEditCurrentSelect = (e: SyntheticInputEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    props.sellerProfileState.pan(coverPhotoPan, '0')
    props.sellerProfileState.userImagePreviewUrl(cachedCoverPhoto, '', false)

    advanceToPan()
  }

  return (
    <Wrapper>
      <Container>
        <HeadingText size="sm">{l10n('cover.select.header')}</HeadingText>
        <OptionsContainer>
          <OptionContainer>
            <Option isSeller={true} onSelect={onUploadSelect} />
            <input
              ref={fileInput}
              style={{ display: 'none' }}
              type="file"
              accept="image/*"
              onChange={onUploadPhoto}
              data-test-id="upload-cover-photo"
            />
          </OptionContainer>
          {STOCK_PHOTOS_URLS.map(({ photoUrl, photoLabel, testId }) => (
            <OptionContainer key={photoUrl} data-test-id={testId}>
              <Option
                photoUrl={photoUrl}
                photoLabel={photoLabel}
                onSelect={onPaypalStockPhotoSelect}
              />
            </OptionContainer>
          ))}
        </OptionsContainer>
        {!isPreDefinedSellerCoverPhoto(cachedCoverPhoto) && (
          <EditCurrentContainer>
            <button
              className="vx_btn-link"
              onClick={onEditCurrentSelect}
              data-test-id="edit-current-cover-photo"
            >
              {l10n('cover.select.link.editCurrent')}
            </button>
          </EditCurrentContainer>
        )}
      </Container>
    </Wrapper>
  )
}

export default CoverSelect
