// @flow

import React from 'react'
import PropTypes from 'prop-types'
import glamorous from 'glamorous'
import { css } from 'glamor'
import getContent from 'pp-react-l10n'
import { Link } from '@paypalcorp/pp-react'
import Events from 'lib/analytics/event-tags'
import { tracking } from 'lib/analytics'
import LocalizedImg from './localized-img'
import {
  smallAndSmaller,
  phone6PortraitMaxAndSmaller,
} from 'lib/styles/media-queries'

const PageContainer = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const ImgContainer = glamorous.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '34rem',
  minHeight: '19.375rem',

  [smallAndSmaller]: {
    minWidth: '22rem',
  },
  '@media(max-width: 425px)': {
    transform: 'scale(0.95)',
  },
  [phone6PortraitMaxAndSmaller]: {
    transform: 'scale(0.9)',
  },
})

const Img = glamorous.img({
  position: 'relative',
  zIndex: 1,
  [smallAndSmaller]: {
    minWidth: '80%',
  },
})

const Header = glamorous.div({
  marginTop: '2.25rem',
  fontSize: '1.75rem',
  fontWeight: '400',
  textAlign: 'center',
  height: 64,
  [smallAndSmaller]: {
    maxWidth: '20rem',
    fontSize: '1.5rem',
  },
  '@media(max-width: 425px)': {
    maxWidth: '18rem',
    marginTop: '1.75rem',
  },
})

const SubHeader = glamorous.p({
  marginTop: '2.25rem',
  fontSize: '0.875rem',
  textAlign: 'center',
  width: '19rem',
  minHeight: '3.125rem',
  '@media(max-width: 425px)': {
    marginTop: '1.75rem',
  },
})

const SubheaderLinks = css({
  fontSize: '0.875rem',
})

const l10n = getContent('pages/seller/landing')

const pageObjects = [
  {
    image: '',
    header: 'page1.header',
    subheader: '',
  },
  {
    image:
      'https://www.paypalobjects.com/p2p_seller_profiles/seller_landing_page2.png',
    header: 'page2.header',
    subheader: l10n('page2.subheader', {
      buyer: (
        <Link
          href="https://www.paypal.com/us/webapps/mpp/paypal-safety-and-security"
          target="_blank"
          className={`${SubheaderLinks}`}
          onClick={() =>
            tracking(
              Events.public_identity_profile_info_buyer_protection_pressed({
                page_variant: 'protect',
              }),
            )
          }
        >
          {l10n('page2.buyer')}
        </Link>
      ),
      seller: (
        <Link
          href="https://www.paypal.com/us/webapps/mpp/security/seller-protection"
          target="_blank"
          className={`${SubheaderLinks}`}
          onClick={() =>
            tracking(
              Events.public_identity_profile_info_seller_protection_pressed({
                page_variant: 'protect',
              }),
            )
          }
        >
          {l10n('page2.seller')}
        </Link>
      ),
    }),
  },
  {
    image:
      'https://www.paypalobjects.com/p2p_seller_profiles/seller_landing_page3.png',
    header: 'page3.header',
    subheader: (
      <Link
        href="https://www.paypal.com/us/webapps/mpp/merchant-fees#fixed-fees-commercialtrans"
        target="_blank"
        className={`${SubheaderLinks}`}
        onClick={() =>
          tracking(
            Events.public_identity_profile_info_terms_pressed({
              page_variant: 'free',
            }),
          )
        }
      >
        {l10n('page3.subheader')}
      </Link>
    ),
  },
]

PageSwitcher.defaultProps = {
  currentPage: 0,
}

PageSwitcher.propTypes = {
  currentPage: PropTypes.number.isRequired,
}

function PageSwitcher(props) {
  const { currentPage } = props

  return (
    <PageContainer data-test-id="seller-landing-pages">
      <ImgContainer data-test-id="seller-landing-images">
        {currentPage === 0 ? (
          <LocalizedImg />
        ) : (
          <Img
            src={pageObjects[currentPage].image}
            alt={`page ${currentPage}`}
            draggable="false"
          />
        )}
      </ImgContainer>
      <Header>{l10n(`${pageObjects[currentPage].header}`)}</Header>
      <SubHeader>{pageObjects[currentPage].subheader}</SubHeader>
    </PageContainer>
  )
}

export default PageSwitcher
