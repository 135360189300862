/* eslint-disable react/jsx-key */
// @flow

import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { get, isEmpty } from 'lodash'
import { AnimatedSwitchPagination, SimpleLayout } from './async-loading'
import clientData from 'utils/client-data'
import { ThemeProvider } from '@paypalcorp/pp-react'

import SellerProfileStateContainer from './screens/seller/container'

import OnBoardingDetails from './screens/seller/details'
import Social from './screens/seller/social'
import SellerLanding from './screens/seller/landing'
import Success from './screens/seller/success'
import Dashboard from './screens/seller/dashboard'
import Preview from './screens/seller/preview.js'
import SellerSettings from './screens/seller/settings'
import LandingPreview from './screens/seller/landing/preview'
import PhotosUpload from './screens/seller/photos'
import CoverSelect from './screens/seller/photos/cover-select'
import ProfilePhotoPan from './screens/seller/photos/profile-pan'
import BusinessNameSetting from './screens/seller/settings/business-name'
import BusinessDescriptionSetting from './screens/seller/settings/business-description'
import PhoneSetting from './screens/seller/settings/phone'
import EmailSetting from './screens/seller/settings/email'
import LocationSetting from './screens/seller/settings/location'
import ProfileStatusSetting from './screens/seller/settings/profile-status'
import ConfirmProfileStatus from './screens/seller/settings/profile-status/confirm'
import SocialSetting from './screens/seller/settings/social'

import ErrorScreen from './screens/seller/error'

import { checkSellerProfileStatus } from './screens/seller/error/ui-error-states'
import { WithExperienceCheck } from './screens/seller/with-experience-check'

const SellerLandingWithExperienceCheck = WithExperienceCheck(SellerLanding)

// please make sure you also update the routes on node level in server/src/routes/index.js
const overpanelViews = [
  '/seller/landing',
  '/seller/details',
  '/seller/social',
  '/seller/photos',
  '/seller/photos/profile',
  '/seller/photos/cover/select',
  '/seller/photos/cover/pan',
  '/seller/preview',
  '/seller/success',
  '/seller/:slug/dashboard',
  '/seller/:slug/dashboard/preview',
  '/seller/:slug/settings',
  '/seller/:slug/settings/name',
  '/seller/:slug/settings/description',
  '/seller/:slug/settings/phone',
  '/seller/:slug/settings/email',
  '/seller/:slug/settings/location',
  '/seller/:slug/settings/profile',
  '/seller/:slug/settings/profile/confirm',
  '/seller/:slug/settings/social',
  '/seller/error',
  '/seller/error/create/handle',
]

export default () => {
  const isBusinessUser = get(clientData, 'isBusinessUser')

  const isSellerProfileEligible = get(
    clientData,
    'isSellerProfileEligible',
    false,
  )
  const hasSellerProfileDetails = !isEmpty(
    get(clientData, 'sellerProfileDetails', {}),
  )

  const errorMessageType = checkSellerProfileStatus({
    isSellerProfileEligible,
    isBusinessUser,
    hasSellerProfileDetails,
  })

  return (
    <Route exact path={overpanelViews}>
      <SimpleLayout>
        <AnimatedSwitchPagination>
          {/*
           * Landing Page - (Carousel) - /seller/landing
           * Onboarding Step 1 - /seller/details
           * Onboarding Step 2 - /seller/social
           * Photo upload - /seller/photos
           * Cover photo upload - (reuse from personal) - /my/cover/select
           * Onboarding Preview - /seller/preview
           * Onboarding Success - /seller/success
           */}
          <SellerRoute
            exact
            path="/seller/landing"
            component={SellerLandingWithExperienceCheck}
            customProps={{
              showExperience: isSellerProfileEligible,
              errorType: errorMessageType,
            }}
          />
          {isSellerProfileEligible && [
            <SellerRoute
              exact
              path="/seller/landing"
              component={SellerLanding}
            />,
            <SellerRoute
              exact
              path="/seller/details"
              component={OnBoardingDetails}
            />,
            <SellerRoute exact path="/seller/social" component={Social} />,
            <SellerRoute
              exact
              path="/seller/preview"
              component={LandingPreview}
            />,
            <SellerRoute exact path="/seller/success" component={Success} />,
            <SellerRoute
              exact
              path="/seller/error/create/handle"
              component={ErrorScreen}
            />,
          ]}

          {(isSellerProfileEligible || hasSellerProfileDetails) && [
            <SellerRoute
              exact
              path="/seller/photos"
              component={PhotosUpload}
            />,
            <SellerRoute
              exact
              path="/seller/photos/cover/select"
              component={CoverSelect}
            />,
            <SellerRoute
              exact
              path="/seller/photos/cover/pan"
              component={PhotosUpload}
            />,
            <SellerRoute
              exact
              path="/seller/photos/profile"
              component={ProfilePhotoPan}
            />,
          ]}

          {/* <SellerRoute exact path="/seller/error" component={ErrorScreen} /> */}
          <Route
            exact
            path="/seller/error"
            render={props => <ErrorScreen {...props} />}
          />

          {/*
           * Dashboard - (Seller Profile Management) -  /seller/dashboard
           * Dashboard -> Preview (Preview what public will see) - /seller/dashboard/preview
           * Dashboard -> Edit ( Edit settings/details ) - /seller/settings
           */}
          {hasSellerProfileDetails && [
            <SellerRoute
              exact
              path="/seller/:slug/dashboard"
              component={Dashboard}
            />,
            <SellerRoute
              exact
              path="/seller/:slug/dashboard/preview"
              component={Preview}
            />,
            <SellerRoute
              exact
              path="/seller/:slug/settings"
              component={SellerSettings}
            />,
            <SellerRoute
              exact
              path="/seller/:slug/settings/name"
              component={BusinessNameSetting}
            />,
            <SellerRoute
              exact
              path="/seller/:slug/settings/description"
              component={BusinessDescriptionSetting}
            />,
            <SellerRoute
              exact
              path="/seller/:slug/settings/phone"
              component={PhoneSetting}
            />,
            <SellerRoute
              exact
              path="/seller/:slug/settings/email"
              component={EmailSetting}
            />,
            <SellerRoute
              exact
              path="/seller/:slug/settings/location"
              component={LocationSetting}
            />,
            <SellerRoute
              exact
              path="/seller/:slug/settings/profile"
              component={ProfileStatusSetting}
            />,
            <SellerRoute
              exact
              path="/seller/:slug/settings/profile/confirm"
              component={ConfirmProfileStatus}
            />,
            <SellerRoute
              exact
              path="/seller/:slug/settings/social"
              component={SocialSetting}
            />,
            <Route
              path={['/seller/landing', '/seller/details', '/seller/preview']}
            ></Route>,
          ]}
          {/* <SellerRoute exact path="/seller/error" component={ErrorScreen} /> */}

          {isSellerProfileEligible && (
            <Route path="/seller/">
              <Redirect to="/seller/landing" />
            </Route>
          )}

          <SellerRoute exact path="/seller/error" component={ErrorScreen} />
          <Route path="/seller">
            <Redirect
              to={{
                pathname: '/seller/error',
                state: {
                  error: {
                    illustrationKey: 'warning',
                    headerKey: 'ineligible',
                    subHeaderKey: null,
                    ctaButtonKey: 'ineligible',
                  },
                },
              }}
            />
          </Route>
        </AnimatedSwitchPagination>
      </SimpleLayout>
    </Route>
  )
}

type ReactComponent = () => React.Element | React.Component

function SellerRoute({
  component: Comp,
  customProps = {},
  ...props
}: {
  component: ReactComponent,
}) {
  return (
    <Route
      exact
      render={routerProps => (
        <>
          <ThemeProvider theme="v2">
            <SellerProfileStateContainer
              {...routerProps}
              body={<Comp {...routerProps} {...customProps} />}
              renderErrorScreen={<ErrorScreen />}
            />
          </ThemeProvider>
        </>
      )}
      {...props}
    />
  )
}
