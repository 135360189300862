import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import glamorous from 'glamorous'
import { get } from 'lodash'
import { HeadingText, Divider, Dropdown, Button } from '@paypalcorp/pp-react'
import SocialMediaLink from '../shared/components/social-media-link'
import clientData from '../../../../client/src/utils/client-data'
import getContent from 'pp-react-l10n'
import Events from '../../lib/analytics/event-tags'
import { tracking } from '../../lib/analytics'
import { phone6PlusPortraitMaxAndSmaller } from 'lib/styles/media-queries'
import { normalizeFacebookURL } from '../shared/socialUtils'
import { buttonsInitialData } from 'utils/constants'

const l10n = getContent('pages/seller/social')

const Container = glamorous.div({
  margin: '10px',
  '& > hr': {
    margin: '30px 0',
  },
  [phone6PlusPortraitMaxAndSmaller]: {
    margin: 0,
  },
  '& .nextButton': {
    textAlign: 'center',
    '& > button': {
      width: 300,
      marginTop: 48,
    },
  },
})

const FormField = glamorous.div({
  margin: '20px 0',
})

// this method formats the location data we receive from the backend
// to whatever is received by the dropdown react component
const locationDataFormatted = (locationData = []) => {
  const result = []
  locationData.forEach(location => {
    if (location.display_address) {
      result.push({
        label: location.display_address,
        value: location.display_address,
        'data-format': location.format,
      })
    }
  })
  result.push({
    label: l10n('doNotShowLocation'),
    value: 'donotshow',
    'data-format': ['NONE'],
  })
  return result
}

Social.propTypes = {
  phoneData: PropTypes.array,
  emailData: PropTypes.array,
  locationData: PropTypes.array,
}

Social.defaultProps = {
  phoneData: [
    {
      label: clientData.evaluateProfileData?.displayPhone,
      value: clientData.evaluateProfileData?.displayPhone,
    },
    {
      label: l10n('doNotShow', {
        value: clientData.evaluateProfileData?.displayPhone,
      }),
      value: 'donotshow',
    },
  ],
  emailData: [
    {
      label: clientData.evaluateProfileData?.displayEmail,
      value: clientData.evaluateProfileData?.displayEmail,
    },
    {
      label: l10n('doNotShow', {
        value: clientData.evaluateProfileData?.displayEmail,
      }),
      value: 'donotshow',
    },
  ],
  locationData: locationDataFormatted(
    clientData.evaluateProfileData?.locationData,
  ),
}

const formFieldsMap = {
  instagram: 'instagramHandle',
  facebook: 'facebookUrl',
  twitter: 'twitterHandle',
  phoneData: 'phone',
  emailData: 'email',
  locationData: 'location',
}

function Social(props) {
  const {
    sellerProfileState: {
      state: {
        socialMedia: socialMediaData = {},
        contactsOption: contactsOptionData = {},
      } = {},
    } = {},
  } = props

  const [isFormValid, setIsFormValid] = useState(false)

  const [smUserInput, setSmUserInput] = useState({
    instagram: socialMediaData.instagramHandle,
    facebook: socialMediaData.facebookUrl,
    twitter: socialMediaData.twitterHandle,
  })

  const [contactsOption, setContactsOption] = useState({
    phoneData: contactsOptionData.phone ?? props.phoneData[0].value,
    emailData: contactsOptionData.email ?? props.emailData[0].value,
    locationData: contactsOptionData.location ?? props.locationData[0].value,
    locationFormat:
      contactsOptionData.locationFormat ?? props.locationData[0]['data-format'],
  })

  const handleDropdown = e => {
    const userInput = e.target.value
    const dropdownName = e.target.dataset.name
    setContactsOption({
      ...contactsOption,
      [dropdownName]: userInput,
      ...(dropdownName === 'locationData' && {
        locationFormat: get(
          e,
          'target.selectedOptions[0].dataset.format',
          [],
        ).split(','),
      }),
    })

    const updates = {
      contactsOption: {
        ...contactsOptionData,
        [formFieldsMap[dropdownName]]: userInput,
        ...(dropdownName === 'locationData' && {
          locationFormat: get(
            e,
            'target.selectedOptions[0].dataset.format',
            [],
          ).split(','),
        }),
      },
    }
    props.sellerProfileState.updateBusinessDetails(updates)
  }

  const advanceToPhotoUpload = () => {
    // Normalize the facebook URL to standard format
    const normalizedFacebookURL = normalizeFacebookURL(smUserInput.facebook)

    const updates = {
      socialMedia: {
        instagramHandle: smUserInput.instagram,
        facebookUrl: normalizedFacebookURL,
        twitterHandle: smUserInput.twitter,
      },
      contactsOption: {
        phone: contactsOption.phoneData,
        email: contactsOption.emailData,
        location: contactsOption.locationData,
        locationFormat: contactsOption.locationFormat,
      },
    }

    // store the data in unstated
    props.sellerProfileState.updateBusinessDetails(updates)
    // instrumentation
    tracking(Events.public_identity_profile_create_social_next_pressed({}))
    // advance to the next screen
    props.history.push('/seller/photos')
  }

  // update the social media component data on user input
  useEffect(() => {
    buttonsInitialData.instagram = {
      ...buttonsInitialData.instagram,
      doneState: !!socialMediaData.instagramHandle,
      textValue: smUserInput.instagram,
    }
    buttonsInitialData.twitter = {
      ...buttonsInitialData.twitter,
      doneState: !!socialMediaData.twitterHandle,
      textValue: smUserInput.twitter,
    }
    buttonsInitialData.facebook = {
      ...buttonsInitialData.facebook,
      doneState: !!socialMediaData.facebookUrl,
      textValue: smUserInput.facebook,
    }
  })

  // hide the social media input field on back button
  useEffect(() => {
    buttonsInitialData.instagram = {
      ...buttonsInitialData.instagram,
      visibleInput: false,
    }
    buttonsInitialData.twitter = {
      ...buttonsInitialData.twitter,
      visibleInput: false,
    }
    buttonsInitialData.facebook = {
      ...buttonsInitialData.facebook,
      visibleInput: false,
    }
  }, [])

  useEffect(() => {
    tracking(Events.public_identity_profile_create_social_screen_shown({}))
  }, [])

  const setSocialMediaUserInput = (key, value) => {
    // set the valid input value
    setSmUserInput(prevState => ({
      ...prevState,
      [key]: value,
    }))
    const updates = {
      socialMedia: {
        ...socialMediaData,
        [formFieldsMap[key]]: value,
      },
    }
    props.sellerProfileState.updateBusinessDetails(updates)
  }

  return (
    <Container data-test-id="seller-social-screen">
      <HeadingText size="md" role="heading" aria-level="1">
        {l10n('title')}
      </HeadingText>
      <p>{l10n('info')}</p>
      <Divider />
      <SocialMediaLink
        handleValidState={setIsFormValid}
        handleChangeInput={setSocialMediaUserInput}
        buttonsInitialData={buttonsInitialData}
      />
      <Divider />
      <p>{l10n('formTitle')}</p>
      <FormField>
        <Dropdown
          label={l10n('formPhone')}
          options={props.phoneData}
          onChange={handleDropdown}
          data-name="phoneData"
          value={contactsOption.phoneData}
        />
      </FormField>
      <FormField>
        <Dropdown
          label={l10n('formEmail')}
          options={props.emailData}
          onChange={handleDropdown}
          data-name="emailData"
          value={contactsOption.emailData}
        />
      </FormField>
      <FormField>
        <Dropdown
          label={l10n('formLocation')}
          options={props.locationData}
          onChange={handleDropdown}
          data-name="locationData"
          value={contactsOption.locationData}
        />
      </FormField>
      {isFormValid ? (
        <div className="nextButton">
          <Button data-test-id="next" onClick={advanceToPhotoUpload}>
            {l10n('nextButton')}
          </Button>
        </div>
      ) : null}
    </Container>
  )
}
export default Social
