// @flow

import React, { useState, useEffect, useRef } from 'react'
import glamorous, { Img } from 'glamorous'
import { css } from 'glamor'
import getContent from 'pp-react-l10n'
import {
  HeadingText,
  BodyText,
  v2neutral200,
  v2grey600,
  textprimary,
} from '@paypalcorp/pp-react'

const baseHeight = 224
const ellipseOffset = 13
const panelTopOffset = 87

const ImgContainer = glamorous.div(
  {
    width: '100%',
    height: 300,
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    zIndex: 1,
  },
  ({ textHeight }) => ({
    height: `calc(${baseHeight}px + ${textHeight}px)`,
  }),
)

const DeviceImgContainer = glamorous.div(
  {
    height: '100%',
    position: 'absolute',
    width: 238,
    top: 0,
    overflowY: 'hidden',
    zIndex: 1,
  },
  ({ textHeight }) => ({
    height: `calc(${baseHeight}px + ${textHeight}px - ${ellipseOffset}px)`,
  }),
)

const DeviceImg = css({
  width: '100%',
})

const UserImg = css({
  position: 'absolute',
  top: 55,
  transform: 'translateX(-55px)',
  zIndex: 5,
})

const PanelImgContainer = glamorous.div(
  {
    height: '100%',
    position: 'absolute',
    width: 224,
    top: panelTopOffset,
    overflowY: 'hidden',
    zIndex: 4,
  },
  ({ textHeight }) => ({
    height: `calc(${baseHeight}px + ${textHeight}px - ${ellipseOffset}px - ${panelTopOffset}px)`,
  }),
)

const PanelImg = css({
  width: '100%',
})

const SocialImg = css({
  position: 'absolute',
  bottom: 30,
  zIndex: 4,
  paddingTop: 18,
  borderTop: `1px solid ${v2neutral200}`,
})

const EllipseImg = css({
  position: 'absolute',
  bottom: 0,
})

const ImgTextContainer = css({
  userSelect: 'none',
  position: 'absolute',
  top: 135,
  width: 176,
  zIndex: 5,
})

const ImgHeading = css({
  fontSize: '1.25em',
  color: textprimary,
})

const ImgSubHeading = css({
  fontSize: '0.75em',
  color: v2grey600,
})

const l10n = getContent('pages/seller/landing')

const images = {
  deviceImg:
    'https://www.paypalobjects.com/p2p_seller_profiles/landing_page1_images/device.png',
  userImg:
    'https://www.paypalobjects.com/p2p_seller_profiles/landing_page1_images/img.png',
  panelImg:
    'https://www.paypalobjects.com/p2p_seller_profiles/landing_page1_images/panel.png',
  socialImg:
    'https://www.paypalobjects.com/p2p_seller_profiles/landing_page1_images/Social.png',
  ellipseImg:
    'https://www.paypalobjects.com/p2p_seller_profiles/landing_page1_images/ellipse.png',
}

function LocalizedImg() {
  const [imgTextHeight, setImgTextHeight] = useState(0)
  const imgTextRef = useRef(null)

  useEffect(() => {
    setImgTextHeight(imgTextRef.current.clientHeight)
    // prefetch images
    const imagesPreload = Object.values(images)
    imagesPreload.forEach(image => {
      const newImage = new Image()
      newImage.src = image
      window[image] = newImage
    })
  })

  return (
    <ImgContainer textHeight={imgTextHeight}>
      <DeviceImgContainer textHeight={imgTextHeight}>
        <Img
          draggable="false"
          src={images.deviceImg}
          className={`${DeviceImg}`}
        />
      </DeviceImgContainer>
      <Img draggable="false" src={images.userImg} className={`${UserImg}`} />
      <PanelImgContainer textHeight={imgTextHeight}>
        <Img
          draggable="false"
          src={images.panelImg}
          className={`${PanelImg}`}
        />
      </PanelImgContainer>
      <Img
        draggable="false"
        src={images.socialImg}
        className={`${SocialImg}`}
      />
      <Img
        draggable="false"
        src={images.ellipseImg}
        className={`${EllipseImg}`}
      />
      <div ref={imgTextRef} className={`${ImgTextContainer}`}>
        <HeadingText size="sm" className={`${ImgHeading}`}>
          {l10n('page1.image.header')}
        </HeadingText>
        <BodyText className={`${ImgSubHeading}`}>
          {l10n('page1.image.subheader1')}
        </BodyText>
        <BodyText className={`${ImgSubHeading}`}>
          {l10n('page1.image.subheader2')}
        </BodyText>
      </div>
    </ImgContainer>
  )
}

export default LocalizedImg
