import { get } from 'lodash'

import { addLoader } from '../../../utils/loader'
import { getDomain } from 'utils/generate-urls'
import clientData from '../../../utils/client-data'

import Events from 'lib/analytics/event-tags'
import { tracking } from 'lib/analytics'

const DEFAULT_PATH = '/seller/error'

const UI_ERROR_KEYS = {
  bizHasSellerProfile: 'BUSINESS_ACCOUNT_ALREADY_HAS_SELLER_PROFILE',
  userHasSellerProfile: 'USER_ACCOUNT_ALREADY_HAS_SELLER_PROFILE',
  userNotEligible: 'USER_IS_NOT_ELIGIBLE_FOR_SELLER_PROFILE',
  shopProductDetailError: 'SHOP_PRODUCT_DETAIL_ERROR',
  badPhotoError: 'BAD_PHOTO_ERROR',
  uploadPhotoError: 'UPLOAD_PHOTO_ERROR',
}

const UI_ERROR_PARAMS = {
  badPhotoError: {},
  uploadPhotoError: {},
}

const UI_ERROR_STATES = {
  BUSINESS_ACCOUNT_ALREADY_HAS_SELLER_PROFILE: {
    pathname: `${DEFAULT_PATH}`,
    actionHandlers: {
      ctaButtonClick: () => {
        addLoader()
        const host = getDomain(clientData)
        window.location.href = `${host}/myaccount/settings`
      },
      trackErrorImpression: () => {
        tracking(
          Events.public_identity_seller_profile_generic_error_occurred({
            acct_type: 'business',
            error_desc: 'user already has a profile',
          }),
        )
      },
    },
    state: {
      error: {
        illustrationKey: 'warning',
        headerKey: 'alreadyHasSellerProfileBusiness',
        subHeaderKey: null,
        ctaButtonKey: 'alreadyHasSellerProfileBusiness',
      },
    },
  },
  USER_ACCOUNT_ALREADY_HAS_SELLER_PROFILE: {
    pathname: `${DEFAULT_PATH}`,
    actionHandlers: {
      ctaButtonClick: props => {
        const businessHandle = get(clientData, 'sellerProfileDetails.id')
        props.history.push(`/seller/${businessHandle}/dashboard`)
      },
      trackErrorImpression: () => {
        tracking(
          Events.public_identity_seller_profile_generic_error_occurred({
            acct_type: 'personal',
            error_desc: 'user already has a profile',
          }),
        )
      },
    },
    state: {
      error: {
        illustrationKey: 'warning',
        headerKey: 'alreadyHasSellerProfile',
        subHeaderKey: null,
        ctaButtonKey: 'alreadyHasSellerProfile',
      },
    },
  },
  USER_IS_NOT_ELIGIBLE_FOR_SELLER_PROFILE: {
    pathname: `${DEFAULT_PATH}`,
    actionHandlers: {
      ctaButtonClick: () => {
        addLoader()
        const host = getDomain(clientData)
        window.location.href = `${host}/myaccount/settings`
      },
    },
    state: {
      error: {
        illustrationKey: 'warning',
        headerKey: 'ineligible',
        subHeaderKey: null,
        ctaButtonKey: 'ineligible',
      },
    },
  },
  SHOP_PRODUCT_DETAIL_ERROR: {
    pathname: `${DEFAULT_PATH}`,
    actionHandlers: {
      ctaButtonClick: props => {
        const slug = get(clientData, 'shop.slug', '') || ''
        addLoader()
        props.history.push(`/${slug}`)
      },
    },
    state: {
      error: {
        headerKey: 'productError',
        subHeaderKey: 'productError',
        ctaButtonKey: 'productError',
      },
    },
  },
  BAD_PHOTO_ERROR: {
    pathname: `${DEFAULT_PATH}`,
    actionHandlers: {
      ctaButtonClick: props => {
        tracking(
          Events.public_identity_photo_upload_error_retry_pressed({
            error_source: 'image_screen',
            error_code: UI_ERROR_PARAMS.badPhotoError.decisionMsg,
            photo_uploaded_flag: UI_ERROR_PARAMS.badPhotoError.photoFlow,
          }),
        )
        return props.history.goBack()
      },
      trackErrorImpression: () => {
        tracking(
          Events.public_identity_photo_upload_error_screen_shown({
            error_source: 'image_screen',
            error_code: UI_ERROR_PARAMS.badPhotoError.decisionMsg,
            photo_uploaded_flag: UI_ERROR_PARAMS.badPhotoError.photoFlow,
          }),
        )
      },
    },
    state: {
      error: {
        headerKey: 'badPhoto',
        subHeaderKey: null,
        ctaButtonKey: 'badPhoto',
        instrumentationObj: {
          decisionMsg: UI_ERROR_PARAMS.badPhotoError.decisionMsg,
          photoFlow: UI_ERROR_PARAMS.badPhotoError.photoFlow,
        },
      },
    },
  },
  UPLOAD_PHOTO_ERROR: {
    pathname: `${DEFAULT_PATH}`,
    actionHandlers: {
      ctaButtonClick: props => {
        UI_ERROR_PARAMS.uploadPhotoError.isMediaservError
          ? tracking(
              Events.public_identity_photo_upload_error_retry_pressed({
                error_source: 'mediaserv',
                error_code: 'SERVICE_ERROR',
                photo_uploaded_flag: UI_ERROR_PARAMS.uploadPhotoError.photoFlow,
              }),
            )
          : tracking(
              Events.public_identity_failed_customer_interaction_occurred(),
            )
        return props.history.goBack()
      },
      trackErrorImpression: () => {
        UI_ERROR_PARAMS.uploadPhotoError.isMediaservError
          ? tracking(
              Events.public_identity_photo_upload_error_screen_shown({
                error_source: 'mediaserv',
                error_code: 'SERVICE_ERROR',
                photo_uploaded_flag: UI_ERROR_PARAMS.uploadPhotoError.photoFlow,
              }),
            )
          : tracking(
              Events.public_identity_failed_customer_interaction_occurred(),
            )
      },
    },
    state: {
      error: {
        headerKey: 'uploadPhoto',
        subHeaderKey: null,
        ctaButtonKey: 'uploadPhoto',
        instrumentationObj: {
          isMediaservError: UI_ERROR_PARAMS.uploadPhotoError.isMediaservError,
          photoFlow: UI_ERROR_PARAMS.uploadPhotoError.photoFlow,
        },
      },
    },
  },
}

/*
 * Hi! Need to return a new key? You should probably write a new function
 * for your specific use case that has special logic.
 */
const checkSellerProfileStatus = flags => {
  const {
    isSellerProfileEligible,
    isBusinessUser,
    hasSellerProfileDetails,
  } = flags

  if (hasSellerProfileDetails) {
    if (isBusinessUser) {
      return UI_ERROR_KEYS['bizHasSellerProfile']
    } else {
      return UI_ERROR_KEYS['userHasSellerProfile']
    }
  } else {
    if (!isSellerProfileEligible) {
      return UI_ERROR_KEYS['userNotEligible']
    }
  }
}

const getShopProductDetailError = () => {
  return UI_ERROR_KEYS['shopProductDetailError']
}

const getBadPhotoError = (decisionMsg = '', photoFlow = 'profile') => {
  UI_ERROR_PARAMS.badPhotoError = { decisionMsg, photoFlow }
  return UI_ERROR_STATES[UI_ERROR_KEYS['badPhotoError']]
}

const getUploadPhotoError = (
  isMediaservError = false,
  photoFlow = 'profile',
) => {
  UI_ERROR_PARAMS.uploadPhotoError = { isMediaservError, photoFlow }
  return UI_ERROR_STATES[UI_ERROR_KEYS['uploadPhotoError']]
}

export {
  UI_ERROR_STATES,
  checkSellerProfileStatus,
  getShopProductDetailError,
  getBadPhotoError,
  getUploadPhotoError,
}
