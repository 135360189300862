// @flow
import React from 'react'
import glamorous from 'glamorous'
import FlipMove from 'react-flip-move'
import Availability from '../../seller/details/availability'

const SuggestionsContainer = glamorous.div({
  marginTop: '1rem',
})
const Suggestions = props => {
  const { onSelectSuggestion, suggestions } = props

  return (
    <>
      <SuggestionsContainer data-test-id="slugSuggestions">
        <FlipMove
          enterAnimation={'fade'}
          leaveAnimation={'fade'}
          duration={150}
          easing="ease-out"
        >
          <Availability
            onSelect={onSelectSuggestion}
            suggestions={suggestions}
          />
        </FlipMove>
      </SuggestionsContainer>
    </>
  )
}

export default Suggestions
