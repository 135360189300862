import React, { useEffect } from 'react'
import glamorous from 'glamorous'
import to from 'await-to-js'
import { css } from 'glamor'
import {
  WarningIcon,
  orange500,
  HeadingText,
  BodyText,
  Button,
  Link,
  v2grey600,
} from '@paypalcorp/pp-react'
import getContent from 'pp-react-l10n'
import Events from 'lib/analytics/event-tags'
import { tracking } from 'lib/analytics'
import { phone6PlusPortraitMaxAndSmaller } from 'lib/styles/media-queries'
import { showServiceError } from './../../error/util'

const l10n = getContent('pages/seller/settings')

const Container = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '10px',
  [phone6PlusPortraitMaxAndSmaller]: {
    margin: 0,
  },
})

const warningIconStyle = css({
  marginTop: 60,
  fontSize: '6rem',
  color: orange500,
  [phone6PlusPortraitMaxAndSmaller]: {
    marginTop: 20,
  },
})

const Heading = css({
  marginTop: 40,
  textAlign: 'center',
  width: '100%',
  [phone6PlusPortraitMaxAndSmaller]: {
    marginTop: 30,
  },
})

const SubHeading = css({
  marginTop: 16,
  width: '65%',
  color: v2grey600,
  textAlign: 'center',
  [phone6PlusPortraitMaxAndSmaller]: {
    width: '90%',
  },
})

const ConfirmBtn = css({
  textAlign: 'center',
  width: 300,
  marginTop: 40,
  [phone6PlusPortraitMaxAndSmaller]: {
    marginTop: 36,
    width: 280,
  },
  '@media(max-width: 374px)': {
    marginTop: 32,
    width: 265,
  },
  '@media(max-width: 325px)': {
    marginTop: 28,
    width: 250,
  },
})

const CancelBtn = css({
  marginTop: 30,
  [phone6PlusPortraitMaxAndSmaller]: {
    marginTop: 24,
  },
})

function ConfirmProfileStatus(props) {
  const {
    sellerProfileState: {
      state: { sellerProfileDetails: { id: businessHandle = '' } = {} } = {},
    } = {},
  } = props
  const { turnOffSellerProfile } = props.sellerProfileState

  useEffect(() => {
    tracking(Events.public_identity_hide_profile_confirmation_screen_shown({}))
  }, [])

  const routeToSettings = async () => {
    tracking(
      Events.public_identity_hide_profile_confirmation_turnoff_pressed({}),
    )
    const [err] = await to(turnOffSellerProfile(businessHandle))

    if (err) {
      showServiceError(
        err,
        props.history,
        'seller-profile-settings-hide-profile-confirmation',
      )
    } else {
      // navigate back to settings page and not profile status
      props.history.go(-2)
    }
  }

  const routeToProfile = e => {
    e.preventDefault()
    tracking(
      Events.public_identity_hide_profile_confirmation_cancel_pressed({}),
    )
    props.history.goBack()
  }

  return (
    <Container data-test-id="seller-settings-status-confirm-screen">
      <WarningIcon className={`${warningIconStyle}`} />
      <HeadingText
        size="md"
        role="heading"
        aria-level="1"
        className={`${Heading}`}
      >
        {l10n('edit.status.confirm.header')}
      </HeadingText>
      <BodyText className={`${SubHeading}`}>
        {l10n('edit.status.confirm.subheader')}
      </BodyText>
      <Button
        className={`${ConfirmBtn}`}
        onClick={routeToSettings}
        data-test-id="seller-settings-status-confirm"
      >
        {l10n('edit.status.confirm.btn')}
      </Button>
      <Link
        title="Primary"
        className={`${CancelBtn}`}
        onClick={routeToProfile}
        data-test-id="seller-settings-status-cancel"
      >
        {l10n('edit.status.confirm.cancel')}
      </Link>
    </Container>
  )
}

export default ConfirmProfileStatus
