import React, { useState, useRef, useEffect } from 'react'
import { css } from 'glamor'
import { HeadingText, TextInput } from '@paypalcorp/pp-react'
import getContent from 'pp-react-l10n'
import Events from 'lib/analytics/event-tags'
import { tracking } from 'lib/analytics'
import { phone6PlusPortraitMaxAndSmaller } from 'lib/styles/media-queries'
import SaveButton from '../shared/save-button'
import { showServiceError } from './../error/util'
import HandleMoreInfoLink from '../details/handle-more-info-link'

const l10n = getContent('pages/seller/settings')

const Heading = css({
  margin: '80px 0 16px',
  [phone6PlusPortraitMaxAndSmaller]: {
    marginTop: 20,
  },
})

const TextStyle = css({
  marginTop: 20,
})

const Center = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})

function BusinessNameSetting(props) {
  const {
    sellerProfileState: {
      state: {
        sellerProfileDetails: {
          id: businessHandle = '',
          user_info: { alternate_full_name: businessName = '' } = {},
        } = {},
      } = {},
    } = {},
  } = props

  useEffect(() => {
    tracking(Events.public_identity_profile_edit_name_screen_shown({}))
  }, [])

  const [value, setValue] = useState(businessName)
  const { editSellerSettings } = props.sellerProfileState
  const { current: initialValue } = useRef(value)
  const isChanged = value !== initialValue

  const handleChangeInput = e => {
    setValue(e.target.value)
  }

  const handleSave = async () => {
    tracking(Events.public_identity_profile_edit_name_save_pressed({}))

    const valueObj = [
      {
        op: 'replace',
        path: '/user_info/alternate_full_name',
        value,
      },
    ]

    try {
      await editSellerSettings(valueObj, businessHandle)
      props.history.goBack()
    } catch (err) {
      showServiceError(
        err,
        props.history,
        'seller-profile-settings-edit-business-name',
      )
    }
  }
  return (
    <>
      <HeadingText
        size="md"
        role="heading"
        aria-level="1"
        className={`${Heading}`}
      >
        {l10n('businessName')}
      </HeadingText>
      <HandleMoreInfoLink />
      <TextInput
        name="businessName"
        label={l10n('businessName')}
        value={value}
        onChange={handleChangeInput}
        autoComplete="off"
        className={`${TextStyle}`}
        data-test-id="seller-settings-name-input"
      />
      {isChanged && (
        <div className={`${Center}`}>
          <SaveButton handleSave={handleSave} />
        </div>
      )}
    </>
  )
}

export default BusinessNameSetting
