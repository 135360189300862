// @flow
import createPrefixer from 'inline-style-prefixer/static/createPrefixer'

import flexboxIE from 'inline-style-prefixer/static/plugins/flexboxIE'
import transition from 'inline-style-prefixer/static/plugins/transition'

const ms = ['ms']

const staticData = {
  plugins: [flexboxIE, transition],
  prefixMap: {
    flex: ms,
    flexDirection: ms,
    flexFlow: ms,
    flexWrap: ms,
    userSelect: ms,
    wrapFlow: ms,
    wrapThrough: ms,
    wrapMargin: ms,
    scrollSnapType: ms,
    scrollSnapPointsX: ms,
    scrollSnapPointsY: ms,
    scrollSnapDestination: ms,
    scrollSnapCoordinate: ms,
    touchAction: ms,
    hyphens: ms,
    flowInto: ms,
    flowFrom: ms,
    breakBefore: ms,
    breakAfter: ms,
    breakInside: ms,
    regionFragment: ms,
    gridTemplateColumns: ms,
    gridTemplateRows: ms,
    gridTemplateAreas: ms,
    gridTemplate: ms,
    gridAutoColumns: ms,
    gridAutoRows: ms,
    gridAutoFlow: ms,
    grid: ms,
    gridRowStart: ms,
    gridColumnStart: ms,
    gridRowEnd: ms,
    gridRow: ms,
    gridColumn: ms,
    gridColumnEnd: ms,
    gridColumnGap: ms,
    gridRowGap: ms,
    gridArea: ms,
    gridGap: ms,
    textSizeAdjust: ms,
    writingMode: ms,
  },
}

const prefixAll = createPrefixer(staticData)

export default function IEPrefixes(node) {
  return Object.assign({}, node, { style: prefixAll(node.style) })
}
