/**
 * Normalize facebook URLs of all patterns to https://www.facebook.com/{handle}
 */

import { SELLER_REGEX } from 'utils/constants'

export function normalizeFacebookURL(inputURL) {
  const facebookUserId =
    (inputURL && inputURL.match(SELLER_REGEX.facebook)[1]) || ''
  return (facebookUserId && `https://www.facebook.com/${facebookUserId}`) || ''
}
