import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import { css } from 'glamor'
import glamorous from 'glamorous'

import {
  HeadingText,
  BodyText,
  Dialog,
  Sheet,
  Button,
  LoadingSpinner,
} from '@paypalcorp/pp-react'

import clientData from 'utils/client-data'

// content
import getContent from 'pp-react-l10n'
import { showServiceError } from '../error/util'
import { tracking } from '../../../lib/analytics'
import Events from '../../../lib/analytics/event-tags'
import { phone6PlusPortraitMaxAndSmaller } from '../../../lib/styles/media-queries'
import { SELLER_FLOW } from '../../../utils/constants'
import {
  getBadPhotoError,
  getUploadPhotoError,
} from '../../seller/error/ui-error-states'
const l10n = getContent('pages/seller/photos')

const DialogContent = glamorous.div(({ isLoadingPhoto = false }) => ({
  ...(isLoadingPhoto && { opacity: '50%' }),
}))

const SpinnerContainer = glamorous.div({
  position: 'absolute',
  left: '50%',
  top: '40%',
  transform: 'translate(-50%,-50%)',
  zIndex: '1',
})

const DialogBodyText = css({
  margin: '43px',
  padding: '0 20px',
  [phone6PlusPortraitMaxAndSmaller]: {
    margin: '20px 0',
    padding: '0',
  },
})

const InitialDialog = props => {
  const {
    onClose,
    isOpen,
    onCancelButton,
    sellerProfileState,
    isChangeProfilePicDialog,
    flow = SELLER_FLOW.create,
  } = props

  const [isLoadingPhoto, setIsLoadingPhoto] = useState(false)
  const isMobile = get(clientData, 'isMobile', false)

  useEffect(() => {
    isOpen &&
      tracking(Events.public_identity_profile_photo_create_screen_shown({}))
  }, [isOpen])

  const onHandleCancel = () => {
    tracking(Events.public_identity_profile_photo_create_not_now_pressed({}))
    onCancelButton()
    onClose()
  }

  const onHandleClose = () => {
    onClose()
  }

  const hiddenFileInput = React.useRef(null)

  const onAddPhoto = () => {
    // TODO: Check if for Change your photo button instrumentation is different
    tracking(Events.public_identity_profile_photo_create_add_pressed({}))
    hiddenFileInput.current.click()
    onClose()
  }

  const advanceToProfilePan = () => {
    // TODO: Add instrumentation here
    props.history.push({
      pathname: '/seller/photos/profile',
      state: {
        flow,
      },
    })
    onCancelButton()
  }

  const onFileSelected = event => {
    setIsLoadingPhoto(true)
    sellerProfileState.processImage(
      {
        scan: true,
      },
      {
        isNewFile: true,
        fileEvent: event,
        mediaCategory: 'PROFILE_IMAGE',
        handleActions: {
          onScan: {
            onSuccess: advanceToProfilePan,
            onError: decisionMsg => {
              props.history.push(getBadPhotoError(decisionMsg))
            },
            onServiceError: e => {
              showServiceError(e, props.history)
            },
          },
        },
      },
    )
  }

  const onSetDefaultImage = async () => {
    // TODO: Add instrumentation here
    setIsLoadingPhoto(true)
    const {
      sellerProfileState: {
        state: { sellerProfileDetails: { id: businessHandle = '' } = {} } = {},
        editSellerSettings,
      } = {},
    } = props

    if (flow === SELLER_FLOW.edit) {
      const valueObj = [
        {
          op: 'replace',
          path: '/user_info/profile_photo_url',
          value: '',
        },
      ]
      try {
        await editSellerSettings(valueObj, businessHandle)
        sellerProfileState.clearSavedProfilePhoto()
      } catch (err) {
        const isMediaservError = false
        props.history.push(getUploadPhotoError(isMediaservError))
      }
    } else {
      sellerProfileState.clearSavedProfilePhoto()
    }
    onCancelButton()
    onClose()
    setIsLoadingPhoto(false)
  }

  const renderContent = () => {
    const cancelButtonContent = l10n('dialog.cancelCTA')

    return (
      <div style={{ textAlign: 'center' }}>
        {isLoadingPhoto && (
          <SpinnerContainer>
            <LoadingSpinner screenReaderText="loading" size="lg" />
          </SpinnerContainer>
        )}
        <DialogContent isLoadingPhoto={isLoadingPhoto}>
          <HeadingText size="md">
            {l10n(
              isChangeProfilePicDialog === true
                ? 'dialog.changePhotoHeading'
                : 'dialog.heading',
            )}
          </HeadingText>
          <BodyText className={`${DialogBodyText}`}>
            {l10n('dialog.paragraph')}
          </BodyText>
          <div style={{ margin: '1.5rem 0' }}>
            <Button
              data-test-id={
                isChangeProfilePicDialog === true
                  ? 'changePhotoFromDialog'
                  : 'addPhotoFromDialog'
              }
              className={isMobile ? `${css({ width: '100%' })}` : ''}
              onClick={onAddPhoto}
            >
              {l10n(
                isChangeProfilePicDialog === true
                  ? 'dialog.continueChangeCTA'
                  : 'dialog.continueCTA',
              )}
            </Button>
            <input
              type="file"
              accept="image/*"
              onChange={onFileSelected}
              ref={hiddenFileInput}
              style={{ display: 'none' }}
            />
          </div>

          {isChangeProfilePicDialog === true ? (
            <div style={{ marginBottom: '3.5rem' }}>
              {isMobile ? (
                <button
                  data-test-id="restoreDefaultButton"
                  className="vx_btn-link"
                  onClick={onSetDefaultImage}
                >
                  {l10n('dialog.deleteAndRestoreDefaultImageCTA')}
                </button>
              ) : (
                <Button
                  data-test-id="restoreDefaultButton"
                  secondary
                  onClick={onSetDefaultImage}
                  style={{ textTransform: 'capitalize' }}
                >
                  {l10n('dialog.deleteAndRestoreDefaultImageCTA')}
                </Button>
              )}
            </div>
          ) : (
            <div style={{ marginBottom: '3.5rem' }}>
              {isMobile ? (
                <button
                  data-test-id="cancelButton"
                  className="vx_btn-link"
                  onClick={onClose}
                >
                  {cancelButtonContent}
                </button>
              ) : (
                <Button
                  data-test-id="cancelButton"
                  secondary
                  onClick={onHandleCancel}
                  style={{ textTransform: 'capitalize' }}
                >
                  {cancelButtonContent}
                </Button>
              )}
            </div>
          )}
        </DialogContent>
      </div>
    )
  }

  return isMobile ? (
    <Sheet isOpen={isOpen} onClose={onHandleClose}>
      {renderContent()}
    </Sheet>
  ) : (
    <Dialog
      center
      isOpen={isOpen}
      onClose={() => {
        onHandleClose()
      }}
    >
      {renderContent()}
    </Dialog>
  )
}

export default InitialDialog
