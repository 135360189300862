// @flow

import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { VisaPlusProfile, SimpleLayout } from './async-loading'
import { ThemeProvider } from '@paypalcorp/pp-react'
import VisaPlusProfileStateContainer from './screens/visa-plus/container'

const overpanelViews = ['/visa-plus']

export default () => {
  return (
    <Route exact path={overpanelViews}>
      <SimpleLayout>
        <Switch>
          {/* NOTE: Remaining routes coming soon. */}
          <VisaPlusRoute
            exact
            path="/visa-plus"
            component={VisaPlusProfile}
            // customProps={{
            // }}
          />
        </Switch>
      </SimpleLayout>
    </Route>
  )
}

type ReactComponent = () => React.Element | React.Component

function VisaPlusRoute({
  component: Comp,
  customProps = {},
  ...props
}: {
  component: ReactComponent,
}) {
  return (
    <Route
      exact
      render={routerProps => (
        <>
          <ThemeProvider theme="v2">
            <VisaPlusProfileStateContainer
              {...routerProps}
              body={<Comp {...routerProps} {...customProps} />}
              // renderErrorScreen={}
            />
          </ThemeProvider>
        </>
      )}
      {...props}
    />
  )
}
