// @flow
// THIS FILE IS AUTOMATICALLY GENERATED -- DO NOT EDIT

const Events: Object = {}

// props

type CGPropsCommon = {
  acct_type: 'personal' | 'business' | 'guest',
  profile_type: 'personal' | 'seller',
  session_id: string,
  source: string,
  store_id: string,
  ...
} & $Shape<CGPropsCommon>
type CGPropsCommonAny = {
  acct_type?: 'personal' | 'business' | 'guest',
  profile_type?: 'personal' | 'seller',
  session_id?: string,
  source?: string,
  store_id?: string,
  ...
}

type CGPropsPublicIdentityEditUsernameScreenShown = {||}

type CGPropsPublicIdentityEditUsernameFieldPressed = {||}

type CGPropsPublicIdentityEditUsernameTypingStarted = {||}

type CGPropsPublicIdentityEditUsernameBackPressed = {||}

type CGPropsPublicIdentityEditUsernameSuggestionPressed = {||}

type CGPropsPublicIdentityEditUsernameSavePressed = {||}

type CGPropsPublicIdentityEditUsernameClosePressed = {||}

type CGPropsPublicIdentityProfileScreenShown =
  | {|
      receiver_id: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_content: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_campaign: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_campaign: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_content: string,
    |}
  | {|
      receiver_id: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_medium: string,
    |}
  | {|
      receiver_id: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_source: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_source: string,
    |}
  | {|
      receiver_id: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
      utm_term: string,
    |}
  | {|
      partner_id: string,
      receiver_id: string,
    |}

type CGPropsPublicIdentityProfileSendPressed = {|
  receiver_id: string,
|}

type CGPropsPublicIdentityProfilePayPressed = {|
  receiver_id: string,
|}

type CGPropsPublicIdentityProfileEmailPressed = {|
  receiver_id: string,
|}

type CGPropsPublicIdentityProfilePhonePressed = {|
  receiver_id: string,
|}

type CGPropsPublicIdentityProfileRequestPressed = {|
  receiver_id: string,
|}

type CGPropsPublicIdentityProfileSharePressed = {|
  receiver_id: string,
|}

type CGPropsPublicIdentityProfileReportPressed = {|
  receiver_id: string,
|}

type CGPropsPublicIdentityMyProfileScreenShown = {||}

type CGPropsPublicIdentityMyProfilePreviewPressed = {||}

type CGPropsPublicIdentityMyProfileEditPressed = {||}

type CGPropsPublicIdentityMyProfileBackPressed = {||}

type CGPropsPublicIdentityMyProfileSharePressed = {||}

type CGPropsPublicIdentityMyProfileActivityPressed = {||}

type CGPropsPublicIdentityMyProfileQrcPressed = {||}

type CGPropsPublicIdentityMyProfileClosePressed = {||}

type CGPropsPublicIdentityEditProfileActivityLoadingOccurred = {||}

type CGPropsPublicIdentityEditProfileScreenShown = {|
  status: 'active' | 'inactive',
|}

type CGPropsPublicIdentityEditProfilePreviewPressed = {||}

type CGPropsPublicIdentityEditProfileBackgroundPressed = {||}

type CGPropsPublicIdentityEditProfilePhotoPressed = {||}

type CGPropsPublicIdentityEditProfileUsernamePressed = {||}

type CGPropsPublicIdentityEditProfileLocationPressed = {||}

type CGPropsPublicIdentityEditProfileAboutPressed = {||}

type CGPropsPublicIdentityEditProfileStatusTogglePressed = {|
  opsel: 'y' | 'n',
|}

type CGPropsPublicIdentityEditProfileEmailTogglePressed = {|
  opsel: 'y' | 'n',
|}

type CGPropsPublicIdentityEditProfilePhoneTogglePressed = {|
  opsel: 'y' | 'n',
|}

type CGPropsPublicIdentityEditProfileBackPressed = {||}

type CGPropsPublicIdentityEditProfileTermsPressed = {||}

type CGPropsPublicIdentityEditProfileSharePressed = {||}

type CGPropsPublicIdentityEditProfileClosePressed = {||}

type CGPropsPublicIdentityEditProfileSavePressed = {|
  social_platform: string,
|}

type CGPropsPublicIdentityEditProfileSettingsPressed = {||}

type CGPropsPublicIdentityEditProfilePrivacyPressed = {||}

type CGPropsPublicIdentityEditProfileBusinessNamePressed = {||}

type CGPropsPublicIdentityEditProfileStatusPressed = {||}

type CGPropsPublicIdentityEditProfileSocialPressed = {||}

type CGPropsPublicIdentityEditProfilePhonePressed = {||}

type CGPropsPublicIdentityEditProfileEmailPressed = {||}

type CGPropsPublicIdentityHideProfileConfirmationScreenShown = {||}

type CGPropsPublicIdentityHideProfileConfirmationClosePressed = {||}

type CGPropsPublicIdentityHideProfileConfirmationTurnoffPressed = {||}

type CGPropsPublicIdentityHideProfileConfirmationCancelPressed = {||}

type CGPropsPublicIdentityHideProfileConfirmationBackPressed = {||}

type CGPropsPublicIdentityHideProfileConfirmationDismissPressed = {||}

type CGPropsPublicIdentityEditAboutScreenShown = {||}

type CGPropsPublicIdentityEditAboutBackPressed = {||}

type CGPropsPublicIdentityEditAboutClosePressed = {||}

type CGPropsPublicIdentityEditAboutFieldPressed = {||}

type CGPropsPublicIdentityEditAboutTypingStarted = {||}

type CGPropsPublicIdentityEditAboutSavePressed = {||}

type CGPropsPublicIdentityEditLocationScreenShown = {||}

type CGPropsPublicIdentityEditLocationBackPressed = {||}

type CGPropsPublicIdentityEditLocationCityPressed = {||}

type CGPropsPublicIdentityEditLocationStatePressed = {||}

type CGPropsPublicIdentityEditLocationDontShowPressed = {||}

type CGPropsPublicIdentityEditLocationSavePressed = {|
  opsel: 'city' | 'state' | 'none',
|}

type CGPropsPublicIdentityEditLocationClosePressed = {||}

type CGPropsPublicIdentityProfilePictureEditScreenShown = {||}

type CGPropsPublicIdentityProfilePictureEditBackPressed = {||}

type CGPropsPublicIdentityProfilePictureEditClosePressed = {||}

type CGPropsPublicIdentityProfilePictureEditChangePressed = {||}

type CGPropsPublicIdentityProfilePictureEditSavePressed = {||}

type CGPropsPublicIdentityBackgroundEditScreenShown = {||}

type CGPropsPublicIdentityBackgroundEditBackPressed = {||}

type CGPropsPublicIdentityBackgroundEditUploadPressed = {||}

type CGPropsPublicIdentityBackgroundEditClosePressed = {||}

type CGPropsPublicIdentityBackgroundAdjustScreenShown = {||}

type CGPropsPublicIdentityBackgroundAdjustBackPressed = {||}

type CGPropsPublicIdentityBackgroundAdjustSavePressed = {||}

type CGPropsPublicIdentityBackgroundAdjustClosePressed = {||}

type CGPropsPublicIdentityFailedCustomerInteractionOccurred = {|
  error_code: string,
  error_description: string,
  screen: string,
|}

type CGPropsPublicIdentityFailedCustomerInteractionRetryPressed = {|
  error_code: string,
  error_description: string,
  screen: string,
|}

type CGPropsPublicIdentityNonVisibleErrorOccurred = {|
  description: string,
  file_name: string,
  function_name: string,
|}

type CGPropsPublicIdentityProfileInfoScreenShown = {|
  page_variant: 'share' | 'protect' | 'free',
|}

type CGPropsPublicIdentityProfileInfoCreatePressed = {|
  page_variant: 'share' | 'protect' | 'free',
|}

type CGPropsPublicIdentityProfileInfoBuyerProtectionPressed = {||}

type CGPropsPublicIdentityProfileInfoSellerProtectionPressed = {||}

type CGPropsPublicIdentityProfileInfoTermsPressed = {||}

type CGPropsPublicIdentityProfileInfoClosePressed = {|
  page_variant: 'share' | 'protect' | 'free',
|}

type CGPropsPublicIdentityProfileInfoBackPressed = {|
  page_variant: 'protect' | 'free',
|}

type CGPropsPublicIdentityProfileCreateScreenShown = {||}

type CGPropsPublicIdentityProfileCreateTermsPressed = {||}

type CGPropsPublicIdentityProfileCreateNextPressed = {||}

type CGPropsPublicIdentityProfileCreateBackPressed = {||}

type CGPropsPublicIdentityProfileCreateClosePressed = {||}

type CGPropsPublicIdentityProfileCreateSocialClosePressed = {||}

type CGPropsPublicIdentityProfileTermsScreenShown = {||}

type CGPropsPublicIdentityProfileTermsClosePressed = {||}

type CGPropsPublicIdentityProfileTermsDismissPressed = {||}

type CGPropsPublicIdentityProfileTermsPolicyPressed = {||}

type CGPropsPublicIdentityProfileTermsAgreementPressed = {||}

type CGPropsPublicIdentityProfileCreateSocialScreenShown = {||}

type CGPropsPublicIdentityProfileCreateSocialBackPressed = {||}

type CGPropsPublicIdentityProfileCreateSocialNextPressed = {|
  field_list: string,
  social_platform: string,
|}

type CGPropsPublicIdentityProfileViewSocialPressed = {|
  receiver_id: string,
  social_platform: 'facebook' | 'instagram' | 'twitter' | 'website',
|}

type CGPropsPublicIdentityProfilePhotoCreateScreenShown = {||}

type CGPropsPublicIdentityProfilePhotoCreateDismissPressed = {||}

type CGPropsPublicIdentityProfilePhotoCreateClosePressed = {||}

type CGPropsPublicIdentityProfilePhotoCreateAddPressed = {||}

type CGPropsPublicIdentityProfilePhotoCreateNotNowPressed = {||}

type CGPropsPublicIdentityProfilePhotoEditScreenShown = {||}

type CGPropsPublicIdentityProfilePhotoEditBackPressed = {||}

type CGPropsPublicIdentityProfilePhotoEditProfilePressed = {||}

type CGPropsPublicIdentityProfilePhotoEditCoverPressed = {||}

type CGPropsPublicIdentityProfilePhotoEditPreviewPressed = {||}

type CGPropsPublicIdentityProfilePhotoEditClosePressed = {||}

type CGPropsPublicIdentityProfilePreviewScreenShown = {||}

type CGPropsPublicIdentityProfilePreviewBackPressed = {||}

type CGPropsPublicIdentityProfilePreviewFinishPressed = {||}

type CGPropsPublicIdentityProfilePreviewClosePressed = {||}

type CGPropsPublicIdentityProfileSuccessScreenShown = {||}

type CGPropsPublicIdentityProfileSuccessClosePressed = {||}

type CGPropsPublicIdentityProfileSuccessDonePressed = {||}

type CGPropsPublicIdentityProfileSuccessSharePressed = {||}

type CGPropsPublicIdentityProfileEditNameScreenShown = {||}

type CGPropsPublicIdentityProfileEditNameSavePressed = {||}

type CGPropsPublicIdentityProfileEditNameBackPressed = {||}

type CGPropsPublicIdentityProfileEditNameClosePressed = {||}

type CGPropsPublicIdentityProfileEditPhoneScreenShown = {|
  opsel: 'show' | 'hide',
|}

type CGPropsPublicIdentityProfileEditPhoneSavePressed = {|
  opsel: 'show' | 'hide',
|}

type CGPropsPublicIdentityProfileEditPhoneBackPressed = {||}

type CGPropsPublicIdentityProfileEditPhoneClosePressed = {||}

type CGPropsPublicIdentityProfileEditEmailScreenShown = {|
  opsel: 'show' | 'hide',
|}

type CGPropsPublicIdentityProfileEditEmailClosePressed = {||}

type CGPropsPublicIdentityProfileEditEmailBackPressed = {||}

type CGPropsPublicIdentityProfileEditEmailSavePressed = {|
  opsel: 'show' | 'hide',
|}

type CGPropsPublicIdentityProfileEditSocialScreenShown = {||}

type CGPropsPublicIdentityProfileEditSocialClosePressed = {||}

type CGPropsPublicIdentityProfileEditSocialBackPressed = {||}

type CGPropsPublicIdentityProfileEditSocialSavePressed = {|
  social_platform: string,
|}

type CGPropsPublicIdentityProfileEditStatusScreenShown = {||}

type CGPropsPublicIdentityProfileEditStatusSavePressed = {||}

type CGPropsPublicIdentityProfileEditStatusBackPressed = {||}

type CGPropsPublicIdentityProfileEditStatusClosePressed = {||}

type CGPropsPublicIdentityProfileShareScreenShown = {||}

type CGPropsPublicIdentityProfileShareClosePressed = {||}

type CGPropsPublicIdentityProfileShareOptionPressed = {||}

type CGPropsPublicIdentityProfileUsernameDuplicateScreenShown = {||}

type CGPropsPublicIdentityProfileUsernameDuplicateClosePressed = {||}

type CGPropsPublicIdentityProfileUsernameDuplicateFinishPressed = {||}

type CGPropsPublicIdentityPhotoUploadErrorScreenShown = {|
  error_code: 'MATCH' | 'NOT_AVAILABLE' | 'SERVICE_ERROR',
  error_source: 'image_screen' | 'mediaserv',
  photo_uploaded_flag: 'profile' | 'cover',
|}

type CGPropsPublicIdentityPhotoUploadErrorClosePressed = {|
  error_code: 'MATCH' | 'NOT_AVAILABLE' | 'SERVICE_ERROR',
  error_source: 'image_screen' | 'mediaserv',
  photo_uploaded_flag: 'profile' | 'cover',
|}

type CGPropsPublicIdentityPhotoUploadErrorRetryPressed = {|
  error_code: 'MATCH' | 'NOT_AVAILABLE' | 'SERVICE_ERROR',
  error_source: 'image_screen' | 'mediaserv',
  photo_uploaded_flag: 'profile' | 'cover',
|}

type CGPropsPublicIdentityPhotoUploadErrorBackPressed = {|
  error_code: 'MATCH' | 'NOT_AVAILABLE' | 'SERVICE_ERROR',
  error_source: 'image_screen' | 'mediaserv',
  photo_uploaded_flagphoto_uploaded_flag: 'profile' | 'cover',
|}

type CGPropsPublicIdentityPhotoUploadErrorCancelPressed = {|
  error_code: 'MATCH' | 'NOT_AVAILABLE' | 'SERVICE_ERROR',
  error_source: 'image_screen' | 'mediaserv',
  photo_uploaded_flagphoto_uploaded_flag: 'profile' | 'cover',
|}

type CGPropsPublicIdentityPhotoUploadErrorDismissPressed = {|
  error_code: 'MATCH' | 'NOT_AVAILABLE' | 'SERVICE_ERROR',
  error_source: 'image_screen' | 'mediaserv',
  photo_uploaded_flag: 'profile' | 'cover',
|}

type CGPropsPublicIdentityProfileUsernameDuplicateBackPressed = {||}

type CGPropsPublicIdentityNetworkConnectionErrorOccurred = {|
  correlation_id: string,
  error_code: string,
  error_desc: string,
|}

type CGPropsPublicIdentityFormErrorOccurred = {|
  correlation_id: string,
  error_code: string,
  error_desc: string,
|}

type CGPropsPublicIdentitySellerProfileGenericErrorOccurred = {|
  correlation_id: string,
  error_code: string,
  error_desc: string,
|}

type CGPropsPublicIdentityBuyerGenericErrorOccurred = {|
  correlation_id: string,
  error_code: string,
  error_desc: string,
|}

type CGPropsPublicIdentitySellerProfileOrderTabErrorOccurred = {|
  correlation_id: string,
  error_code: string,
  error_desc: string,
|}

type CGPropsPublicIdentitySellerProfileFullScreenErrorOccurred = {|
  correlation_id: string,
  error_code: string,
  error_desc: string,
|}

type CGPropsActivityDetailMessageBuyerPressed = {||}

type CGPropsActivityDetailIssueRefundPressed = {||}

type CGPropsPublicIdentityOrdersTabScreenShown = {|
  acct_type: string,
  count: string,
  state: 'empty' | 'populated',
  store_id: string,
|}

type CGPropsPublicIdentityOrdersTabOrderPressed = {|
  acct_type: string,
  order_id: string,
  status: 'pending' | 'fulfilled',
  store_id: string,
|}

type CGPropsPublicIdentityBuyerItemScreenShown = {|
  item_id: string,
  status: 'outofstock' | 'instock',
|}

type CGPropsPublicIdentityBuyerItemPaypalPayPressed = {|
  item_id: string,
  status: 'outofstock' | 'instock',
|}

type CGPropsPublicIdentityBuyerItemGuestPayPressed = {|
  item_id: string,
  status: 'outofstock' | 'instock',
|}

type CGPropsPublicIdentityBuyerItemMessagePressed = {||}

type CGPropsPublicIdentityBuyerItemUploadPressed = {||}

type CGPropsPublicIdentityBuyerItemReportShopPressed = {|
  count: string,
  item_id: string,
  status: 'outofstock' | 'instock',
|}

type CGPropsPublicIdentityBuyerItemDismissPressed = {||}

type CGPropsPublicIdentityShopProductListScreenShown = {||}

type CGPropsPublicIdentityShopProductListItemPressed = {|
  count: string,
  item_id: string,
|}

type CGPropsPublicIdentityShopProductListDismissPressed = {||}

type CGPropsPublicIdentityProfileMoreProductPressed = {||}

type CGPropsPublicIdentityProfileShowMorePressed = {||}

type CGPropsPublicIdentityProfileItemPressed = {|
  count: string,
  item_id: string,
|}

type CGPropsPublicIdentityListingManageScreenShown = {||}

type CGPropsPublicIdentityListingManageSharePressed = {||}

type CGPropsPublicIdentityListingManageEditPressed = {||}

type CGPropsPublicIdentityListingManageHidePressed = {||}

type CGPropsPublicIdentityListingManageDeletePressed = {||}

type CGPropsPublicIdentityListingManageDismissPressed = {||}

type CGPropsPublicIdentityListingManageConfirmActionScreenShown = {||}

type CGPropsPublicIdentityListingManageConfirmActionProceedPrpessed = {||}

type CGPropsPublicIdentityListingManageConfirmActionCancelPrpessed = {||}

type CGPropsPublicIdentityListingManageConfirmActionDismissPrpessed = {||}

type CGPropsPublicIdentitySellerListingDetailScreenShown = {||}

type CGPropsPublicIdentitySellerListingDetailManagePressed = {||}

type CGPropsPublicIdentitySellerListingDetailBackPressed = {||}

type CGPropsPublicIdentityListingSuccessScreenShown = {||}

type CGPropsPublicIdentityListingSuccessSharePressed = {||}

type CGPropsPublicIdentityListingSuccessAddNewPressed = {||}

type CGPropsPublicIdentityListingSuccessDismissPressed = {||}

type CGPropsPublicIdentityListingPreviewScreenShown = {||}

type CGPropsPublicIdentityListingPreviewPublishPressed = {||}

type CGPropsPublicIdentityListingPreviewBackPressed = {||}

type CGPropsPublicIdentityCreateListingScreenShown = {||}

type CGPropsPublicIdentityCreateListingUploadPhotoPressed = {||}

type CGPropsPublicIdentityCreateListingBackPressed = {||}

type CGPropsPublicIdentityCreateListingInputStarted = {||}

type CGPropsPublicIdentityCreateListingShippingDropdownPressed = {||}

type CGPropsPublicIdentityCreateListingNextPressed = {||}

type CGPropsPublicIdentityCreateListingShippingScreenShown = {||}

type CGPropsPublicIdentityCreateListingShippingOptionSelected = {||}

type CGPropsPublicIdentityCreateListingShippingDismissPressed = {||}

type CGPropsPublicIdentityListingsCoachCardShown = {|
  acct_type: string,
  page_variant: 'edit_shop' | 'share_shop',
  store_id: string,
|}

type CGPropsPublicIdentityListingsCoachCardNavigationPressed = {|
  acct_type: string,
  link_name: 'next' | 'back',
  page_variant: 'edit_shop' | 'share_shop',
  store_id: string,
|}

type CGPropsPublicIdentityListingsCoachCardDismissPressed = {|
  acct_type: string,
  page_variant: 'edit_shop' | 'share_shop',
  store_id: string,
|}

type CGPropsPublicIdentityListingsTabScreenShown = {|
  acct_type: string,
  count: string,
  store_id: string,
|}

type CGPropsPublicIdentityListingsTabSpinnerShown = {||}

type CGPropsPublicIdentityListingsTabAddNewPressed = {||}

type CGPropsPublicIdentityListingsTabItemPressed = {||}

type CGPropsPublicIdentityListingsTabItemQuickActionPressed = {||}

type CGPropsPublicIdentityListingsTabBackPressed = {|
  acct_type: string,
  store_id: string,
|}

type CGPropsPublicIdentityListingsTabTaxCardShown = {||}

type CGPropsPublicIdentityListingsTabTaxCardPressed = {||}

type CGPropsPublicIdentityShopHeaderSharePressed = {|
  acct_type: string,
  screen: 'listings' | 'orders',
  store_id: string,
|}

type CGPropsPublicIdentityShopHeaderProfilePressed = {|
  acct_type: string,
  screen: 'listings' | 'orders' | 'shop_setup_success',
  store_id: string,
|}

type CGPropsPublicIdentityShopSecondaryHeaderListingsPressed = {|
  acct_type: string,
  store_id: string,
|}

type CGPropsPublicIdentityShopSecondaryHeaderOrdersPressed = {|
  acct_type: string,
  store_id: string,
|}

type CGPropsPublicIdentityShopOnboardingFeesAndRatesPressed = {||}

type CGPropsPublicIdentityShopOnboardingTermsPressed = {||}

type CGPropsPublicIdentityShopOnboardingBuyerProtectionPressed = {||}

type CGPropsPublicIdentityShopOnboardingSellerProtectionPressed = {||}

type CGPropsPublicIdentityProfilePaypalShopsModuleShown = {|
  acct_type: string,
  state: 'enrolled' | 'not_enrolled',
|}

type CGPropsPublicIdentityProfilePaypalShopsModulePressed = {|
  acct_type: string,
  state: 'enrolled' | 'not_enrolled',
|}

type CGPropsPublicIdentitySetupTaxScreenShown = {||}

type CGPropsPublicIdentitySetupTaxSsnTypingStarted = {||}

type CGPropsPublicIdentitySetupTaxNextPressed = {||}

type CGPropsPublicIdentitySetupTaxDismissPressed = {||}

type CGPropsPublicIdentityShopSetupSuccessScreenShown = {|
  acct_type: string,
  store_id: string,
|}

type CGPropsPublicIdentityShopSetupSuccessListingPressed = {||}

type CGPropsPublicIdentityShopSetupSuccessDonePressed = {|
  acct_type: string,
  store_id: string,
|}

type CGPropsPublicIdentityShopOnboardingScreenShown = {|
  acct_type: string,
  source: 'request_money' | 'profile_page',
|}

type CGPropsPublicIdentityShopOnboardingSwipePressed = {||}

type CGPropsPublicIdentityShopOnboardingSetupPressed = {|
  acct_type: string,
  source: 'request_money' | 'profile_page',
|}

type CGPropsPublicIdentityShopOnboardingBackPressed = {|
  acct_type: string,
  source: 'request_money' | 'profile_page',
|}

type CGPropsP2pPhotoPromptInterstitialShown = {||}

type CGPropsP2pPhotoPromptInterstitialAddPhotoPressed = {||}

type CGPropsP2pPhotoPromptInterstitialDismissedPressed = {||}

type CGPropsP2pPhotoPromptTakePhotoPressed = {||}

type CGPropsP2pPhotoPromptChoosePhotoPressed = {||}

type CGPropsP2pPhotoPromptAllowPermissionsPressed = {||}

type CGPropsP2pPhotoPromptDeclinePermissionsPressed = {||}

type CGPropsP2pPhotoPromptCancelPressed = {||}

type CGPropsP2pPhotoPromptSuccessOccurred = {||}

type CGPropsPublicIdentityBuyerItemQuantityDropdownPressed = {|
  item_id: string,
  store_id: string,
|}

type CGPropsPublicIdentityBuyerItemQuantityOptionScreenShown = {|
  item_id: string,
  store_id: string,
|}

type CGPropsPublicIdentityBuyerItemQuantitySelectionPressed = {|
  item_id: string,
  opt_sel: string,
  store_id: string,
|}

type CGPropsPublicIdentityBuyerItemAddToCartPressed = {|
  item_cnt: string,
  item_id: string,
  store_id: string,
|}

type CGPropsPublicIdentityBuyerItemBuyNowPressed = {|
  item_cnt: string,
  item_id: string,
  store_id: string,
|}

type CGPropsPublicIdentityBuyerItemCartBadgeUpdated = {|
  item_id: string,
  store_id: string,
|}

type CGPropsPublicIdentityBuyerItemInventoryErrorOccurred = {|
  item_cnt: string,
  item_id: string,
  store_id: string,
|}

type CGPropsPublicIdentityBuyerAddCartConfirmationScreenShown = {|
  currency_code: string,
  item_cnt: string,
  item_id: string,
  store_id: string,
  subtotal_amt: string,
|}

type CGPropsPublicIdentityBuyerAddCartConfirmationReviewCartPressed = {|
  currency_code: string,
  item_cnt: string,
  item_id: string,
  store_id: string,
  subtotal_amt: string,
|}

type CGPropsPublicIdentityBuyerAddCartConfirmationContinueShoppingPressed = {|
  currency_code: string,
  item_cnt: string,
  item_id: string,
  store_id: string,
  subtotal_amt: string,
|}

type CGPropsPublicIdentityBuyerAddCartConfirmationDismissPressed = {|
  currency_code: string,
  item_cnt: string,
  item_id: string,
  store_id: string,
  subtotal_amt: string,
|}

type CGPropsPublicIdentityBuyerReviewCartScreenShown = {|
  currency_code: string,
  item_cnt: string,
  item_id: string,
  store_id: string,
  subtotal_amt: string,
|}

type CGPropsPublicIdentityBuyerReviewCartRemoveItemPressed = {|
  item_id: string,
  store_id: string,
|}

type CGPropsPublicIdentityBuyerReviewCartUpdateDropdownPressed = {|
  currency_code: string,
  item_cnt: string,
  item_id: string,
  store_id: string,
  subtotal_amt: string,
|}

type CGPropsPublicIdentityBuyerReviewCartUpdateQuantityOptionScreenShown = {|
  item_id: string,
  store_id: string,
|}

type CGPropsPublicIdentityBuyerReviewCartUpdateQuantitySelectionPressed = {|
  item_cnt: string,
  item_id: string,
  store_id: string,
|}

type CGPropsPublicIdentityBuyerReviewCartPaypalCheckoutPressed = {|
  currency_code: string,
  item_cnt: string,
  item_id: string,
  store_id: string,
  subtotal_amt: string,
  total_count: string,
|}

type CGPropsPublicIdentityBuyerReviewCartGuestCheckoutPressed = {|
  currency_code: string,
  item_cnt: string,
  item_id: string,
  store_id: string,
  subtotal_amt: string,
  total_count: string,
|}

type CGPropsPublicIdentityBuyerReviewCartInventoryErrorOccurred = {|
  item_cnt: string,
  item_id: string,
  store_id: string,
|}

type CGPropsPublicIdentityBuyerReviewCartContinueShoppingPressed = {|
  currency_code: string,
  item_cnt: string,
  item_id: string,
  store_id: string,
  subtotal_amt: string,
  total_count: string,
|}

type CGPropsPublicIdentityBuyerAbandonCartScreenShown = {|
  currency_code: string,
  item_cnt: string,
  item_id: string,
  store_id: string,
  subtotal_amt: string,
|}

type CGPropsPublicIdentityBuyerAbandonCartStayCheckoutPressed = {|
  currency_code: string,
  item_cnt: string,
  item_id: string,
  store_id: string,
  subtotal_amt: string,
|}

type CGPropsPublicIdentityBuyerAbandonCartReturnShoppingPressed = {|
  currency_code: string,
  item_cnt: string,
  item_id: string,
  store_id: string,
  subtotal_amt: string,
|}

type CGPropsPublicIdentityBuyerAbandonCartDismissPressed = {|
  currency_code: string,
  item_cnt: string,
  item_id: string,
  store_id: string,
  subtotal_amt: string,
  total_count: string,
|}

type CGPropsP2pCheckoutSuccessContinueShoppingPressed = {|
  store_id: string,
  txn_id: string,
|}

type CGPropsP2pCheckoutSuccessScreenShown = {|
  store_id: string,
  txn_id: string,
|}

type CGPropsP2pGuestPaymentFormContinuePressed = {| entry_point: string |}

// Personal Profile.
type CGPropsP2pPersonalProfileCreateContinuePressed = {|
  entry_point: string,
|}

type CGPropsP2pPersonalProfileCreateEditPressed = {|
  entry_point: string,
|}

type CGPropsP2pPersonalProfileCreateFailureReturned = {|
  correlation_id: string,
  error_code: string,
  error_desc: string,
  entry_point: string,
|}

type CGPropsP2pPersonalProfileCreateFormErrorOccurred = {|
  correlation_id: string,
  error_code: string,
  error_desc: string,
  entry_point: string,
|}

type CGPropsP2pPersonalProfileCreateNotNowPressed = {| entry_point: string |}

type CGPropsP2pPersonalProfileCreateScreenShown = {|
  entry_point: string,
|}

type CGPropsP2pPersonalProfileCreateSuccessReturned = {| entry_point: string |}

type CGPropsP2pPersonalProfileCreateSuggestionPressed = {|
  entry_point: string,
|}

// common props

export let commonProps: Object = {}
Events.initCommonProps = (props: CGPropsCommon) => {
  commonProps = {
    product: 'public_identity',
    ...props,
  }
}
Events.updateCommonProps = (props: CGPropsCommonAny) => {
  commonProps = {
    ...commonProps,
    ...props,
  }
}

// events

Events.public_identity_edit_username_screen_shown = (
  props: CGPropsPublicIdentityEditUsernameScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_edit_username_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_username_field_pressed = (
  props: CGPropsPublicIdentityEditUsernameFieldPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_username_field_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_username_typing_started = (
  props: CGPropsPublicIdentityEditUsernameTypingStarted,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_username_typing_started',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_username_back_pressed = (
  props: CGPropsPublicIdentityEditUsernameBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_username_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_username_suggestion_pressed = (
  props: CGPropsPublicIdentityEditUsernameSuggestionPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_username_suggestion_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_username_save_pressed = (
  props: CGPropsPublicIdentityEditUsernameSavePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_username_save_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_username_close_pressed = (
  props: CGPropsPublicIdentityEditUsernameClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_username_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_screen_shown = (
  props: CGPropsPublicIdentityProfileScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_profile_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, utm_campaign, utm_content, utm_medium, utm_source, utm_term, partner_id, receiver_id',
  ...props,
})

Events.public_identity_profile_send_pressed = (
  props: CGPropsPublicIdentityProfileSendPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_send_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, receiver_id',
  ...props,
})

Events.public_identity_profile_pay_pressed = (
  props: CGPropsPublicIdentityProfilePayPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_pay_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, receiver_id',
  ...props,
})

Events.public_identity_profile_email_pressed = (
  props: CGPropsPublicIdentityProfileEmailPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_email_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, receiver_id',
  ...props,
})

Events.public_identity_profile_phone_pressed = (
  props: CGPropsPublicIdentityProfilePhonePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_phone_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, receiver_id',
  ...props,
})

Events.public_identity_profile_request_pressed = (
  props: CGPropsPublicIdentityProfileRequestPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_request_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, receiver_id',
  ...props,
})

Events.public_identity_profile_share_pressed = (
  props: CGPropsPublicIdentityProfileSharePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_share_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, receiver_id',
  ...props,
})

Events.public_identity_profile_report_pressed = (
  props: CGPropsPublicIdentityProfileReportPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_report_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, receiver_id',
  ...props,
})

Events.public_identity_my_profile_screen_shown = (
  props: CGPropsPublicIdentityMyProfileScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_my_profile_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_my_profile_preview_pressed = (
  props: CGPropsPublicIdentityMyProfilePreviewPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_my_profile_preview_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_my_profile_edit_pressed = (
  props: CGPropsPublicIdentityMyProfileEditPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_my_profile_edit_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_my_profile_back_pressed = (
  props: CGPropsPublicIdentityMyProfileBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_my_profile_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_my_profile_share_pressed = (
  props: CGPropsPublicIdentityMyProfileSharePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_my_profile_share_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_my_profile_activity_pressed = (
  props: CGPropsPublicIdentityMyProfileActivityPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_my_profile_activity_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_my_profile_qrc_pressed = (
  props: CGPropsPublicIdentityMyProfileQrcPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_my_profile_qrc_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_my_profile_close_pressed = (
  props: CGPropsPublicIdentityMyProfileClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_my_profile_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_profile_activity_loading_occurred = (
  props: CGPropsPublicIdentityEditProfileActivityLoadingOccurred,
) => ({
  ...commonProps,
  event_type: 'ac',
  event_name: 'public_identity_edit_profile_activity_loading_occurred',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_profile_screen_shown = (
  props: CGPropsPublicIdentityEditProfileScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_edit_profile_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, status',
  ...props,
})

Events.public_identity_edit_profile_preview_pressed = (
  props: CGPropsPublicIdentityEditProfilePreviewPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_profile_preview_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_profile_background_pressed = (
  props: CGPropsPublicIdentityEditProfileBackgroundPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_profile_background_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_profile_photo_pressed = (
  props: CGPropsPublicIdentityEditProfilePhotoPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_profile_photo_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_profile_username_pressed = (
  props: CGPropsPublicIdentityEditProfileUsernamePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_profile_username_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_profile_location_pressed = (
  props: CGPropsPublicIdentityEditProfileLocationPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_profile_location_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_profile_about_pressed = (
  props: CGPropsPublicIdentityEditProfileAboutPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_profile_about_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_profile_status_toggle_pressed = (
  props: CGPropsPublicIdentityEditProfileStatusTogglePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_profile_status_toggle_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, opsel',
  ...props,
})

Events.public_identity_edit_profile_email_toggle_pressed = (
  props: CGPropsPublicIdentityEditProfileEmailTogglePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_profile_email_toggle_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, opsel',
  ...props,
})

Events.public_identity_edit_profile_phone_toggle_pressed = (
  props: CGPropsPublicIdentityEditProfilePhoneTogglePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_profile_phone_toggle_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, opsel',
  ...props,
})

Events.public_identity_edit_profile_back_pressed = (
  props: CGPropsPublicIdentityEditProfileBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_profile_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_profile_terms_pressed = (
  props: CGPropsPublicIdentityEditProfileTermsPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_profile_terms_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_profile_share_pressed = (
  props: CGPropsPublicIdentityEditProfileSharePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_profile_share_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_profile_close_pressed = (
  props: CGPropsPublicIdentityEditProfileClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_profile_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_profile_save_pressed = (
  props: CGPropsPublicIdentityEditProfileSavePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_profile_save_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, social_platform',
  ...props,
})

Events.public_identity_edit_profile_settings_pressed = (
  props: CGPropsPublicIdentityEditProfileSettingsPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_profile_settings_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_profile_privacy_pressed = (
  props: CGPropsPublicIdentityEditProfilePrivacyPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_profile_privacy_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_profile_business_name_pressed = (
  props: CGPropsPublicIdentityEditProfileBusinessNamePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_profile_business_name_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_profile_status_pressed = (
  props: CGPropsPublicIdentityEditProfileStatusPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_profile_status_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_profile_social_pressed = (
  props: CGPropsPublicIdentityEditProfileSocialPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_profile_social_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_profile_phone_pressed = (
  props: CGPropsPublicIdentityEditProfilePhonePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_profile_phone_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_profile_email_pressed = (
  props: CGPropsPublicIdentityEditProfileEmailPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_profile_email_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_hide_profile_confirmation_screen_shown = (
  props: CGPropsPublicIdentityHideProfileConfirmationScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_hide_profile_confirmation_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_hide_profile_confirmation_close_pressed = (
  props: CGPropsPublicIdentityHideProfileConfirmationClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_hide_profile_confirmation_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_hide_profile_confirmation_turnoff_pressed = (
  props: CGPropsPublicIdentityHideProfileConfirmationTurnoffPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_hide_profile_confirmation_turnoff_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_hide_profile_confirmation_cancel_pressed = (
  props: CGPropsPublicIdentityHideProfileConfirmationCancelPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_hide_profile_confirmation_cancel_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_hide_profile_confirmation_back_pressed = (
  props: CGPropsPublicIdentityHideProfileConfirmationBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_hide_profile_confirmation_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_hide_profile_confirmation_dismiss_pressed = (
  props: CGPropsPublicIdentityHideProfileConfirmationDismissPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_hide_profile_confirmation_dismiss_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_about_screen_shown = (
  props: CGPropsPublicIdentityEditAboutScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_edit_about_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_about_back_pressed = (
  props: CGPropsPublicIdentityEditAboutBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_about_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_about_close_pressed = (
  props: CGPropsPublicIdentityEditAboutClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_about_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_about_field_pressed = (
  props: CGPropsPublicIdentityEditAboutFieldPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_about_field_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_about_typing_started = (
  props: CGPropsPublicIdentityEditAboutTypingStarted,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_about_typing_started',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_about_save_pressed = (
  props: CGPropsPublicIdentityEditAboutSavePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_about_save_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_location_screen_shown = (
  props: CGPropsPublicIdentityEditLocationScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_edit_location_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_location_back_pressed = (
  props: CGPropsPublicIdentityEditLocationBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_location_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_location_city_pressed = (
  props: CGPropsPublicIdentityEditLocationCityPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_location_city_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_location_state_pressed = (
  props: CGPropsPublicIdentityEditLocationStatePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_location_state_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_location_dont_show_pressed = (
  props: CGPropsPublicIdentityEditLocationDontShowPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_location_dont_show_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_edit_location_save_pressed = (
  props: CGPropsPublicIdentityEditLocationSavePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_location_save_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, opsel',
  ...props,
})

Events.public_identity_edit_location_close_pressed = (
  props: CGPropsPublicIdentityEditLocationClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_edit_location_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_picture_edit_screen_shown = (
  props: CGPropsPublicIdentityProfilePictureEditScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_profile_picture_edit_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_picture_edit_back_pressed = (
  props: CGPropsPublicIdentityProfilePictureEditBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_picture_edit_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_picture_edit_close_pressed = (
  props: CGPropsPublicIdentityProfilePictureEditClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_picture_edit_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_picture_edit_change_pressed = (
  props: CGPropsPublicIdentityProfilePictureEditChangePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_picture_edit_change_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_picture_edit_save_pressed = (
  props: CGPropsPublicIdentityProfilePictureEditSavePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_picture_edit_save_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_background_edit_screen_shown = (
  props: CGPropsPublicIdentityBackgroundEditScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_background_edit_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_background_edit_back_pressed = (
  props: CGPropsPublicIdentityBackgroundEditBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_background_edit_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_background_edit_upload_pressed = (
  props: CGPropsPublicIdentityBackgroundEditUploadPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_background_edit_upload_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_background_edit_close_pressed = (
  props: CGPropsPublicIdentityBackgroundEditClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_background_edit_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_background_adjust_screen_shown = (
  props: CGPropsPublicIdentityBackgroundAdjustScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_background_adjust_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_background_adjust_back_pressed = (
  props: CGPropsPublicIdentityBackgroundAdjustBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_background_adjust_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_background_adjust_save_pressed = (
  props: CGPropsPublicIdentityBackgroundAdjustSavePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_background_adjust_save_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_background_adjust_close_pressed = (
  props: CGPropsPublicIdentityBackgroundAdjustClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_background_adjust_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_failed_customer_interaction_occurred = (
  props: CGPropsPublicIdentityFailedCustomerInteractionOccurred,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_failed_customer_interaction_occurred',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, error_code, error_description, screen',
  ...props,
})

Events.public_identity_failed_customer_interaction_retry_pressed = (
  props: CGPropsPublicIdentityFailedCustomerInteractionRetryPressed,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_failed_customer_interaction_retry_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, error_code, error_description, screen',
  ...props,
})

Events.public_identity_non_visible_error_occurred = (
  props: CGPropsPublicIdentityNonVisibleErrorOccurred,
) => ({
  ...commonProps,
  event_type: 'err',
  event_name: 'public_identity_non_visible_error_occurred',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, file_name, function_name, description',
  ...props,
})

Events.public_identity_profile_info_screen_shown = (
  props: CGPropsPublicIdentityProfileInfoScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_profile_info_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, page_variant',
  ...props,
})

Events.public_identity_profile_info_create_pressed = (
  props: CGPropsPublicIdentityProfileInfoCreatePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_info_create_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, page_variant',
  ...props,
})

Events.public_identity_profile_info_buyer_protection_pressed = (
  props: CGPropsPublicIdentityProfileInfoBuyerProtectionPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  page_variant: 'protect',
  event_name: 'public_identity_profile_info_buyer_protection_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, page_variant',
  ...props,
})

Events.public_identity_profile_info_seller_protection_pressed = (
  props: CGPropsPublicIdentityProfileInfoSellerProtectionPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  page_variant: 'protect',
  event_name: 'public_identity_profile_info_seller_protection_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, page_variant',
  ...props,
})

Events.public_identity_profile_info_terms_pressed = (
  props: CGPropsPublicIdentityProfileInfoTermsPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  page_variant: 'free',
  event_name: 'public_identity_profile_info_terms_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, page_variant',
  ...props,
})

Events.public_identity_profile_info_close_pressed = (
  props: CGPropsPublicIdentityProfileInfoClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_info_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, page_variant',
  ...props,
})

Events.public_identity_profile_info_back_pressed = (
  props: CGPropsPublicIdentityProfileInfoBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_info_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, page_variant',
  ...props,
})

Events.public_identity_profile_create_screen_shown = (
  props: CGPropsPublicIdentityProfileCreateScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_profile_create_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_create_terms_pressed = (
  props: CGPropsPublicIdentityProfileCreateTermsPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_create_terms_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_create_next_pressed = (
  props: CGPropsPublicIdentityProfileCreateNextPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_create_next_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_create_back_pressed = (
  props: CGPropsPublicIdentityProfileCreateBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_create_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_create_close_pressed = (
  props: CGPropsPublicIdentityProfileCreateClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_create_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_create_social_close_pressed = (
  props: CGPropsPublicIdentityProfileCreateSocialClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_create_social_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_terms_screen_shown = (
  props: CGPropsPublicIdentityProfileTermsScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_profile_terms_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_terms_close_pressed = (
  props: CGPropsPublicIdentityProfileTermsClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_terms_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_terms_dismiss_pressed = (
  props: CGPropsPublicIdentityProfileTermsDismissPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_terms_dismiss_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_terms_policy_pressed = (
  props: CGPropsPublicIdentityProfileTermsPolicyPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_terms_policy_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_terms_agreement_pressed = (
  props: CGPropsPublicIdentityProfileTermsAgreementPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_terms_agreement_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_create_social_screen_shown = (
  props: CGPropsPublicIdentityProfileCreateSocialScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_profile_create_social_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_create_social_back_pressed = (
  props: CGPropsPublicIdentityProfileCreateSocialBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_create_social_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_create_social_next_pressed = (
  props: CGPropsPublicIdentityProfileCreateSocialNextPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_create_social_next_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, field_list, social_platform',
  ...props,
})

Events.public_identity_profile_view_social_pressed = (
  props: CGPropsPublicIdentityProfileViewSocialPressed,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_profile_view_social_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, social_platform, receiver_id',
  ...props,
})

Events.public_identity_profile_photo_create_screen_shown = (
  props: CGPropsPublicIdentityProfilePhotoCreateScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_profile_photo_create_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_photo_create_dismiss_pressed = (
  props: CGPropsPublicIdentityProfilePhotoCreateDismissPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_photo_create_dismiss_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_photo_create_close_pressed = (
  props: CGPropsPublicIdentityProfilePhotoCreateClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_photo_create_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_photo_create_add_pressed = (
  props: CGPropsPublicIdentityProfilePhotoCreateAddPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_photo_create_add_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_photo_create_not_now_pressed = (
  props: CGPropsPublicIdentityProfilePhotoCreateNotNowPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_photo_create_not_now_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_photo_edit_screen_shown = (
  props: CGPropsPublicIdentityProfilePhotoEditScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_profile_photo_edit_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_photo_edit_back_pressed = (
  props: CGPropsPublicIdentityProfilePhotoEditBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_photo_edit_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_photo_edit_profile_pressed = (
  props: CGPropsPublicIdentityProfilePhotoEditProfilePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_photo_edit_profile_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_photo_edit_cover_pressed = (
  props: CGPropsPublicIdentityProfilePhotoEditCoverPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_photo_edit_cover_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_photo_edit_preview_pressed = (
  props: CGPropsPublicIdentityProfilePhotoEditPreviewPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_photo_edit_preview_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_photo_edit_close_pressed = (
  props: CGPropsPublicIdentityProfilePhotoEditClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_photo_edit_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_preview_screen_shown = (
  props: CGPropsPublicIdentityProfilePreviewScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_profile_preview_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_preview_back_pressed = (
  props: CGPropsPublicIdentityProfilePreviewBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_preview_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_preview_finish_pressed = (
  props: CGPropsPublicIdentityProfilePreviewFinishPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_preview_finish_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_preview_close_pressed = (
  props: CGPropsPublicIdentityProfilePreviewClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_preview_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_success_screen_shown = (
  props: CGPropsPublicIdentityProfileSuccessScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_profile_success_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_success_close_pressed = (
  props: CGPropsPublicIdentityProfileSuccessClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_success_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_success_done_pressed = (
  props: CGPropsPublicIdentityProfileSuccessDonePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_success_done_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_success_share_pressed = (
  props: CGPropsPublicIdentityProfileSuccessSharePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_success_share_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_edit_name_screen_shown = (
  props: CGPropsPublicIdentityProfileEditNameScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_profile_edit_name_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_edit_name_save_pressed = (
  props: CGPropsPublicIdentityProfileEditNameSavePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_edit_name_save_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_edit_name_back_pressed = (
  props: CGPropsPublicIdentityProfileEditNameBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_edit_name_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_edit_name_close_pressed = (
  props: CGPropsPublicIdentityProfileEditNameClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_edit_name_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_edit_phone_screen_shown = (
  props: CGPropsPublicIdentityProfileEditPhoneScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_profile_edit_phone_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, opsel',
  ...props,
})

Events.public_identity_profile_edit_phone_save_pressed = (
  props: CGPropsPublicIdentityProfileEditPhoneSavePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_edit_phone_save_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, opsel',
  ...props,
})

Events.public_identity_profile_edit_phone_back_pressed = (
  props: CGPropsPublicIdentityProfileEditPhoneBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_edit_phone_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_edit_phone_close_pressed = (
  props: CGPropsPublicIdentityProfileEditPhoneClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_edit_phone_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_edit_email_screen_shown = (
  props: CGPropsPublicIdentityProfileEditEmailScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_profile_edit_email_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, opsel',
  ...props,
})

Events.public_identity_profile_edit_email_close_pressed = (
  props: CGPropsPublicIdentityProfileEditEmailClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_edit_email_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_edit_email_back_pressed = (
  props: CGPropsPublicIdentityProfileEditEmailBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_edit_email_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_edit_email_save_pressed = (
  props: CGPropsPublicIdentityProfileEditEmailSavePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_edit_email_save_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, opsel',
  ...props,
})

Events.public_identity_profile_edit_social_screen_shown = (
  props: CGPropsPublicIdentityProfileEditSocialScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_profile_edit_social_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_edit_social_close_pressed = (
  props: CGPropsPublicIdentityProfileEditSocialClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_edit_social_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_edit_social_back_pressed = (
  props: CGPropsPublicIdentityProfileEditSocialBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_edit_social_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_edit_social_save_pressed = (
  props: CGPropsPublicIdentityProfileEditSocialSavePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_edit_social_save_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, social_platform',
  ...props,
})

Events.public_identity_profile_edit_status_screen_shown = (
  props: CGPropsPublicIdentityProfileEditStatusScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_profile_edit_status_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_edit_status_save_pressed = (
  props: CGPropsPublicIdentityProfileEditStatusSavePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_edit_status_save_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_edit_status_back_pressed = (
  props: CGPropsPublicIdentityProfileEditStatusBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_edit_status_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_edit_status_close_pressed = (
  props: CGPropsPublicIdentityProfileEditStatusClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_edit_status_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_share_screen_shown = (
  props: CGPropsPublicIdentityProfileShareScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_profile_share_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_share_close_pressed = (
  props: CGPropsPublicIdentityProfileShareClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_share_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_share_option_pressed = (
  props: CGPropsPublicIdentityProfileShareOptionPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_share_option_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_username_duplicate_screen_shown = (
  props: CGPropsPublicIdentityProfileUsernameDuplicateScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_profile_username_duplicate_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_username_duplicate_close_pressed = (
  props: CGPropsPublicIdentityProfileUsernameDuplicateClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_username_duplicate_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_username_duplicate_finish_pressed = (
  props: CGPropsPublicIdentityProfileUsernameDuplicateFinishPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_username_duplicate_finish_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_photo_upload_error_screen_shown = (
  props: CGPropsPublicIdentityPhotoUploadErrorScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_photo_upload_error_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, photo_uploaded_flag, error_source, error_code',
  ...props,
})

Events.public_identity_photo_upload_error_close_pressed = (
  props: CGPropsPublicIdentityPhotoUploadErrorClosePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_photo_upload_error_close_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, photo_uploaded_flag, error_source, error_code',
  ...props,
})

Events.public_identity_photo_upload_error_retry_pressed = (
  props: CGPropsPublicIdentityPhotoUploadErrorRetryPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_photo_upload_error_retry_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, photo_uploaded_flag, error_source, error_code',
  ...props,
})

Events.public_identity_photo_upload_error_back_pressed = (
  props: CGPropsPublicIdentityPhotoUploadErrorBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_photo_upload_error_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, photo_uploaded_flagphoto_uploaded_flag, error_source, error_code',
  ...props,
})

Events.public_identity_photo_upload_error_cancel_pressed = (
  props: CGPropsPublicIdentityPhotoUploadErrorCancelPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_photo_upload_error_cancel_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, photo_uploaded_flagphoto_uploaded_flag, error_source, error_code',
  ...props,
})

Events.public_identity_photo_upload_error_dismiss_pressed = (
  props: CGPropsPublicIdentityPhotoUploadErrorDismissPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_photo_upload_error_dismiss_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, photo_uploaded_flag, error_source, error_code',
  ...props,
})

Events.public_identity_profile_username_duplicate_back_pressed = (
  props: CGPropsPublicIdentityProfileUsernameDuplicateBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_username_duplicate_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_network_connection_error_occurred = (
  props: CGPropsPublicIdentityNetworkConnectionErrorOccurred,
) => ({
  ...commonProps,
  event_type: 'ac',
  event_name: 'public_identity_network_connection_error_occurred',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, error_code, error_desc, correlation_id',
  ...props,
})

Events.public_identity_form_error_occurred = (
  props: CGPropsPublicIdentityFormErrorOccurred,
) => ({
  ...commonProps,
  event_type: 'ac',
  event_name: 'public_identity_form_error_occurred',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, error_code, error_desc, correlation_id',
  ...props,
})

Events.public_identity_seller_profile_generic_error_occurred = (
  props: CGPropsPublicIdentitySellerProfileGenericErrorOccurred,
) => ({
  ...commonProps,
  event_type: 'ac',
  event_name: 'public_identity_seller_profile_generic_error_occurred',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, error_code, error_desc, correlation_id',
  ...props,
})

Events.public_identity_buyer_generic_error_occurred = (
  props: CGPropsPublicIdentityBuyerGenericErrorOccurred,
) => ({
  ...commonProps,
  event_type: 'ac',
  event_name: 'public_identity_buyer_generic_error_occurred',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, error_code, error_desc, correlation_id',
  ...props,
})

Events.public_identity_seller_profile_order_tab_error_occurred = (
  props: CGPropsPublicIdentitySellerProfileOrderTabErrorOccurred,
) => ({
  ...commonProps,
  event_type: 'ac',
  event_name: 'public_identity_seller_profile_order_tab_error_occurred',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, error_code, error_desc, correlation_id',
  ...props,
})

Events.public_identity_seller_profile_full_screen_error_occurred = (
  props: CGPropsPublicIdentitySellerProfileFullScreenErrorOccurred,
) => ({
  ...commonProps,
  event_type: 'ac',
  event_name: 'public_identity_seller_profile_full_screen_error_occurred',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, error_code, error_desc, correlation_id',
  ...props,
})

Events.activity_detail_message_buyer_pressed = (
  props: CGPropsActivityDetailMessageBuyerPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'activity_detail_message_buyer_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.activity_detail_issue_refund_pressed = (
  props: CGPropsActivityDetailIssueRefundPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'activity_detail_issue_refund_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_orders_tab_screen_shown = (
  props: CGPropsPublicIdentityOrdersTabScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_orders_tab_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, state, count, store_id, acct_type',
  ...props,
})

Events.public_identity_orders_tab_order_pressed = (
  props: CGPropsPublicIdentityOrdersTabOrderPressed,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_orders_tab_order_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, order_id, status, store_id, acct_type',
  ...props,
})

Events.public_identity_buyer_item_screen_shown = (
  props: CGPropsPublicIdentityBuyerItemScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_buyer_item_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, status, item_id',
  ...props,
})

Events.public_identity_buyer_item_paypal_pay_pressed = (
  props: CGPropsPublicIdentityBuyerItemPaypalPayPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_buyer_item_paypal_pay_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, status, item_id',
  ...props,
})

Events.public_identity_buyer_item_guest_pay_pressed = (
  props: CGPropsPublicIdentityBuyerItemGuestPayPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_buyer_item_guest_pay_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, status, item_id',
  ...props,
})

Events.public_identity_buyer_item_message_pressed = (
  props: CGPropsPublicIdentityBuyerItemMessagePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_buyer_item_message_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_buyer_item_upload_pressed = (
  props: CGPropsPublicIdentityBuyerItemUploadPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_buyer_item_upload_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_buyer_item_report_shop_pressed = (
  props: CGPropsPublicIdentityBuyerItemReportShopPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_buyer_item_report_shop_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, status, item_id, count',
  ...props,
})

Events.public_identity_buyer_item_dismiss_pressed = (
  props: CGPropsPublicIdentityBuyerItemDismissPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_buyer_item_dismiss_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_shop_product_list_screen_shown = (
  props: CGPropsPublicIdentityShopProductListScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_shop_product_list_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_shop_product_list_item_pressed = (
  props: CGPropsPublicIdentityShopProductListItemPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_shop_product_list_item_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, item_id, count',
  ...props,
})

Events.public_identity_shop_product_list_dismiss_pressed = (
  props: CGPropsPublicIdentityShopProductListDismissPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_shop_product_list_dismiss_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_more_product_pressed = (
  props: CGPropsPublicIdentityProfileMoreProductPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_more_product_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_show_more_pressed = (
  props: CGPropsPublicIdentityProfileShowMorePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_show_more_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_item_pressed = (
  props: CGPropsPublicIdentityProfileItemPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_item_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, item_id, count',
  ...props,
})

Events.public_identity_listing_manage_screen_shown = (
  props: CGPropsPublicIdentityListingManageScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_listing_manage_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listing_manage_share_pressed = (
  props: CGPropsPublicIdentityListingManageSharePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_listing_manage_share_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listing_manage_edit_pressed = (
  props: CGPropsPublicIdentityListingManageEditPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_listing_manage_edit_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listing_manage_hide_pressed = (
  props: CGPropsPublicIdentityListingManageHidePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_listing_manage_hide_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listing_manage_delete_pressed = (
  props: CGPropsPublicIdentityListingManageDeletePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_listing_manage_delete_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listing_manage_dismiss_pressed = (
  props: CGPropsPublicIdentityListingManageDismissPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_listing_manage_dismiss_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listing_manage_confirm_action_screen_shown = (
  props: CGPropsPublicIdentityListingManageConfirmActionScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_listing_manage_confirm_action_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listing_manage_confirm_action_proceed_prpessed = (
  props: CGPropsPublicIdentityListingManageConfirmActionProceedPrpessed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_listing_manage_confirm_action_proceed_prpessed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listing_manage_confirm_action_cancel_prpessed = (
  props: CGPropsPublicIdentityListingManageConfirmActionCancelPrpessed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_listing_manage_confirm_action_cancel_prpessed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listing_manage_confirm_action_dismiss_prpessed = (
  props: CGPropsPublicIdentityListingManageConfirmActionDismissPrpessed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_listing_manage_confirm_action_dismiss_prpessed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_seller_listing_detail_screen_shown = (
  props: CGPropsPublicIdentitySellerListingDetailScreenShown,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_seller_listing_detail_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_seller_listing_detail_manage_pressed = (
  props: CGPropsPublicIdentitySellerListingDetailManagePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_seller_listing_detail_manage_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_seller_listing_detail_back_pressed = (
  props: CGPropsPublicIdentitySellerListingDetailBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_seller_listing_detail_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listing_success_screen_shown = (
  props: CGPropsPublicIdentityListingSuccessScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_listing_success_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listing_success_share_pressed = (
  props: CGPropsPublicIdentityListingSuccessSharePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_listing_success_share_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listing_success_add_new_pressed = (
  props: CGPropsPublicIdentityListingSuccessAddNewPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_listing_success_add_new_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listing_success_dismiss_pressed = (
  props: CGPropsPublicIdentityListingSuccessDismissPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_listing_success_dismiss_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listing_preview_screen_shown = (
  props: CGPropsPublicIdentityListingPreviewScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_listing_preview_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listing_preview_publish_pressed = (
  props: CGPropsPublicIdentityListingPreviewPublishPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_listing_preview_publish_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listing_preview_back_pressed = (
  props: CGPropsPublicIdentityListingPreviewBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_listing_preview_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_create_listing_screen_shown = (
  props: CGPropsPublicIdentityCreateListingScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_create_listing_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_create_listing_upload_photo_pressed = (
  props: CGPropsPublicIdentityCreateListingUploadPhotoPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_create_listing_upload_photo_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_create_listing_back_pressed = (
  props: CGPropsPublicIdentityCreateListingBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_create_listing_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_create_listing_input_started = (
  props: CGPropsPublicIdentityCreateListingInputStarted,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_create_listing_input_started',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_create_listing_shipping_dropdown_pressed = (
  props: CGPropsPublicIdentityCreateListingShippingDropdownPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_create_listing_shipping_dropdown_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_create_listing_next_pressed = (
  props: CGPropsPublicIdentityCreateListingNextPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_create_listing_next_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_create_listing_shipping_screen_shown = (
  props: CGPropsPublicIdentityCreateListingShippingScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_create_listing_shipping_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_create_listing_shipping_option_selected = (
  props: CGPropsPublicIdentityCreateListingShippingOptionSelected,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_create_listing_shipping_option_selected',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_create_listing_shipping_dismiss_pressed = (
  props: CGPropsPublicIdentityCreateListingShippingDismissPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_create_listing_shipping_dismiss_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listings_coach_card_shown = (
  props: CGPropsPublicIdentityListingsCoachCardShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_listings_coach_card_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, page_variant, store_id, acct_type',
  ...props,
})

Events.public_identity_listings_coach_card_navigation_pressed = (
  props: CGPropsPublicIdentityListingsCoachCardNavigationPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_listings_coach_card_navigation_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, page_variant, store_id, link_name, acct_type',
  ...props,
})

Events.public_identity_listings_coach_card_dismiss_pressed = (
  props: CGPropsPublicIdentityListingsCoachCardDismissPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_listings_coach_card_dismiss_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, page_variant, store_id, acct_type',
  ...props,
})

Events.public_identity_listings_tab_screen_shown = (
  props: CGPropsPublicIdentityListingsTabScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_listings_tab_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, count, store_id, acct_type',
  ...props,
})

Events.public_identity_listings_tab_spinner_shown = (
  props: CGPropsPublicIdentityListingsTabSpinnerShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_listings_tab_spinner_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listings_tab_add_new_pressed = (
  props: CGPropsPublicIdentityListingsTabAddNewPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_listings_tab_add_new_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listings_tab_item_pressed = (
  props: CGPropsPublicIdentityListingsTabItemPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_listings_tab_item_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listings_tab_item_quick_action_pressed = (
  props: CGPropsPublicIdentityListingsTabItemQuickActionPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_listings_tab_item_quick_action_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listings_tab_back_pressed = (
  props: CGPropsPublicIdentityListingsTabBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_listings_tab_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, store_id, acct_type',
  ...props,
})

Events.public_identity_listings_tab_tax_card_shown = (
  props: CGPropsPublicIdentityListingsTabTaxCardShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_listings_tab_tax_card_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_listings_tab_tax_card_pressed = (
  props: CGPropsPublicIdentityListingsTabTaxCardPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_listings_tab_tax_card_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_shop_header_share_pressed = (
  props: CGPropsPublicIdentityShopHeaderSharePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_shop_header_share_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, screen, store_id, acct_type',
  ...props,
})

Events.public_identity_shop_header_profile_pressed = (
  props: CGPropsPublicIdentityShopHeaderProfilePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_shop_header_profile_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, screen, store_id, acct_type',
  ...props,
})

Events.public_identity_shop_secondary_header_listings_pressed = (
  props: CGPropsPublicIdentityShopSecondaryHeaderListingsPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_shop_secondary_header_listings_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, store_id, acct_type',
  ...props,
})

Events.public_identity_shop_secondary_header_orders_pressed = (
  props: CGPropsPublicIdentityShopSecondaryHeaderOrdersPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_shop_secondary_header_orders_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, store_id, acct_type',
  ...props,
})

Events.public_identity_shop_onboarding_fees_and_rates_pressed = (
  props: CGPropsPublicIdentityShopOnboardingFeesAndRatesPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_shop_onboarding_fees_and_rates_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_shop_onboarding_terms_pressed = (
  props: CGPropsPublicIdentityShopOnboardingTermsPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_shop_onboarding_terms_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_shop_onboarding_buyer_protection_pressed = (
  props: CGPropsPublicIdentityShopOnboardingBuyerProtectionPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_shop_onboarding_buyer_protection_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_shop_onboarding_seller_protection_pressed = (
  props: CGPropsPublicIdentityShopOnboardingSellerProtectionPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_shop_onboarding_seller_protection_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_profile_paypal_shops_module_shown = (
  props: CGPropsPublicIdentityProfilePaypalShopsModuleShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_profile_paypal_shops_module_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, state, acct_type',
  ...props,
})

Events.public_identity_profile_paypal_shops_module_pressed = (
  props: CGPropsPublicIdentityProfilePaypalShopsModulePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_profile_paypal_shops_module_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, state, acct_type',
  ...props,
})

Events.public_identity_setup_tax_screen_shown = (
  props: CGPropsPublicIdentitySetupTaxScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_setup_tax_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_setup_tax_ssn_typing_started = (
  props: CGPropsPublicIdentitySetupTaxSsnTypingStarted,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_setup_tax_ssn_typing_started',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_setup_tax_next_pressed = (
  props: CGPropsPublicIdentitySetupTaxNextPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_setup_tax_next_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_setup_tax_dismiss_pressed = (
  props: CGPropsPublicIdentitySetupTaxDismissPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_setup_tax_dismiss_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_shop_setup_success_screen_shown = (
  props: CGPropsPublicIdentityShopSetupSuccessScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_shop_setup_success_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, store_id, acct_type',
  ...props,
})

Events.public_identity_shop_setup_success_listing_pressed = (
  props: CGPropsPublicIdentityShopSetupSuccessListingPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_shop_setup_success_listing_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_shop_setup_success_done_pressed = (
  props: CGPropsPublicIdentityShopSetupSuccessDonePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_shop_setup_success_done_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, store_id, acct_type',
  ...props,
})

Events.public_identity_shop_onboarding_screen_shown = (
  props: CGPropsPublicIdentityShopOnboardingScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_shop_onboarding_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, acct_type, source',
  ...props,
})

Events.public_identity_shop_onboarding_swipe_pressed = (
  props: CGPropsPublicIdentityShopOnboardingSwipePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_shop_onboarding_swipe_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_shop_onboarding_setup_pressed = (
  props: CGPropsPublicIdentityShopOnboardingSetupPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_shop_onboarding_setup_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, acct_type, source',
  ...props,
})

Events.public_identity_shop_onboarding_back_pressed = (
  props: CGPropsPublicIdentityShopOnboardingBackPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_shop_onboarding_back_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, acct_type, source',
  ...props,
})

Events.p2p_photo_prompt_interstitial_shown = (
  props: CGPropsP2pPhotoPromptInterstitialShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'p2p_photo_prompt_interstitial_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.p2p_photo_prompt_interstitial_add_photo_pressed = (
  props: CGPropsP2pPhotoPromptInterstitialAddPhotoPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'p2p_photo_prompt_interstitial_add_photo_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.p2p_photo_prompt_interstitial_dismissed_pressed = (
  props: CGPropsP2pPhotoPromptInterstitialDismissedPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'p2p_photo_prompt_interstitial_dismissed_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.p2p_photo_prompt_take_photo_pressed = (
  props: CGPropsP2pPhotoPromptTakePhotoPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'p2p_photo_prompt_take_photo_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.p2p_photo_prompt_choose_photo_pressed = (
  props: CGPropsP2pPhotoPromptChoosePhotoPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'p2p_photo_prompt_choose_photo_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.p2p_photo_prompt_allow_permissions_pressed = (
  props: CGPropsP2pPhotoPromptAllowPermissionsPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'p2p_photo_prompt_allow_permissions_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.p2p_photo_prompt_decline_permissions_pressed = (
  props: CGPropsP2pPhotoPromptDeclinePermissionsPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'p2p_photo_prompt_decline_permissions_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.p2p_photo_prompt_cancel_pressed = (
  props: CGPropsP2pPhotoPromptCancelPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'p2p_photo_prompt_cancel_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.p2p_photo_prompt_success_occurred = (
  props: CGPropsP2pPhotoPromptSuccessOccurred,
) => ({
  ...commonProps,
  event_type: 'ac',
  event_name: 'p2p_photo_prompt_success_occurred',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

Events.public_identity_buyer_item_quantity_dropdown_pressed = (
  props: CGPropsPublicIdentityBuyerItemQuantityDropdownPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_buyer_item_quantity_dropdown_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_item_quantity_option_screen_shown = (
  props: CGPropsPublicIdentityBuyerItemQuantityOptionScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_buyer_item_quantity_option_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_item_quantity_selection_pressed = (
  props: CGPropsPublicIdentityBuyerItemQuantitySelectionPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_buyer_item_quantity_selection_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, opt_sel, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_item_add_to_cart_pressed = (
  props: CGPropsPublicIdentityBuyerItemAddToCartPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_buyer_item_add_to_cart_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, item_cnt, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_item_buy_now_pressed = (
  props: CGPropsPublicIdentityBuyerItemBuyNowPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_buyer_item_buy_now_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, item_cnt, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_item_cart_badge_updated = (
  props: CGPropsPublicIdentityBuyerItemCartBadgeUpdated,
) => ({
  ...commonProps,
  event_type: 'ac',
  event_name: 'public_identity_buyer_item_cart_badge_updated',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_item_inventory_error_occurred = (
  props: CGPropsPublicIdentityBuyerItemInventoryErrorOccurred,
) => ({
  ...commonProps,
  event_type: 'ac',
  event_name: 'public_identity_buyer_item_inventory_error_occurred',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, item_cnt, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_add_cart_confirmation_screen_shown = (
  props: CGPropsPublicIdentityBuyerAddCartConfirmationScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_buyer_add_cart_confirmation_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, currency_code, subtotal_amt, item_cnt, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_add_cart_confirmation_review_cart_pressed = (
  props: CGPropsPublicIdentityBuyerAddCartConfirmationReviewCartPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_buyer_add_cart_confirmation_review_cart_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, currency_code, subtotal_amt, item_cnt, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_add_cart_confirmation_continue_shopping_pressed = (
  props: CGPropsPublicIdentityBuyerAddCartConfirmationContinueShoppingPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name:
    'public_identity_buyer_add_cart_confirmation_continue_shopping_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, currency_code, subtotal_amt, item_cnt, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_add_cart_confirmation_dismiss_pressed = (
  props: CGPropsPublicIdentityBuyerAddCartConfirmationDismissPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_buyer_add_cart_confirmation_dismiss_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, currency_code, subtotal_amt, item_cnt, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_review_cart_screen_shown = (
  props: CGPropsPublicIdentityBuyerReviewCartScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_buyer_review_cart_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, currency_code, subtotal_amt, item_cnt, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_review_cart_remove_item_pressed = (
  props: CGPropsPublicIdentityBuyerReviewCartRemoveItemPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_buyer_review_cart_remove_item_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_review_cart_update_dropdown_pressed = (
  props: CGPropsPublicIdentityBuyerReviewCartUpdateDropdownPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_buyer_review_cart_update_dropdown_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, currency_code, subtotal_amt, item_cnt, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_review_cart_update_quantity_option_screen_shown = (
  props: CGPropsPublicIdentityBuyerReviewCartUpdateQuantityOptionScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name:
    'public_identity_buyer_review_cart_update_quantity_option_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_review_cart_update_quantity_selection_pressed = (
  props: CGPropsPublicIdentityBuyerReviewCartUpdateQuantitySelectionPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name:
    'public_identity_buyer_review_cart_update_quantity_selection_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, item_cnt, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_review_cart_paypal_checkout_pressed = (
  props: CGPropsPublicIdentityBuyerReviewCartPaypalCheckoutPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_buyer_review_cart_paypal_checkout_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, total_count, currency_code, subtotal_amt, item_cnt, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_review_cart_guest_checkout_pressed = (
  props: CGPropsPublicIdentityBuyerReviewCartGuestCheckoutPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_buyer_review_cart_guest_checkout_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, total_count, currency_code, subtotal_amt, item_cnt, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_review_cart_inventory_error_occurred = (
  props: CGPropsPublicIdentityBuyerReviewCartInventoryErrorOccurred,
) => ({
  ...commonProps,
  event_type: 'ac',
  event_name: 'public_identity_buyer_review_cart_inventory_error_occurred',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, item_cnt, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_review_cart_continue_shopping_pressed = (
  props: CGPropsPublicIdentityBuyerReviewCartContinueShoppingPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_buyer_review_cart_continue_shopping_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, total_count, currency_code, subtotal_amt, item_cnt, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_abandon_cart_screen_shown = (
  props: CGPropsPublicIdentityBuyerAbandonCartScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'public_identity_buyer_abandon_cart_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, currency_code, subtotal_amt, item_cnt, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_abandon_cart_stay_checkout_pressed = (
  props: CGPropsPublicIdentityBuyerAbandonCartStayCheckoutPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_buyer_abandon_cart_stay_checkout_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, currency_code, subtotal_amt, item_cnt, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_abandon_cart_return_shopping_pressed = (
  props: CGPropsPublicIdentityBuyerAbandonCartReturnShoppingPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_buyer_abandon_cart_return_shopping_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, currency_code, subtotal_amt, item_cnt, item_id, store_id',
  ...props,
})

Events.public_identity_buyer_abandon_cart_dismiss_pressed = (
  props: CGPropsPublicIdentityBuyerAbandonCartDismissPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'public_identity_buyer_abandon_cart_dismiss_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, total_count, currency_code, subtotal_amt, item_cnt, item_id, store_id',
  ...props,
})

Events.p2p_checkout_success_continue_shopping_pressed = (
  props: CGPropsP2pCheckoutSuccessContinueShoppingPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'p2p_checkout_success_continue_shopping_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, txn_id, store_id',
  ...props,
})

Events.p2p_checkout_success_screen_shown = (
  props: CGPropsP2pCheckoutSuccessScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'p2p_checkout_success_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, txn_id, store_id',
  ...props,
})

Events.p2p_guest_payment_form_continue_pressed = (
  props: CGPropsP2pGuestPaymentFormContinuePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'p2p_guest_payment_form_continue_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type',
  ...props,
})

// Personal Profile
Events.p2p_personal_profile_create_continue_pressed = (
  props: CGPropsP2pPersonalProfileCreateContinuePressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'p2p_personal_profile_create_continue_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, entry_point',
  ...props,
})

Events.p2p_personal_profile_create_failure_returned = (
  props: CGPropsP2pPersonalProfileCreateFailureReturned,
) => ({
  ...commonProps,
  event_type: 'ac',
  event_name: 'p2p_personal_profile_create_failure_returned',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, error_code, error_desc, correlation_id, entry_point',
  ...props,
})

Events.p2p_personal_profile_create_form_error_occurred = (
  props: CGPropsP2pPersonalProfileCreateFormErrorOccurred,
) => ({
  ...commonProps,
  event_type: 'err',
  event_name: 'p2p_personal_profile_create_form_error_occurred',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, error_code, error_desc, correlation_id, entry_point',
  ...props,
})

Events.p2p_personal_profile_create_edit_pressed = (
  props: CGPropsP2pPersonalProfileCreateEditPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'p2p_personal_profile_create_edit_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, entry_point',
  ...props,
})

Events.p2p_personal_profile_create_not_now_pressed = (
  props: CGPropsP2pPersonalProfileCreateNotNowPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'p2p_personal_profile_create_not_now_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, entry_point',
  ...props,
})

Events.p2p_personal_profile_create_screen_shown = (
  props: CGPropsP2pPersonalProfileCreateScreenShown,
) => ({
  ...commonProps,
  event_type: 'im',
  event_name: 'p2p_personal_profile_create_screen_shown',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, entry_point',
  ...props,
})

Events.p2p_personal_profile_create_success_returned = (
  props: CGPropsP2pPersonalProfileCreateSuccessReturned,
) => ({
  ...commonProps,
  event_type: 'ac',
  event_name: 'p2p_personal_profile_create_success_returned',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, entry_point',
  ...props,
})

Events.p2p_personal_profile_create_suggestion_pressed = (
  props: CGPropsP2pPersonalProfileCreateSuggestionPressed,
) => ({
  ...commonProps,
  event_type: 'cl',
  event_name: 'p2p_personal_profile_create_suggestion_pressed',
  event_props:
    'session_id, profile_type, source, store_id, acct_type, product, event_type, entry_point',
  ...props,
})

// export

export default Events
