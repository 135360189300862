// @flow

import EXIF from 'exif-js'

import { COVER_PHOTO_URLS, SELLER_COVER_PHOTO_URLS } from 'utils/constants'

const COVER_PHOTO_URL_MAPPINGS = {}
Object.values(COVER_PHOTO_URLS).forEach(({ RASTER, VECTOR }) => {
  COVER_PHOTO_URL_MAPPINGS[RASTER] = VECTOR
})

const SELLER_COVER_PHOTO_URL_MAPPINGS = {}
Object.values(SELLER_COVER_PHOTO_URLS).forEach(({ RASTER, VECTOR }) => {
  SELLER_COVER_PHOTO_URL_MAPPINGS[RASTER] = VECTOR
})

type EXIFHTMLImageElement = HTMLImageElement & {
  exifdata?: {
    Orientation: number,
  },
}

//options are:
//normalizeImageSize - decrease image size to fit in toWidth and toHeight
export function normalizeImage(
  dataUrl: string,
  toWidth: number,
  toHeight: number,
  {
    normalizeImageSize = false,
    mimeType = 'image/jpeg',
  }: { normalizeImageSize?: boolean, mimeType?: string } = {},
  callback: string => void,
) {
  const img: EXIFHTMLImageElement = document.createElement('img')
  img.onload = () => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    let changed
    let resizeWidth = img.width
    let resizeHeight = img.height

    // PNGs weigh too much
    if (mimeType === 'image/png') {
      mimeType = 'image/jpeg'
    }

    if (normalizeImageSize) {
      if (resizeWidth > toWidth) {
        resizeHeight *= toWidth / resizeWidth
        resizeWidth = toWidth
        changed = true
      }

      if (resizeHeight > toHeight) {
        resizeWidth *= toHeight / resizeHeight
        resizeHeight = toHeight
        changed = true
      }

      if (changed) {
        resizeWidth = Math.floor(resizeWidth)
        resizeHeight = Math.floor(resizeHeight)
        canvas.width = resizeWidth
        canvas.height = resizeHeight
      }
    }

    EXIF.getData(img, () => {
      if (img.exifdata && img.exifdata.Orientation) {
        if (!changed) {
          canvas.width = resizeWidth = img.width
          canvas.height = resizeHeight = img.height
        }

        // in exif orientations 5,6,7,8 the image width and height flips, so we need to flip
        // the canvas dimensions as well
        if (img.exifdata.Orientation >= 5 && img.exifdata.Orientation <= 8) {
          const swapCanvasDimensions = canvas.height

          canvas.height = canvas.width
          canvas.width = swapCanvasDimensions
        }

        switch (img.exifdata.Orientation) {
          case 2:
            ctx.transform(-1, 0, 0, 1, resizeWidth, 0)
            break
          case 3:
            ctx.transform(-1, 0, 0, -1, resizeWidth, resizeHeight)
            break
          case 4:
            ctx.transform(1, 0, 0, -1, 0, resizeHeight)
            break
          case 5:
            ctx.transform(0, 1, 1, 0, 0, 0)
            break
          case 6:
            ctx.transform(0, 1, -1, 0, resizeHeight, 0)
            break
          case 7:
            ctx.transform(0, -1, -1, 0, resizeHeight, resizeWidth)
            break
          case 8:
            ctx.transform(0, -1, 1, 0, 0, resizeWidth)
            break
          default:
            ctx.transform(1, 0, 0, 1, 0, 0)
        }
        ctx.drawImage(img, 0, 0, resizeWidth, resizeHeight)
        return callback(canvas.toDataURL(mimeType))
      } else if (changed) {
        ctx.drawImage(img, 0, 0, resizeWidth, resizeHeight)
        return callback(canvas.toDataURL(mimeType))
      }
      return callback(dataUrl)
    })
  }

  // the animation acts wonky if we don't add the animation here
  // we wait for 800ms once the image is selected, and do the transition as required
  setTimeout(() => {
    img.src = dataUrl
  }, 800)
}

/**
 * Get cover photo URL for display purposes based on the cover URL returned from
 * paypalmeserv. In some cases the URL can be mapped to an SVG (for cover photos
 * that were selected from the pre-defined list), otherwise return the regular URL
 */
export function getDisplayCoverPhotoUrl(url: string) {
  if (COVER_PHOTO_URL_MAPPINGS[url]) {
    return COVER_PHOTO_URL_MAPPINGS[url]
  } else {
    return url
  }
}

export function isPreDefinedCoverPhoto(url: string) {
  return !!COVER_PHOTO_URL_MAPPINGS[url]
}

export function isPreDefinedSellerCoverPhoto(url: string) {
  return !!SELLER_COVER_PHOTO_URL_MAPPINGS[url]
}
