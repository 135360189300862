// @flow

import React, { useEffect, useState } from 'react'
import glamorous from 'glamorous'
import { css } from 'glamor'
import getContent from 'pp-react-l10n'
import {
  Pagination,
  Button,
  white,
  borderform,
  v2blue600,
  grey200,
  grey300,
  grey700,
} from '@paypalcorp/pp-react'
import Events from 'lib/analytics/event-tags'
import { tracking } from 'lib/analytics'
import {
  smallAndSmaller,
  phoneLandscapeMaxAndSmaller,
  phoneLandscapeMinAndSmaller,
} from 'lib/styles/media-queries'
import PageSwitcher from './page'

const Container = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const PaginationContainer = css({
  backgroundColor: white,
  marginTop: '1.75rem',
  [smallAndSmaller]: {
    marginTop: '0.75rem',
  },
})

const Page = css({
  height: '0.375rem',
  width: '0.375rem',
  borderRadius: '100%',
  backgroundColor: borderform,
  margin: '0 0.25rem',
  color: white,
  ':hover': {
    color: white,
    textDecoration: 'none',
  },
})

const ActivePage = css({
  backgroundColor: v2blue600,
  color: white,
  transition: 'none',
})

const PageArrow = {
  height: '3rem',
  width: '3rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: grey700,
  background: grey200,
  fontWeight: 100,
  position: 'absolute',
  zIndex: 5,
  top: '8.75rem',
  ':hover': {
    color: grey700,
    background: grey300,
  },
  [smallAndSmaller]: {
    ':hover': {
      background: grey200,
    },
  },
}

const PageArrowLeft = {
  left: '-15%',
  [phoneLandscapeMaxAndSmaller]: {
    left: '-10%',
  },
  [phoneLandscapeMinAndSmaller]: {
    left: -8,
  },
}

const PageArrowRight = {
  right: '-15%',
  [phoneLandscapeMaxAndSmaller]: {
    right: '-10%',
  },
  [phoneLandscapeMinAndSmaller]: {
    right: -8,
  },
}

const PageSpace = css({
  display: 'none',
})

const CreateBtn = css({
  marginTop: 40,
  width: '60%',
  '@media(max-width: 425px)': {
    marginTop: 24,
    width: '80%',
  },
})

const l10n = getContent('pages/seller/landing')
const pageVariant = ['share', 'protect', 'free']

export default function SellerLanding(props) {
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    tracking(
      Events.public_identity_profile_info_screen_shown({
        page_variant: pageVariant[currentPage - 1],
      }),
    )
  }, [])

  const handleChange = page => {
    setCurrentPage(page)
  }

  const handleRoute = () => {
    tracking(
      Events.public_identity_profile_info_create_pressed({
        page_variant: pageVariant[currentPage - 1],
      }),
    )
    props.history.push('/seller/details')
  }

  return (
    <Container data-test-id="seller-landing">
      <PageSwitcher currentPage={currentPage - 1} />
      <Pagination
        totalPageCount={3}
        currentPage={currentPage}
        onChange={handleChange}
        prevPageLabel={l10n('prevPageLabel')}
        nextPageLabel={l10n('nextPageLabel')}
        paginationLabel={l10n('paginationLabel')}
        className={`${PaginationContainer}`}
        pageClassName={`${Page}`}
        activePageClassName={`${ActivePage}`}
        arrowLeftClassName={`${css(PageArrow, PageArrowLeft)}`}
        arrowRightClassName={`${css(PageArrow, PageArrowRight)}`}
        arrowSpaceClassName={`${PageSpace}`}
      />
      <Button
        className={`${CreateBtn}`}
        onClick={handleRoute}
        data-test-id="seller-landing-create"
      >
        {l10n('button.createProfile')}
      </Button>
    </Container>
  )
}
