// @flow

import { plugins } from 'glamor'
import clientData from '../client-data'
import getSpecificityPlugin from './increase-specificity'
import rtlPlugin from './rtl-plugin'
import IE10Prefixes from './ie-10-prefixes'

function init() {
  // if the document.body.id doesn't exist, we'll make a random one and assign it
  // then use that to increase our specificity
  // making it random so it can't be used for anything other than this plugin.
  document.body.id =
    document.body.id ||
    `random-glamor-id-${Math.random()
      .toString()
      .slice(2)}`
  plugins.add(getSpecificityPlugin(document.body.id))

  const { locality: { directionality = 'ltr' } = {} }: ClientData = clientData
  if (directionality === 'rtl') {
    // set the direction for styles (whether to convert them to RTL)
    plugins.add(rtlPlugin)
  }

  plugins.add(IE10Prefixes)
}

export { init }
