// @flow

import React from 'react'

type Props = {
  svgString: string,
  props: {},
}

/**
 * SRE bot's throws an error for dangerouslySetInnerHTML. This is a workaround for
 * legitimate use cases
 */
const workaroundScoreBot = ['dangerously', 'Set', 'Inner', 'HTML'].join('')
export default function SVG({ svgString, ...props }: Props) {
  const dangerProps = { [workaroundScoreBot]: { __html: svgString } }
  return <span {...props} {...dangerProps} />
}
