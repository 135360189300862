export const createSellerProfileMutation = `
  mutation createSellerProfile ( $input: CreateSellerProfileInput! ) {
    createSeller(
      input: $input,
    ) {
      handle
      userInfo {
        alternateFullName
      }
    }
  }
`
