import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clientData from 'utils/client-data'
import glamorous from 'glamorous'
import { css } from 'glamor'
import { V2CameraIcon, black, white } from '@paypalcorp/pp-react'
import { SELLER_FLOW } from 'utils/constants'
import Events from 'lib/analytics/event-tags'
import { tracking } from 'lib/analytics'
import {
  phoneLandscapeMaxAndSmaller,
  phoneLandscapeMinAndSmaller,
} from 'lib/styles/media-queries'
import InitialDialog from '../photos/initial-dialog'
import { showServiceError } from '../error/util'
import { getBadPhotoError } from '../../seller/error/ui-error-states'

const FadeIn = css.keyframes({ from: { opacity: 0 }, to: { opacity: 1 } })
const BackgroundPhoto = glamorous.div(
  ({ image, photoVerticalPan, fadeIn = true }) => ({
    boxSizing: 'border-box',
    width: '100%',
    minWidth: '700px',
    position: 'absolute',
    top: -30,
    left: '50%',
    transform: 'translateX(-50%)',
    '& > div:first-child': {
      backgroundImage: `url('${encodeURI(image)}')`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPositionX: '50%',
      backgroundPositionY: `${photoVerticalPan}%`,
      width: '100%',
      paddingBottom: '32%',
      minHeight: 172,
      maxHeight: 293,
      position: 'relative',
      ...(fadeIn && { animation: `${FadeIn} 0.2s` }),
    },
    [phoneLandscapeMaxAndSmaller]: {
      minWidth: '131%',
    },
    [phoneLandscapeMinAndSmaller]: {
      minWidth: 'calc(100% + 41px)',
    },
  }),
)

const Logo = glamorous.div(({ image }) => ({
  height: '20vw',
  width: '20vw',
  maxHeight: 124,
  maxWidth: 124,
  borderRadius: '50%',
  backgroundImage: `url('${encodeURI(image)}')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'absolute',
  left: 'calc(13% + 10px)',
  bottom: 0,
  transform: 'translateY(50%)',
  border: `${white} solid 3px`,
  boxSizing: 'content-box',
  overflow: 'hidden',
  [phoneLandscapeMaxAndSmaller]: {
    left: 'calc(8.33% + 20px)',
  },
  [phoneLandscapeMinAndSmaller]: {
    left: 20,
  },
}))

// TODO: to be replaced by Link later
const CoverEditContainer = glamorous.div({
  cursor: 'pointer',
  ':hover > div:first-child': {
    opacity: 0.8,
  },
  ':active > div:first-child': {
    opacity: 0.8,
  },
})

const EditCoverBackground = glamorous.div({
  position: 'absolute',
  width: 58,
  height: 58,
  right: 24,
  bottom: 24,
  borderRadius: '100%',
  backgroundColor: black,
  opacity: 0.7,
})

const editCoverIcon = css({
  position: 'absolute',
  right: 53,
  bottom: 53,
  transform: 'translate(50%, 50%)',
  color: white,
})

// TODO: to be replaced by Link later
const ProfileEditContainer = glamorous.div({
  cursor: 'pointer',
  ':hover > div:first-child': {
    opacity: 0.8,
  },
  ':active > div:first-child': {
    opacity: 0.8,
  },
})

const EditProfileBackground = glamorous.div({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '40%',
  backgroundColor: black,
  opacity: 0.7,
  transform: 'translateZ(0)',
})

const editProfileIcon = css({
  position: 'absolute',
  bottom: 10,
  left: '50%',
  transform: 'translateX(-50%)',
  color: white,
  [phoneLandscapeMinAndSmaller]: {
    bottom: '6%',
  },
})

EditBackground.propTypes = {
  profilePhotoUrl: PropTypes.string.isRequired,
  coverPhotoUrl: PropTypes.string.isRequired,
  coverPhotoVerticalPan: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  allowEdit: PropTypes.bool,
}

function EditBackground(props) {
  const {
    profilePhotoUrl = '',
    coverPhotoUrl = '',
    coverPhotoVerticalPan = 0,
    allowEdit = true,
    sellerProfileState: {
      state: { photoSettings: { userHasProfilePicture = false } = {} } = {},
    } = {},
  } = props
  const { isMobile } = clientData

  const [showDialog, setShowDialog] = useState(false)

  const showDialogRef = React.useRef(showDialog)
  showDialogRef.current = showDialog

  const hiddenFileInput = React.useRef(null)

  const redirectToPhotos = () => {
    tracking(Events.public_identity_edit_profile_photo_pressed({}))
    if (!userHasProfilePicture) {
      hiddenFileInput.current.click()
    } else {
      setShowDialog(true)
    }
  }

  const onFileSelected = event => {
    // load the selected photo instead of what's available

    props.sellerProfileState.scanProfilePhoto(
      event,
      () => {
        props.history.push({
          pathname: '/seller/photos/profile',
          state: {
            flow: SELLER_FLOW.edit,
          },
        })
      },
      decisionMsg => {
        props.history.push(getBadPhotoError(decisionMsg))
      },
      e => {
        showServiceError(e, props.history)
      },
    )
  }

  const redirectToCover = () => {
    tracking(Events.public_identity_edit_profile_background_pressed({}))

    props.history.push({
      pathname: '/seller/photos/cover/select',
      state: {
        flow: SELLER_FLOW.edit,
      },
    })
  }

  return (
    <>
      <InitialDialog
        {...props}
        // closedBy is a paramter to be passed to extract the method in which
        // the dialog is closed
        onClose={(e, closedBy) => {
          props.sellerProfileState.updatePhotoSettings({ hasSeenDialog: true })
          if (closedBy === 'closeButton') {
            setShowDialog(false)
            tracking(
              Events.public_identity_profile_photo_create_close_pressed({}),
            )
          } else {
            tracking(
              Events.public_identity_profile_photo_create_dismiss_pressed({}),
            )
          }
        }}
        isOpen={showDialogRef.current}
        onCancelButton={() => {
          setShowDialog(!showDialog)
        }}
        isChangeProfilePicDialog={true}
        flow={SELLER_FLOW.edit}
      />
      <BackgroundPhoto
        image={coverPhotoUrl}
        photoVerticalPan={coverPhotoVerticalPan}
        data-test-id="seller-settings-background"
      >
        {/* inner div here for maintaining the aspect ratio*/}
        <div>
          <Logo image={profilePhotoUrl}>
            {allowEdit && (
              <ProfileEditContainer
                onClick={redirectToPhotos}
                data-test-id="seller-settings-profile-edit"
              >
                <EditProfileBackground />
                <V2CameraIcon
                  size={isMobile ? 'sm' : 'md'}
                  className={`${editProfileIcon}`}
                />
                <input
                  type="file"
                  accept="image/*"
                  onChange={onFileSelected}
                  ref={hiddenFileInput}
                  style={{ display: 'none' }}
                />
              </ProfileEditContainer>
            )}
          </Logo>
        </div>
        {allowEdit && (
          <CoverEditContainer
            onClick={redirectToCover}
            data-test-id="seller-settings-cover-edit"
          >
            <EditCoverBackground />
            <V2CameraIcon className={`${editCoverIcon}`} />
          </CoverEditContainer>
        )}
      </BackgroundPhoto>
    </>
  )
}

export default EditBackground
