// @flow

import React, { useState, useEffect } from 'react'
import glamorous from 'glamorous'
import { css } from 'glamor'
import { HeadingText, TextInput, TextArea, Button } from '@paypalcorp/pp-react'
import UserInputHandle from '../../personal-profile-handle/common-components/user-input-handle'
import { PROFILE_TYPE } from '../../../utils/constants'
import getContent from 'pp-react-l10n'
import HandleMoreInfoLink from './handle-more-info-link'
import Events from '../../../lib/analytics/event-tags'
import { tracking } from '../../../lib/analytics'
import { phone6PlusPortraitMaxAndSmaller } from '../../../lib/styles/media-queries'
// content
const l10n = getContent('pages/seller/details')

const GenericInputContainerPadding = glamorous.div({
  margin: '26px 0',
})

const ButtonContainer = glamorous.div({
  display: 'flex',
})

const ContinueButton = css({
  margin: '40px auto',
  width: '65%',
  [phone6PlusPortraitMaxAndSmaller]: {
    margin: 'auto',
    width: '85%',
  },
})

function OnBoardingDetails(props) {
  const {
    sellerProfileState: {
      state: {
        businessName,
        businessHandle,
        businessDescription,
        isValidBusinessHandle,
      } = {},
    } = {},
  } = props

  useEffect(() => {
    tracking(Events.public_identity_profile_create_screen_shown({}))
  }, [])

  useEffect(() => {
    validateForm()
  }, [businessName, businessDescription, isValidBusinessHandle])

  const [handleName, setHandleName] = useState(businessHandle || '')
  const [isValid, setIsValid] = useState(false)
  const [isSuggestedSlug, setIsSuggestedSlug] = useState(false)
  const [inputs, setInputs] = useState({
    businessName: businessName || '',
    businessHandle: businessHandle || '',
    businessDescription: businessDescription || '',
  })

  const [isValidForm, setIsValidForm] = useState(false)

  useEffect(() => {
    setIsValidForm(false)
    const updates = {
      ...inputs,
      businessHandle: handleName,
      isValidBusinessHandle: isValid,
      isSuggestedBusinessHandle: isSuggestedSlug,
    }
    setInputs(updates)
    props.sellerProfileState.updateBusinessDetails(updates)
  }, [handleName, isValid])

  const handleChangeInput = e => {
    setIsValidForm(false)
    const updates = {
      ...inputs,
      [e.target.name]: e.target.value,
    }
    setInputs(updates)
    props.sellerProfileState.updateBusinessDetails(updates)
  }

  const advanceToSocial = () => {
    tracking(Events.public_identity_profile_create_next_pressed({}))
    props.history.push('/seller/social')
  }

  const validateForm = () => {
    const isValid =
      inputs.businessName !== '' &&
      inputs.businessHandle !== '' &&
      inputs.businessDescription !== '' &&
      isValidBusinessHandle &&
      !errorTextArea

    setIsValidForm(isValid)
  }

  // text area stuff
  const [value, setValue] = useState(businessDescription || '')
  const [isErrorTextArea, setErrorTextArea] = useState(false)
  const maxCharacters = 200
  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    handleChangeInput(e)
    setValue(e.target.value)
    setErrorTextArea(e.target.value.length > maxCharacters)
  }
  const errorTextArea = isErrorTextArea && l10n('main.textArea.error')

  return (
    <>
      <HeadingText size="md" role="heading" aria-level="1">
        {l10n('main.heading')}
      </HeadingText>
      <br />
      <HandleMoreInfoLink />
      <GenericInputContainerPadding>
        <TextInput
          data-test-id="businessNameInput"
          name="businessName"
          label={l10n('main.businessNameInput.label')}
          value={businessName}
          onChange={handleChangeInput}
          autoComplete="off"
        />
      </GenericInputContainerPadding>
      <GenericInputContainerPadding>
        <UserInputHandle
          {...props}
          handleName={handleName}
          setHandleName={setHandleName}
          setIsValid={setIsValid}
          setIsSuggestedSlug={setIsSuggestedSlug}
          businessName={businessName}
          profileType={PROFILE_TYPE['seller']}
        />
      </GenericInputContainerPadding>
      <GenericInputContainerPadding>
        <TextArea
          data-test-id="businessDescriptionInput"
          name="businessDescription"
          value={value}
          label={l10n('main.textArea.label')}
          onChange={handleTextAreaChange}
          showCharacterCounter
          maxCharacters={maxCharacters}
          errorText={errorTextArea}
          autoComplete="off"
        />
      </GenericInputContainerPadding>
      {isValidForm && (
        <ButtonContainer>
          <Button
            className={`${ContinueButton}`}
            data-test-id="continueButton"
            label="Next"
            onClick={advanceToSocial}
          >
            {l10n('main.continue')}
          </Button>
        </ButtonContainer>
      )}
    </>
  )
}

export default OnBoardingDetails
