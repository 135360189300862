export const activateProfile = `
 mutation ActivateProfile ($slugName: String!) {
  activateProfile (slug: $slugName) {
    isProfileStatusActive
    privacySettings {
      emailSearchEnabled
      phoneSearchEnabled
    }
  }
}`
