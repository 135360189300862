// Sizes according to -> http://getbootstrap.com/css/#grid-media-queries which is grid system VX inherits

export const SIZE_GREY_BACKGROUND = 550
export const SIZE_EXTRA_SMALL = 393
export const SIZE_SMALL = 768
export const SIZE_MEDIUM = 992
export const SIZE_LARGE = 1200

// Size for mobiles

export const phoneLandscapeMin = 480
export const phoneLandscapeMax = 640
export const iphone5portrait = 320
export const iphone6portrait = 375
export const iphone6Plusportrait = 414

// Size for tablet
export const tabletLandscapeMax = 1024

// Mobile Specific

export const foldPortraitMaxAndSmaller = `@media only screen and (max-width: 359px)`
export const phoneLandscapeMinAndSmaller = `@media only screen and (max-width: ${phoneLandscapeMin}px)`
export const phoneLandscapeMaxAndSmaller = `@media only screen and (max-width: ${phoneLandscapeMax}px)`
export const phone5PortraitMaxAndSmaller = `@media only screen and (max-width: ${iphone5portrait}px)`
export const phone6PortraitMaxAndSmaller = `@media only screen and (max-width: ${iphone6portrait}px)`
export const phone6PlusPortraitMaxAndSmaller = `@media only screen and (max-width: ${iphone6Plusportrait}px)`

// Tablet Specific
export const tabletLandscapeMaxAndSmaller = `@media only screen and (max-width: ${tabletLandscapeMax}px)`

// Mobile-first Queries (think smallest first, add media queries to support larger devices):
export const smallAndBigger = `@media only screen and (min-width: ${SIZE_SMALL}px)`
export const mediumAndBigger = `@media only screen and (min-width: ${SIZE_MEDIUM}px)`
export const largeAndBigger = `@media only screen and (min-width: ${SIZE_LARGE}px)`

// Desktop-first Queries (think largest first, add media queries to support smaller devices):
export const extraSmallAndSmaller = `@media only screen and (max-width: ${SIZE_EXTRA_SMALL}px)`
export const smallAndSmaller = `@media only screen and (max-width: ${SIZE_SMALL}px)`
export const mediumAndSmaller = `@media only screen and (max-width: ${SIZE_MEDIUM}px)`
export const largeAndSmaller = `@media only screen and (max-width: ${SIZE_LARGE}px)`
export const greyBackgroundAndSmaller = `@media only screen and (max-width: ${SIZE_GREY_BACKGROUND}px)`
