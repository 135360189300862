import React, { useState, useEffect } from 'react'
import glamorous from 'glamorous'
import {
  HeadingText,
  v2grey600,
  grey600,
  grey700,
  v2blue600,
  v2black,
  Button,
  v2blue100,
  v2blue400,
  Divider,
  white,
  v2neutral100,
  V2ShareInstagramIcon,
  V2ShareTwitterIcon,
  V2ShareFacebookIcon,
  V2WebsiteIcon,
  V2ShareIcon,
  V2MailIcon,
  V2PhoneIcon,
  Link,
} from '@paypalcorp/pp-react'
import clientData from 'utils/client-data'
import { getDomain } from 'utils/generate-urls'
import { addLoader } from 'utils/loader'
import { showServiceError } from '../error/util'
import getContent from 'pp-react-l10n'
import { tracking } from '../../../lib/analytics'
import Events from '../../../lib/analytics/event-tags'
import { phone6PlusPortraitMaxAndSmaller } from '../../../lib/styles/media-queries'
import { PAYPAL_SANS_BIG_REGULAR_FONT } from '../constants/constants'
const l10n = getContent('pages/seller/preview')

const Container = glamorous.div({
  fontFamily: PAYPAL_SANS_BIG_REGULAR_FONT,
  margin: '-30px 0 0 0',
  '& div[role=heading]': {
    color: grey700,
    textAlign: 'left',
  },
  '& div[role=heading] + p': {
    color: grey600,
    fontFamily: PAYPAL_SANS_BIG_REGULAR_FONT,
    fontSize: '1rem',
    margin: '0.5rem 0',
  },
})

const BackgroundPhoto = glamorous.div(({ image, pan }) => ({
  boxSizing: 'border-box',
  width: '100%',
  '& > div:first-child': {
    borderRadius: '13px 13px 0 0',
    backgroundImage: `url(${encodeURI(image)})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPositionX: '50%',
    backgroundPositionY: `${pan}%`,
    width: '100%',
    paddingBottom: '32%',
    maxHeight: 293,
    position: 'relative',
  },
}))

const Logo = glamorous.div(({ image }) => ({
  height: '20vw',
  width: '20vw',
  maxHeight: 85,
  maxWidth: 85,
  borderRadius: '50%',
  backgroundImage: `url(${encodeURI(image)})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundColor: v2blue100,
  position: 'absolute',
  left: 'calc(5% + 10px)',
  bottom: 0,
  transform: 'translateY(50%)',
  border: white + ' solid 3px',
  boxSizing: 'content-box',
}))

const ShadowBlock = glamorous.div({
  borderRadius: 13,
  minHeight: 400,
  margin: '40px 0',
  backgroundColor: white,
  boxShadow: '0 0 6px rgba(0, 0, 0, 0.4)',
  '& p': {
    fontFamily: PAYPAL_SANS_BIG_REGULAR_FONT,
  },
})

const disabledMod = {
  '& .disabledLink, & .disabledLink:focus, & .disabledLink:active, & .disabledLink:hover': {
    pointerEvents: 'none',
    textDecoration: 'none',
    outline: 'none',
    boxShadow: 'none',
    color: v2blue400,
  },
  '& .disabledBtn, & .disabledBtn:focus, & .disabledBtn:active, & .disabledBtn:hover': {
    border: 'none',
    pointerEvents: 'none',
    textDecoration: 'none',
    outline: 'none',
    boxShadow: 'none',
  },
  '& .disabledBtn::after': {
    border: 'none',
    pointerEvents: 'none',
    textDecoration: 'none',
    outline: 'none',
    boxShadow: 'none',
  },
}

const ContentBlock = glamorous.div({
  padding: '0 10%',
  [phone6PlusPortraitMaxAndSmaller]: {
    padding: '0 3%',
  },
  '& hr': {
    margin: '24px 0',
  },
  '& .aboutBlock': {
    color: v2black,
    fontWeight: 'bold',
    fontSize: '0.857rem',
    lineHeight: '1rem',
  },
  '& .aboutBlock + p': {
    color: v2grey600,
    fontSize: '0.857rem',
    lineHeight: '1rem',
  },
  '& .contacts': {
    '& p, & a': {
      fontSize: '0.857rem',
      lineHeight: '1rem',
    },
    '& .email span': {
      color: grey700,
    },
    '& p': {
      '& span': {
        marginRight: '0.375rem',
        transform: 'translateY(4px)',
      },
    },
    '& a svg': {
      transform: 'translateY(2px)',
    },
  },
  '& .payButton': {
    textAlign: 'center',
    '& button': {
      padding: 0,
      margin: '40px auto',
      lineHeight: 1,
      width: 226,
      height: 36,
    },
  },
  ...disabledMod,
})

const ConfirmationBlock = glamorous.div({
  textAlign: 'center',
  fontFamily: PAYPAL_SANS_BIG_REGULAR_FONT,
  '& p': {
    margin: '70px 0 36px 0',
    [phone6PlusPortraitMaxAndSmaller]: {
      margin: '40px 0 20px 0',
    },
    color: grey600,
    fontFamily: PAYPAL_SANS_BIG_REGULAR_FONT,
    fontSize: '1rem',
  },
})

const ShareIcon = glamorous.div({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 15,
  color: v2blue600,
  backgroundColor: v2neutral100,
  width: 27,
  height: 27,
  borderRadius: '50%',
})

const SellerName = glamorous.h2({
  fontSize: '1.125rem',
  fontWeight: 'normal',
  lineHeight: '1.5rem',
  margin: '12% 0 5px 0',
  padding: '10px 0 0 0',
  overflowWrap: 'break-word',
})

const Slug = glamorous.p({
  fontFamily: PAYPAL_SANS_BIG_REGULAR_FONT,
  fontSize: '0.857rem',
  lineHeight: '1rem',
})

const LocationText = glamorous.p({
  color: grey600,
  fontFamily: PAYPAL_SANS_BIG_REGULAR_FONT,
  fontSize: '0.857rem',
  lineHeight: '1rem',
  marginBottom: 20,
})

const LocationTextPlaceHolder = glamorous.p({
  clear: 'both',
  width: '100%',
  height: 5,
})

const AboutDescriptionText = glamorous.p({
  wordWrap: 'break-word',
})

function Preview(props) {
  const {
    sellerProfileState: {
      state: {
        businessName,
        businessHandle,
        businessDescription,
        sellerProfileCreated = false,
        socialMedia: {
          instagramHandle,
          facebookUrl,
          twitterHandle,
          websiteUrl,
        } = {},
        contactsOption: { phone, email, location } = {},
        photoSettings: {
          initialCoverPhoto = undefined,
          cachedCoverPhoto = '',
          userHasProfilePicture = false,
          cachedDataProfilePhoto,
          coverPhotoPan = '50',
        } = {},
      } = {},
    } = {},
  } = props

  const hasCachedProfilePhoto = !!cachedDataProfilePhoto
  const [profileImage, setProfileImage] = useState(
    'https://www.paypalobjects.com/p2p_seller_profiles/storeImage.jpg',
  )

  useEffect(() => {
    tracking(Events.public_identity_profile_preview_screen_shown({}))
    if (sellerProfileCreated) {
      addLoader()
      const host = getDomain(clientData)
      window.location.href = `${host}/paypalme/seller/${businessHandle}/dashboard`
    }
  }, [])

  const createSellerProfile = () => {
    tracking(Events.public_identity_profile_preview_finish_pressed({}))
    props.sellerProfileState.createSellerProfile(onSuccessCreate, onErrorCreate)
  }

  const onSuccessCreate = profile => {
    const {
      createSeller: {
        handle: businessHandle = '',
        userInfo: { alternateFullName: businessName = '' } = {},
      } = {},
    } = profile
    props.history.push({
      pathname: '/seller/success',
      state: {
        businessHandle,
        businessName,
      },
    })
  }

  const onErrorCreate = e => {
    showServiceError(
      e,
      props.history,
      'seller_profile_onboarding_preview_screen',
    )
  }

  useEffect(() => {
    if (hasCachedProfilePhoto || userHasProfilePicture) {
      setProfileImage(cachedDataProfilePhoto)
    }
  }, [])

  return (
    <Container data-test-id="seller-preview-screen">
      <HeadingText size="md" role="heading" aria-level="1">
        {l10n('title')}
      </HeadingText>
      <p>{l10n('description')}</p>
      <ShadowBlock>
        <BackgroundPhoto
          image={cachedCoverPhoto || initialCoverPhoto}
          pan={coverPhotoPan}
        >
          {/* inner div here for maintaining the aspect ratio*/}
          <div role="banner">
            <Logo image={profileImage} />
          </div>
        </BackgroundPhoto>
        <ContentBlock>
          <SellerName role="heading" aria-level="2">
            {businessName}
          </SellerName>
          <Slug>@{businessHandle}</Slug>
          {location && location !== 'donotshow' ? (
            <LocationText>{location}</LocationText>
          ) : (
            <LocationTextPlaceHolder />
          )}

          {instagramHandle ? (
            <ShareIcon>
              <V2ShareInstagramIcon size="xs" />
            </ShareIcon>
          ) : null}

          {facebookUrl ? (
            <ShareIcon>
              <V2ShareFacebookIcon size="xs" />
            </ShareIcon>
          ) : null}

          {twitterHandle ? (
            <ShareIcon>
              <V2ShareTwitterIcon size="xs" />
            </ShareIcon>
          ) : null}

          {websiteUrl ? (
            <ShareIcon>
              <V2WebsiteIcon size="xs" />
            </ShareIcon>
          ) : null}

          <ShareIcon>
            <V2ShareIcon size="xs" />
          </ShareIcon>

          <Divider />
          <HeadingText className="aboutBlock" size="sm">
            {l10n('about')}
          </HeadingText>
          <AboutDescriptionText>{businessDescription}</AboutDescriptionText>
          <div className="contacts">
            {phone && phone !== 'donotshow' ? (
              <p>
                <V2PhoneIcon size="xs" />
                {phone}
              </p>
            ) : null}
            {email && email !== 'donotshow' ? (
              <Link
                className="email disabledLink"
                href={`mailto:${email}`}
                leftIcon={<V2MailIcon size="xs" />}
                theme="v2"
              >
                {email}
              </Link>
            ) : null}
          </div>
          <div className="payButton">
            <Button disabled className="disabledBtn">
              {l10n('pay')}
            </Button>
          </div>
        </ContentBlock>
      </ShadowBlock>
      <ConfirmationBlock>
        <p>{l10n('lookConfirm')}</p>
        <Button data-test-id="finish-create" onClick={createSellerProfile}>
          {l10n('finish')}
        </Button>
      </ConfirmationBlock>
    </Container>
  )
}
export default Preview
