// @flow

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { css } from 'glamor'
import glamorous from 'glamorous'

import { BodyText, HeadingText, Button } from '@paypalcorp/pp-react'
import { SELLER_FLOW } from 'utils/constants'

import InitialDialog from './initial-dialog'
import CoverPan from './cover-pan'

import Events from '../../../lib/analytics/event-tags'
import { tracking } from '../../../lib/analytics'
import { phone6PlusPortraitMaxAndSmaller } from '../../../lib/styles/media-queries'
import { getUploadPhotoError } from '../../seller/error/ui-error-states'

// content
import getContent from 'pp-react-l10n'
const l10n = getContent('pages/seller/photos')

const PageContainer = glamorous.div({
  position: 'relative',
  height: '50vh',
})

const ButtonContainer = glamorous.div({
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%,0%)',
  bottom: '-30%',
  [phone6PlusPortraitMaxAndSmaller]: {
    width: '100%',
    bottom: '-40%',
  },
})

function PhotosUpload(props) {
  const {
    sellerProfileState: {
      state: {
        photoSettings: {
          hasSeenDialog,
          cachedDataProfilePhoto,
          isCoverDraggable,
        } = {},
        sellerProfileDetails: { id: businessHandle = '' } = {},
      } = {},
    } = {},
    location: { state: { flow = SELLER_FLOW.create } = {} } = {},
  } = props

  // This takes care of the user clicks back case, and
  // then we could be stuck in the cover dragging true state
  const isDraggable = props.history.action === 'POP' ? false : isCoverDraggable

  const hasCachedProfilePhoto = !!cachedDataProfilePhoto

  const isChangeProfilePicDialog =
    flow === SELLER_FLOW.create && hasCachedProfilePhoto === true

  const [showDialog, setShowDialog] = useState(false)

  const showDialogRef = React.useRef(showDialog)
  showDialogRef.current = showDialog

  useEffect(() => {
    let timer
    if (!hasSeenDialog && flow !== SELLER_FLOW.edit) {
      timer = setTimeout(() => {
        setShowDialog(true)
      }, 500)
    } else {
      setShowDialog(false)
      clearTimeout(timer)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [])

  const editCoverPhoto = async () => {
    if (flow === SELLER_FLOW.edit) {
      tracking(Events.public_identity_background_adjust_save_pressed({}))
    }
    props.sellerProfileState.updateCachedCoverPhoto()
    const {
      sellerProfileState: {
        state: {
          photoSettings: { cachedCoverPhoto = '', coverPhotoPan = '' } = {},
        } = {},
      } = {},
    } = props

    const valueObj = [
      {
        op: 'replace',
        path: '/personalization_settings/cover_photo_url',
        value: cachedCoverPhoto,
      },
      {
        op: 'replace',
        path: '/personalization_settings/cover_photo_vertical_pan',
        value: coverPhotoPan,
      },
    ]

    try {
      await props.sellerProfileState.editSellerSettings(
        valueObj,
        businessHandle,
      )
    } catch (err) {
      const isMediaservError = false,
        photoFlow = 'cover'
      props.history.push(getUploadPhotoError(isMediaservError, photoFlow))
    }
  }

  // The CTA has a two different possibile routes AND three different
  // content depending on the 'state' of the user's photo upload journey
  const renderContinueCTA = () => {
    let routeToString = '/seller/preview',
      content = '',
      onClick = () => {}

    if (flow === SELLER_FLOW.edit) {
      // Save (User already exits - Edit flow)
      content = l10n('saveCover')
      onClick = async () => editCoverPhoto()
      routeToString = `/seller/${businessHandle}/settings`
    } else if (isDraggable) {
      if (hasCachedProfilePhoto) {
        // Save and Preview Profile
        content = l10n('saveAndPreview')
        onClick = () => {
          tracking(Events.public_identity_background_adjust_save_pressed({}))
          props.sellerProfileState.updateCachedCoverPhoto()
        }
      } else {
        // Save (we don't have a profile photo)
        content = l10n('saveCover')
        onClick = () => {
          tracking(Events.public_identity_background_adjust_save_pressed({}))
          props.sellerProfileState.updateCachedCoverPhoto()
        }
        routeToString = '/seller/photos'
      }
    } else {
      // default
      content = l10n('previewProfile')
    }

    return (
      <ButtonContainer>
        <Button
          data-test-id="preview-photo"
          className={`${css({
            [phone6PlusPortraitMaxAndSmaller]: { width: '100%' },
          })}`}
          as={Link}
          to={routeToString}
          onClick={() => {
            tracking(
              Events.public_identity_profile_photo_edit_preview_pressed({}),
            )
            onClick()
          }}
        >
          {content}
        </Button>
      </ButtonContainer>
    )
  }

  return (
    <PageContainer>
      <InitialDialog
        {...props}
        // closedBy is a paramter to be passed to extract the method in which
        // the dialog is closed
        onClose={(e, closedBy) => {
          props.sellerProfileState.updatePhotoSettings({ hasSeenDialog: true })
          if (closedBy === 'closeButton') {
            setShowDialog(false)
            tracking(
              Events.public_identity_profile_photo_create_close_pressed({}),
            )
          } else {
            tracking(
              Events.public_identity_profile_photo_create_dismiss_pressed({}),
            )
          }
        }}
        isOpen={showDialogRef.current}
        onCancelButton={() => {
          setShowDialog(!showDialog)
        }}
        isChangeProfilePicDialog={isChangeProfilePicDialog}
        flow={flow}
      />
      <HeadingText size="md">{l10n('heading')}</HeadingText>
      <BodyText
        className={`${css({ marginTop: '1rem', marginBottom: '3rem' })}`}
      >
        {l10n('body')}
      </BodyText>
      <CoverPan
        {...props}
        isDraggable={isDraggable}
        isEditable={!isDraggable}
        handleDialogView={setShowDialog}
      />
      {renderContinueCTA()}
    </PageContainer>
  )
}

export default PhotosUpload
