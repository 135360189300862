// @flow
import axios from 'axios'
import getContent from 'pp-react-l10n'
import clientData from 'utils/client-data'
import { tracking } from 'lib/analytics'
import Suggestions from './suggestions-container'
import Events from 'lib/analytics/event-tags'
import { ENTRY_POINT_UNKNOWN } from 'utils/constants'
import React, { useEffect, forwardRef } from 'react'
import { LoadingSpinner, TextInput } from '@paypalcorp/pp-react'
import useSuggestionsAPI from '../custom-hooks/useSuggestionsAPI'
import useCharacterValidation from '../custom-hooks/useCharacterValidation'

const { CancelToken } = axios

let cancelToken
const l10n = getContent('pages/discoverability')
const l10nSeller = getContent('pages/seller/details')

/**
 * A common component shared between personal profile(Discoverability feat.) and seller profile.
 */
const UserInputHandle = forwardRef((props, inputRef) => {
  const {
    handleName,
    setHandleName = () => {},
    setIsValid = () => {},
    pageLoader,
    setPageLoader = () => {},
    profileType,
    setIsSuggestedSlug = () => {},
    creationError,
    businessName,
  } = props
  const helperText = l10nSeller('main.businessHandleInput.helper')
  const [
    fieldError,
    delayedValidation,
    setFieldError,
  ] = useCharacterValidation()
  const {
    suggestions,
    setSuggestions,
    isCheckingHandleAvailability,
    isHandleAvailable,
    setIsHandleAvailable,
    fetchSuggestionsAndAvailability,
  } = useSuggestionsAPI(profileType, businessName)

  const userMessages = {
    taken: l10n('handleInput.error.taken'),
    empty: l10n('handleInput.error.empty'),
    invalid: l10n('handleInput.error.invalid'),
    tooShort: l10nSeller('main.businessHandleInput.error.beOver'),
    tooLong: l10nSeller('main.businessHandleInput.error.beUnder'),
    available: l10n('handleInput.available', {
      handle: handleName,
    }),
  }

  // On page load if we have a default name sent from the consumer of this component.
  useEffect(() => {
    if (handleName) {
      generateCancelToken()
      setFieldError({ type: 'success' })
    }
  }, [creationError])

  useEffect(() => {
    validateHandle(handleName)
  }, [fieldError])

  useEffect(() => {
    setIsValid(isHandleAvailable)
  }, [isHandleAvailable])

  /**
   * If input field has a type other than 'none' / 'success'.
   * Refer useCharacterValidation.js for other types of errors.
   * @returns
   */
  const isError = () => {
    if (fieldError.type !== 'none' && fieldError.type !== 'success') {
      tracking(
        Events.p2p_personal_profile_create_form_error_occurred({
          acct_type: 'personal',
          error_desc: 'form error occurred.',
          entry_point: clientData.entryPoint || ENTRY_POINT_UNKNOWN,
        }),
      )
      return true
    }
    return false
  }

  /**
   * We are tying the rendering of <Suggestions /> to suggestions.length array.
   * If we have suggestions, we must show them.
   * @returns
   */
  const isSlugTaken = () => {
    return suggestions.length > 0
  }

  /**
   * Client-side validation must be 'success' for us to start the API call.
   * If we started the pageLoader during page load, we will set it to false once the fetch API is done.
   * @param {*} input
   */
  const validateHandle = input => {
    if (fieldError.type === 'success') {
      const fetchSuggestionsPromise = fetchSuggestionsAndAvailability(
        input,
        cancelToken,
        businessName,
      )
      fetchSuggestionsPromise.then(result => {
        if (result && pageLoader) {
          setPageLoader(false)
        }
      })
    }
  }

  /**
   * When user selects from suggestions.
   * @param {*} e
   */
  const onSelectSuggestion = e => {
    tracking(
      Events.p2p_personal_profile_create_suggestion_pressed({
        entry_point: clientData.entryPoint || ENTRY_POINT_UNKNOWN,
      }),
    )
    resetHandleStates(e.target.value, true, 'none', true)
  }

  /**
   * On input field change.
   * @param {*} e
   */
  const onChange = async e => {
    generateCancelToken() // cancel any ongoing calls
    resetHandleStates(e.target.value, false, 'none', false)
    delayedValidation(e.target.value)
  }

  const onBlur = () => {
    tracking(
      Events.p2p_personal_profile_create_edit_pressed({
        entry_point: clientData.entryPoint || ENTRY_POINT_UNKNOWN,
      }),
    )
    delayedValidation.flush()
  }
  /**
   * Generates cancelToken for axios calls that happen in useSuggestionsAPI hook.
   * Cancel any ongoing slug suggestions/availability call
   */
  const generateCancelToken = () => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    cancelToken = CancelToken.source()
  }

  /**
   * We need to reset the initial state variables everytime user changes the current
   * handleName text field OR when selects from suggestions list.
   * @param {*} newHandleName
   * @param {*} isAvailable
   * @param {*} fieldErrType
   * @param {*} isSuggestedSlug
   */
  const resetHandleStates = (
    newHandleName,
    isAvailable,
    fieldErrType,
    isSuggestedSlug,
  ) => {
    setHandleName(newHandleName)
    setIsHandleAvailable(isAvailable)
    setFieldError({ type: fieldErrType })
    setIsSuggestedSlug(isSuggestedSlug) // Used to track FPTI event for Seller Profile if user picked suggested slug.
    setSuggestions([])
  }

  let errorTextMessage = null

  if (isError()) {
    errorTextMessage = userMessages[fieldError.type]
  } else if (isSlugTaken()) {
    errorTextMessage = userMessages['taken']
  }

  return (
    <>
      <TextInput
        data-test-id="handleSlugInput"
        ref={inputRef}
        value={handleName}
        autoComplete="off"
        prefix="@"
        label={l10n('handleInput.label')}
        helperText={!isError() && helperText}
        onChange={onChange}
        onBlur={onBlur}
        errorText={errorTextMessage}
        successText={isHandleAvailable && userMessages['available']}
        rightIcon={
          isCheckingHandleAvailability && (
            <div>
              <LoadingSpinner screenReaderText="loading" />
            </div>
          )
        }
      />
      {isSlugTaken() && (
        <Suggestions
          onSelectSuggestion={onSelectSuggestion}
          suggestions={suggestions}
        />
      )}
    </>
  )
})

export default UserInputHandle
