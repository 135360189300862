import React, { useState, useRef, useEffect } from 'react'
import glamorous from 'glamorous'
import { css } from 'glamor'
import { HeadingText, BodyText, RadioButton, Link } from '@paypalcorp/pp-react'
import getContent from 'pp-react-l10n'
import { SELLER_SETTING_TYPES as types } from 'utils/constants'
import Events from 'lib/analytics/event-tags'
import { tracking } from 'lib/analytics'
import { phone6PlusPortraitMaxAndSmaller } from 'lib/styles/media-queries'
import { showServiceError } from './../error/util'
import SaveButton from '../shared/save-button'

const l10n = getContent('pages/seller/settings')

const Container = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
})

const Heading = css({
  marginTop: 80,
  [phone6PlusPortraitMaxAndSmaller]: {
    marginTop: 20,
  },
})

const SubHeading = css({
  textAlign: 'left',
  margin: '16px 0 20px',
})

const Legend = glamorous.span({
  border: 0,
  clip: 'rect(1px, 1px, 1px, 1px)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  width: '1px',
})

function PhoneSetting(props) {
  const {
    sellerProfileState: {
      state: {
        sellerProfileDetails: {
          id: businessHandle = '',
          user_info: { display_mobile_phone: phone = '' } = {},
          privacy_settings: {
            display_mobile_phone_enabled: searchEnabled = false,
          } = {},
        } = {},
      } = {},
    } = {},
  } = props

  useEffect(() => {
    tracking(
      Events.public_identity_profile_edit_phone_screen_shown({
        opsel: searchEnabled ? 'show' : 'hide',
      }),
    )
  }, [])

  const phoneSearchEnabled = searchEnabled ? types.SHOW : types.HIDE
  const [value, setValue] = useState(phoneSearchEnabled)
  const { editSellerSettings } = props.sellerProfileState
  const { current: initialValue } = useRef(value)
  const isChanged = value !== initialValue

  const handleChangeInput = e => {
    setValue(e.target.value)
  }

  const handleSave = async () => {
    tracking(
      Events.public_identity_profile_edit_phone_save_pressed({
        opsel: value === types.SHOW ? 'show' : 'hide',
      }),
    )

    const valueObj = [
      {
        op: 'replace',
        path: '/privacy_settings/display_mobile_phone_enabled',
        value: value === types.SHOW,
      },
    ]

    try {
      await editSellerSettings(valueObj, businessHandle)
      props.history.goBack()
    } catch (err) {
      showServiceError(err, props.history, 'seller-profile-settings-edit-phone')
    }
  }

  const options = [
    {
      label: l10n('edit.phone.option.show', { phone }),
      value: types.SHOW,
    },
    {
      label: l10n('edit.phone.option.hide', { phone }),
      value: types.HIDE,
    },
  ]

  return (
    <Container data-test-id="seller-settings-phone-screen">
      <HeadingText
        size="md"
        role="heading"
        aria-level="1"
        className={`${Heading}`}
      >
        {l10n('phone')}
      </HeadingText>
      <BodyText className={`${SubHeading}`}>
        {/* TODO: Update links */}
        {l10n('primaryAccountInfo', {
          primaryAccountLink: (
            <Link
              href="/myaccount/settings"
              onClick={() =>
                tracking(
                  Events.public_identity_edit_profile_settings_pressed({}),
                )
              }
            >
              {l10n('primaryAccountSettings')}
            </Link>
          ),
        })}
      </BodyText>
      <RadioButton
        name="phone"
        label={<Legend>{l10n('phone')}</Legend>}
        value={value}
        options={options}
        onChange={handleChangeInput}
        data-test-id="seller-settings-phone-input"
      />
      {isChanged && <SaveButton handleSave={handleSave} />}
    </Container>
  )
}

export default PhoneSetting
