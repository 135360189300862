/* global PAYPAL */

import debug from 'debug'

import { trackBackButton, trackElement } from 'lib/analytics'
import Events from 'lib/analytics/event-tags'

import userAttributes from 'utils/user-attributes'

export default function setupAnalytics() {
  const fptiData = {}
  const dataNode = document.getElementById('react-fpti')

  const fptiDataValue = (dataNode && dataNode.innerHTML) || '{}'

  try {
    Object.assign(fptiData, JSON.parse(fptiDataValue))
    debug('ppme:fpti-data:success')('load', fptiData)
  } catch (error) {
    debug('ppme:fpti-data:error')('load', error)
  }

  window.fpti = fptiData && fptiData.tracking && fptiData.tracking.fpti

  const {
    name: fptiName,
    dataString: fptiDataString,
    serverURL: fptiServerURL,
  } = window.fpti

  window.PAYPAL = window.PAYPAL || {}
  if (typeof window.PAYPAL.analytics !== 'undefined') {
    window.PAYPAL.core = window.PAYPAL.core || {}
    PAYPAL.analytics.setupComplete = function() {
      PAYPAL.analytics._isReady = true
    }
    window.PAYPAL.core[fptiName] = window.PAYPAL.analytics.setup({
      data: fptiDataString,
      url: fptiServerURL,
    })
  }

  Events.initCommonProps(userAttributes)

  window.addEventListener('popstate', trackBackButton)

  const handler = function(event) {
    const elem = event.target.closest('[data-pagename]')
    if (elem) {
      trackElement(elem)
    }
  }
  document.body.addEventListener('click', handler, false)

  return window.PAYPAL.fpti
}
