import React from 'react'
import glamorous from 'glamorous'
import {
  V2ShareInstagramIcon,
  V2ShareTwitterIcon,
  V2ShareFacebookIcon,
  V2ShareIcon,
  v2blue600,
  v2neutral100,
} from '@paypalcorp/pp-react'
import PropTypes from 'prop-types'
import ShareProfilePopup from '../../shared/components/share-profile-popup'
import getContent from 'pp-react-l10n'
import { tracking } from 'lib/analytics'
import Events from 'lib/analytics/event-tags'
const l10n = getContent('pages/shared')

const SocialLink = glamorous.a({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 15,
  color: v2blue600,
  backgroundColor: v2neutral100,
  width: 40,
  height: 40,
  borderRadius: '50%',
})

const DisabledSocialIcon = glamorous.span({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 15,
  color: v2blue600,
  backgroundColor: v2neutral100,
  width: 40,
  height: 40,
  borderRadius: '50%',
})

const INSTAGRAM_PREFIX = 'https://www.instagram.com/'
const TWITTER_PREFIX = 'https://www.twitter.com/'

export default function SocialLinks({
  disabledLinks = false,
  instagramUsername = '',
  twitterHandle = '',
  facebookUrl = '',
  copyLink = '',
  sellerName = '',
  payerId,
}) {
  const [isShareModalOpen, setIsShareModalOpen] = React.useState(false)
  if (facebookUrl && !facebookUrl.includes('http')) {
    facebookUrl = '//' + facebookUrl
  }

  function handleShareModalOpen() {
    if (!disabledLinks) {
      tracking(
        Events.public_identity_profile_share_pressed({
          receiver_id: payerId,
          social_platform: 'share',
        }),
      )
      setIsShareModalOpen(true)
    }
  }

  const SocialElement = disabledLinks ? DisabledSocialIcon : SocialLink

  return (
    <React.Fragment>
      {instagramUsername && (
        <SocialElement
          onClick={() =>
            !disabledLinks &&
            tracking(
              Events.public_identity_profile_view_social_pressed({
                receiver_id: payerId,
                social_platform: 'instagram',
              }),
            )
          }
          href={`${INSTAGRAM_PREFIX}${instagramUsername}`}
          target="_blank"
          data-test-id="instagram-social-link"
        >
          <V2ShareInstagramIcon size="sm" />
        </SocialElement>
      )}

      {facebookUrl && (
        <SocialElement
          onClick={() =>
            !disabledLinks &&
            tracking(
              Events.public_identity_profile_view_social_pressed({
                receiver_id: payerId,
                social_platform: 'facebook',
              }),
            )
          }
          href={facebookUrl}
          target="_blank"
          data-test-id="facebook-social-link"
        >
          <V2ShareFacebookIcon size="sm" />
        </SocialElement>
      )}

      {twitterHandle && (
        <SocialElement
          onClick={() =>
            !disabledLinks &&
            tracking(
              Events.public_identity_profile_view_social_pressed({
                receiver_id: payerId,
                social_platform: 'twitter',
              }),
            )
          }
          href={`${TWITTER_PREFIX}${twitterHandle}`}
          target="_blank"
          data-test-id="twitter-social-link"
        >
          <V2ShareTwitterIcon size="sm" />
        </SocialElement>
      )}

      <SocialElement
        href={'#'}
        onClick={handleShareModalOpen}
        data-test-id="share-modal-link"
      >
        <V2ShareIcon size="sm" />
      </SocialElement>
      <ShareProfilePopup
        isOpen={isShareModalOpen}
        handleDisplay={() => setIsShareModalOpen(false)}
        header={l10n('share.modal.title')}
        subheader={l10n('share.modal.description')}
        copyLink={copyLink}
        sellerName={sellerName}
      />
    </React.Fragment>
  )
}

SocialLinks.propTypes = {
  instagramUsername: PropTypes.string,
  facebookUrl: PropTypes.string,
  twitterHandle: PropTypes.string,
  copyLink: PropTypes.string.isRequired,
  sellerName: PropTypes.string.isRequired,
  disabledLinks: PropTypes.bool,
}
