export const DEFAULT_PROFILE_PHOTO =
  'https://www.paypalobjects.com/p2p/personalProfileImage.jpg'

export const COVER_PHOTO_URLS = {
  GREEN: {
    RASTER: 'https://www.paypalobjects.com/profiles/cover/green.jpg',
    VECTOR: 'https://www.paypalobjects.com/profiles/cover/green.svg',
  },
  BLUE: {
    RASTER: 'https://www.paypalobjects.com/profiles/cover/blue.jpg',
    VECTOR: 'https://www.paypalobjects.com/profiles/cover/blue.svg',
  },
  PURPLE: {
    RASTER: 'https://www.paypalobjects.com/profiles/cover/purple.jpg',
    VECTOR: 'https://www.paypalobjects.com/profiles/cover/purple.svg',
  },
  RED: {
    RASTER: 'https://www.paypalobjects.com/profiles/cover/red.jpg',
    VECTOR: 'https://www.paypalobjects.com/profiles/cover/red.svg',
  },
}

export const INSTRUMENTATION_MAP = {
  profile: {
    OLD: {
      PAGE_GROUP: 'main:ppme:profile:preview',
      PAGE_NAME: 'main:ppme:profile:preview',
    },
    NEW: {
      back: 'public_identity_my_profile_back_pressed',
      close: 'public_identity_my_profile_close_pressed',
    },
  },
  about: {
    OLD: {
      PAGE_GROUP: 'main:ppme:about',
      PAGE_NAME: 'main:ppme:about',
    },
    NEW: {
      back: 'public_identity_edit_about_back_pressed',
      close: 'public_identity_edit_about_close_pressed',
    },
  },
  confirm: {
    OLD: {
      PAGE_GROUP: 'main:ppme:hide:confirm',
      PAGE_NAME: 'main:ppme:hide:confirm',
    },
    NEW: {
      back: 'public_identity_hide_profile_confirmation_back_pressed',
      close: 'public_identity_hide_profile_confirmation_close_pressed',
    },
  },
  location: {
    OLD: {
      PAGE_GROUP: 'main:ppme:location',
      PAGE_NAME: 'main:ppme:location',
    },
    NEW: {
      back: 'public_identity_edit_location_back_pressed',
      close: 'public_identity_edit_location_close_pressed',
    },
  },
  recipient: {
    OLD: {
      PAGE_GROUP: 'main:ppme:recipientprofile',
      PAGE_NAME: 'main:ppme:recipientprofile:::',
    },
  },
  settings: {
    OLD: {
      PAGE_GROUP: 'main:ppme:settings',
      PAGE_NAME: 'main:ppme:settings:::',
    },
    NEW: {
      close: 'public_identity_edit_profile_close_pressed',
    },
  },
  username: {
    OLD: {
      PAGE_GROUP: 'main:ppme:username',
      PAGE_NAME: 'main:ppme:username:::change',
    },
    NEW: {
      back: 'public_identity_edit_username_back_pressed',
      close: 'public_identity_edit_username_close_pressed',
    },
  },
  'cover/select': {
    OLD: {
      PAGE_GROUP: 'main:ppme:background:edit',
      PAGE_NAME: 'main:ppme:background:edit',
    },
    NEW: {
      back: 'public_identity_background_edit_back_pressed',
      close: 'public_identity_background_edit_close_pressed',
    },
  },
  'cover/pan': {
    OLD: {
      PAGE_GROUP: 'main:ppme:background:adjust',
      PAGE_NAME: 'main:ppme:background:adjust',
    },
    NEW: {
      back: 'public_identity_background_adjust_back_pressed',
      close: 'public_identity_background_adjust_close_pressed',
    },
  },
  picture: {
    OLD: {
      PAGE_GROUP: 'main:ppme:profile_pic:edit',
      PAGE_NAME: 'main:ppme:profile_pic:edit',
    },
    NEW: {
      back: 'public_identity_profile_picture_edit_back_pressed',
      close: 'public_identity_profile_picture_edit_close_pressed',
    },
  },
}

export const SELLER_INSTRUMENTATION_MAP = {
  landing: {
    back: 'public_identity_profile_info_back_pressed',
    close: 'public_identity_profile_info_close_pressed',
  },
  success: {
    close: 'public_identity_profile_success_close_pressed',
  },
  details: {
    back: 'public_identity_profile_create_back_pressed',
    close: 'public_identity_profile_create_close_pressed',
  },
  social: {
    back: 'public_identity_profile_create_social_back_pressed',
    close: 'public_identity_profile_create_social_close_pressed',
  },
  photos: {
    back: 'public_identity_profile_photo_edit_back_pressed',
    close: 'public_identity_profile_photo_edit_close_pressed',
  },
  'photos/profile': {
    back: 'public_identity_profile_picture_edit_back_pressed',
    close: 'public_identity_profile_picture_edit_close_pressed',
  },
  'photos/cover/select': {
    back: 'public_identity_background_edit_back_pressed',
    close: 'public_identity_background_edit_close_pressed',
  },
  'photos/cover/pan': {
    back: 'public_identity_background_adjust_back_pressed',
    close: 'public_identity_background_adjust_close_pressed',
  },
  preview: {
    back: 'public_identity_profile_preview_back_pressed',
    close: 'public_identity_profile_preview_close_pressed',
  },
  error: {
    back: 'public_identity_photo_upload_error_back_pressed',
    close: 'public_identity_photo_upload_error_close_pressed',
  },
  'error/create/handle': {
    back: 'public_identity_profile_username_duplicate_back_pressed  ',
    close: 'public_identity_profile_username_duplicate_close_pressed',
  },
  dashboard: {
    back: 'public_identity_my_profile_back_pressed',
    close: 'public_identity_my_profile_close_pressed',
  },
  settings: {
    back: 'public_identity_edit_profile_back_pressed',
    close: 'public_identity_edit_profile_close_pressed',
  },
  'settings/name': {
    back: 'public_identity_profile_edit_name_back_pressed',
    close: 'public_identity_profile_edit_name_close_pressed',
  },
  'settings/description': {
    back: 'public_identity_edit_about_back_pressed',
    close: 'public_identity_edit_about_close_pressed',
  },
  'settings/phone': {
    back: 'public_identity_profile_edit_phone_back_pressed',
    close: 'public_identity_profile_edit_phone_close_pressed',
  },
  'settings/email': {
    back: 'public_identity_profile_edit_email_back_pressed',
    close: 'public_identity_profile_edit_email_close_pressed',
  },
  'settings/location': {
    back: 'public_identity_edit_location_back_pressed',
    close: 'public_identity_edit_location_close_pressed',
  },
  'settings/profile': {
    back: 'public_identity_profile_edit_status_back_pressed',
    close: 'public_identity_profile_edit_status_close_pressed',
  },
  'settings/profile/confirm': {
    back: 'public_identity_hide_profile_confirmation_back_pressed',
    close: 'public_identity_hide_profile_confirmation_close_pressed',
  },
  'settings/social': {
    back: 'public_identity_profile_edit_social_back_pressed',
    close: 'public_identity_profile_edit_social_close_pressed',
  },
}

export const ILLUSTRATIONS = {
  WARNING:
    'https://www.paypalobjects.com/paypal-ui/illustrations/svg/warning.svg',
}

export const LOGOS = {
  PAYPAL_PRIMARY:
    'https://www.paypalobjects.com/paypal-ui/logos/svg/paypal-wordmark-color.svg',
  PAYPAL_MARK:
    'https://www.paypalobjects.com/paypal-ui/logos/svg/paypal-mark-color.svg',
  VISA_PLUS_LOGO:
    'https://www.paypalobjects.com/p2p-visatag/visa-plus-logo-large.png',
  VENMO_MARK:
    'https://www.paypalobjects.com/paypal-ui/logos/svg/venmo-mark-color.svg',
}

export const ERROR_URLS = {
  CRITICAL_ERROR:
    'https://www.paypalobjects.com/paypal-ui/illustrations/focused/svg/empty-critical.svg',
}

export const SELLER_COVER_PHOTO_URLS = {
  GREEN: {
    RASTER:
      'https://www.paypalobjects.com/p2p_seller_profiles/background_covers/green.jpg',
    VECTOR:
      'https://www.paypalobjects.com/p2p_seller_profiles/background_covers/green.svg',
  },
  BLUE: {
    RASTER:
      'https://www.paypalobjects.com/p2p_seller_profiles/background_covers/blue.jpg',
    VECTOR:
      'https://www.paypalobjects.com/p2p_seller_profiles/background_covers/blue.svg',
  },
  PURPLE: {
    RASTER:
      'https://www.paypalobjects.com/p2p_seller_profiles/background_covers/purple.jpg',
    VECTOR:
      'https://www.paypalobjects.com/p2p_seller_profiles/background_covers/purple.svg',
  },
  PINK: {
    RASTER:
      'https://www.paypalobjects.com/p2p_seller_profiles/background_covers/pink.jpg',
    VECTOR:
      'https://www.paypalobjects.com/p2p_seller_profiles/background_covers/pink.svg',
  },
}

export const SELLER_SETTING_TYPES = {
  SHOW: 'show',
  HIDE: 'hide',
}

export const SELLER_FLOW = {
  create: 'create',
  edit: 'settings',
}

export const SELLER_SOCIAL = {
  facebook: {
    MAX_CHAR_LIMIT: 310,
  },
}

export const PROFILE_TYPE = {
  seller: 'seller',
  personal: 'personal',
}

// Log this when entry src is not receieved for Personal Profile.
export const ENTRY_POINT_UNKNOWN = 'EntryPointUnknown'

export const SELLER_REGEX = {
  instagram: /^[A-Za-z0-9_.]{1,30}$/,
  facebook:
    // eslint-disable-next-line
    /^(?:https?:\/\/)?(?:(?:m|www)\.)?(?:facebook|fb)\.com\/([^\/?#]+)/i,
  twitter: /^[A-Za-z0-9_]{4,15}$/,
}

export const SHOP_CART_IMAGES = {
  EMPTY_CART:
    'https://www.paypalobjects.com/paypal-ui/illustrations/scenic/svg/empty-desert.svg',
}

export const EMPTY_CARD_ACTIVITY_IMAGE = {
  EMPTY_CARD_ACTIVITY:
    'https://www.paypalobjects.com/paypal-ui/illustrations/focused/svg/empty-card-activity.svg',
}

export const SHOP_CART_ERROR_TYPES = {
  quantity: 'quantity',
  soldOut: 'soldOut',
}

export const buttonsInitialData = {
  instagram: {
    ariaLabel: 'instagramRadio',
    ariaLabelDone: 'instagramRadioDone',
    label: 'instagramInputLabel',
    validation: SELLER_REGEX.instagram,
    error: 'userNameInputInputError',
    showError: false,
    visibleInput: false,
    doneState: false,
    textValue: '',
  },
  facebook: {
    ariaLabel: 'facebookRadio',
    ariaLabelDone: 'facebookRadioDone',
    label: 'facebookInputLabel',
    validation: SELLER_REGEX.facebook,
    helperText: 'fbInputInputHelperText',
    error: 'fbInputInputError',
    showError: false,
    visibleInput: false,
    doneState: false,
    textValue: '',
  },
  twitter: {
    ariaLabel: 'twitterRadio',
    ariaLabelDone: 'twitterRadioDone',
    label: 'twitterInputLabel',
    validation: SELLER_REGEX.twitter,
    error: 'userNameInputInputError',
    showError: false,
    visibleInput: false,
    doneState: false,
    textValue: '',
  },
}

export const PAYPAL_URL = 'https://www.paypal.com'

export const APP_CHOICES = {
  VENMO: 'VENMO',
  OTHERS: 'OTHERS',
}

export const NEXT_STEP_OPTIONS = {
  CREATE_USERNAME: 'username',
  COMPLETE_PROFILE: 'profile',
  ADD_PHONE: 'mobile',
  ADD_EMAIL: 'email',
}
