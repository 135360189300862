import React, { useEffect, useState } from 'react'
import { IconButton, v2blue100, V2CameraIcon } from '@paypalcorp/pp-react'
import glamorous from 'glamorous'
import { css } from 'glamor'
import { SELLER_FLOW } from 'utils/constants'
import Events from '../../../lib/analytics/event-tags'
import { tracking } from '../../../lib/analytics'
import { phone6PlusPortraitMaxAndSmaller } from '../../../lib/styles/media-queries'
import { getBadPhotoError } from '../../seller/error/ui-error-states'

// content
import getContent from 'pp-react-l10n'
import { showServiceError } from '../error/util'
const l10n = getContent('pages/seller/photos')

const ProfilePhoto = glamorous.div({
  position: 'absolute',
  bottom: '-60px',
  border: '4px solid white',
  backgroundColor: v2blue100,
  borderRadius: '125px',
  height: '125px',
  width: '125px',
  left: '0px',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  [phone6PlusPortraitMaxAndSmaller]: {
    borderRadius: '92px',
    height: '92px',
    width: '92px',
    bottom: '-46px',
    left: '16px',
  },
})

const ProfilePhotoEdit = glamorous.div({
  position: 'absolute',
  bottom: '-4px',
  backgroundColor: '#222324',
  borderBottomLeftRadius: '125px',
  borderBottomRightRadius: '125px',
  height: '62.5px',
  width: '125px',
  opacity: '80%',
  clipPath: 'inset(28% 0% 0% 0%)',
  border: '4px solid white',
  right: '-4px',
  [phone6PlusPortraitMaxAndSmaller]: {
    borderBottomLeftRadius: '92px',
    borderBottomRightRadius: '92px',
    height: '46px',
    width: '92px',
  },
})

const ProfilePhotoCamera = css({
  position: 'absolute',
  left: '34px',
  top: '7px',
  [phone6PlusPortraitMaxAndSmaller]: {
    left: '18px',
    top: '-4px',
  },
})

const ProfileCircle = props => {
  const {
    isEditable,
    sellerProfileState: {
      state: {
        photoSettings: {
          cachedDataProfilePhoto = '',
          hasSetDefaultProfilePicture = false,
        } = {},
        sellerProfileDetails: {
          user_info: { profile_photo_url: profilePhotoUrl = '' } = {},
        } = {},
      } = {},
    } = {},
    location: { state: { flow = SELLER_FLOW.create } = {} } = {},
    handleDialogView,
  } = props

  const hasCachedPhoto = !!cachedDataProfilePhoto
  const inEditFlow = flow === SELLER_FLOW.edit

  const [image, setImage] = useState(
    'https://www.paypalobjects.com/p2p_seller_profiles/storeImage.jpg',
  )

  const SELLER_PHOTO_PROFILE_PATH = '/seller/photos/profile'

  useEffect(() => {
    if (inEditFlow) {
      setImage(profilePhotoUrl)
    } else if (hasCachedPhoto) {
      setImage(cachedDataProfilePhoto)
    }
  })

  useEffect(() => {
    if (hasSetDefaultProfilePicture) {
      setImage(
        'https://www.paypalobjects.com/p2p_seller_profiles/storeImage.jpg',
      )
    }
  }, [hasSetDefaultProfilePicture])

  const backgroundImage = css({
    backgroundImage: `url('${image}')`,
    backgroundSize: '100%',
  })

  const hiddenFileInput = React.useRef(null)

  const handleClick = () => {
    if (inEditFlow) {
      props.history.push({
        pathname: SELLER_PHOTO_PROFILE_PATH,
        state: {
          flow: SELLER_FLOW.edit,
        },
      })
    } else {
      hiddenFileInput.current.click()
    }
  }

  const advanceToProfilePan = () => {
    props.sellerProfileState.loadCachedProfilePhoto()
    props.history.push({
      pathname: SELLER_PHOTO_PROFILE_PATH,
      state: {
        flow,
      },
    })
  }

  const onChangeProfilePhoto = event => {
    // load the selected photo instead of what's cached

    props.sellerProfileState.scanProfilePhoto(
      event,
      () => {
        props.history.push({
          pathname: SELLER_PHOTO_PROFILE_PATH,
          state: {
            flow,
          },
        })
      },
      decisionMsg => {
        props.history.push(getBadPhotoError(decisionMsg))
      },
      e => {
        showServiceError(e, props.history)
      },
    )
  }

  return (
    <ProfilePhoto className={backgroundImage}>
      {isEditable && (
        <ProfilePhotoEdit>
          <IconButton
            inverse
            name="profile-cover"
            className={`${ProfilePhotoCamera}`}
            svgIconComponent={V2CameraIcon}
            buttonType="tertiary"
            theme="v2"
            onClick={() => {
              tracking(
                Events.public_identity_profile_photo_edit_profile_pressed({}),
              )
              if (hasCachedPhoto) {
                if (inEditFlow) {
                  advanceToProfilePan()
                } else {
                  handleDialogView(true)
                }
              } else {
                handleClick()
              }
            }}
          >
            {l10n('a11y.profile.camera')}
          </IconButton>
          {!hasCachedPhoto && (
            <input
              type="file"
              accept="image/*"
              onChange={onChangeProfilePhoto}
              ref={hiddenFileInput}
              style={{ display: 'none' }}
            />
          )}
        </ProfilePhotoEdit>
      )}
    </ProfilePhoto>
  )
}

export default ProfileCircle
