import React from 'react'
import PropTypes from 'prop-types'
import clientData from '../../../utils/client-data'
import glamorous from 'glamorous'
import { css } from 'glamor'
import {
  HeadingText,
  BodyText,
  Divider,
  V2ViewIcon,
  V2EditIcon,
  grey600,
  grey700,
  v2green500,
  v2grey600,
  Link,
  v2blue400,
  Button,
} from '@paypalcorp/pp-react'
import { get } from 'lodash'
import getContent from 'pp-react-l10n'
import SocialShare from '../social/share'
import EditBackground from '../shared/edit-background'
import Events from 'lib/analytics/event-tags'
import { tracking } from 'lib/analytics'
import {
  phoneLandscapeMaxAndSmaller,
  phoneLandscapeMinAndSmaller,
} from 'lib/styles/media-queries'
import { EMPTY_CARD_ACTIVITY_IMAGE } from '../../../utils/constants'

import { PAYPAL_SANS_BIG_REGULAR_FONT } from '../constants/constants.js'
const l10n = getContent('pages/seller/dashboard')

const Container = glamorous.div({
  margin: '-30px 0 0 0',
})

const Heading = css({
  textAlign: 'center',
  position: 'absolute',
  width: '60%',
  left: '50%',
  marginLeft: '-30%',
  top: -85,
  lineHeight: '2.125rem',
  fontSize: '1.25rem',
  [phoneLandscapeMaxAndSmaller]: {
    lineHeight: '2rem',
  },
})

const ContentBlock = glamorous.div({
  marginTop: 320,
  fontFamily: PAYPAL_SANS_BIG_REGULAR_FONT,
  '& p': {
    fontFamily: PAYPAL_SANS_BIG_REGULAR_FONT,
  },
  '& .edit': {
    margin: '30px 0 20px 0',
  },
  '& .preview': {
    margin: '10px 0 20px 0',
  },
  '& .edit a, & .preview a': {
    fontFamily: PAYPAL_SANS_BIG_REGULAR_FONT,
    fontSize: '1rem',
    fontWeight: 'bold',
    '& span': {
      color: grey700,
    },
  },
  [phoneLandscapeMaxAndSmaller]: {
    marginTop: 300,
  },
  [phoneLandscapeMinAndSmaller]: {
    marginTop: 230,
  },
})

const SellerName = glamorous.h2({
  fontSize: '1.5rem',
  fontWeight: 'normal',
  lineHeight: '2rem',
  marginBottom: 5,
  overflowWrap: 'break-word',
})

const ShareLinkTxt = glamorous.p({
  fontFamily: PAYPAL_SANS_BIG_REGULAR_FONT,
  color: grey600,
})

const HandleLink = glamorous.a({
  color: v2blue400,
  fontFamily: PAYPAL_SANS_BIG_REGULAR_FONT,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontWeight: 'bold',
})

const ActivityWidgetWrapper = glamorous.div({
  '& #js_widgetView': {
    fontFamily:
      'PayPalSansBig-Regular,"Helvetica Neue",Arial,sans-serif !important',
  },
  // activity widget styles override
  '& #js_widgetView .transactionRow': {
    paddingLeft: 0,
  },
  '& #js_widgetView .newActivityModule .moduleHeader': {
    padding: 0,
  },
  '& #js_widgetView .newActivityModule .activityBubble': {
    paddingLeft: 0,
  },
  '& #js_widgetView .transactionItem .transactionAmount': {
    color: v2green500,
  },
})

const ActivityWidget = glamorous.div({
  marginTop: '1.25rem',
  '& .recent-activity-header-link': {
    fontFamily: PAYPAL_SANS_BIG_REGULAR_FONT,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: v2grey600,
    fontWeight: 'normal',
    paddingLeft: '0.5rem',
  },
  '& .recent-activity-header-link:after': {
    paddingRight: '0.5rem',
  },
})

const AppSection = glamorous.div({
  textAlign: 'center',
  margin: '50px auto',
})

const AppSectionText = glamorous.div({
  margin: '20px auto 40px auto',
})

Dashboard.defaultProps = {
  buttonsData: {
    copyLink: `https://paypal.me/${get(
      clientData,
      'sellerProfileDetails.id',
    )}?country.x=${get(clientData, 'locality.country')}&locale.x=${get(
      clientData,
      'locality.locale',
    )}`,
    facebookLink: 'https://facebook.com',
    messengerLink: 'https://messenger.com',
    twitterLink: 'https://twitter.com',
  },
}

Dashboard.propTypes = {
  buttonsData: PropTypes.object.isRequired,
}

function Dashboard(props) {
  const {
    sellerProfileState: {
      state: {
        sellerProfileDetails: {
          id: businessHandle = '',
          user_info: {
            alternate_full_name: businessName = '',
            profile_photo_url: profilePhotoUrl = '',
          } = {},
          personalization_settings: {
            cover_photo_url: coverPhotoUrl = '',
            cover_photo_vertical_pan: coverPhotoVerticalPan = '',
          } = {},
        } = {},
      } = {},
    } = {},
  } = props
  const { isMobile, isWidgetSPFEnabled = false } = clientData

  const widgetDivRef = React.useRef()

  const shouldShowAppDownload = get(
    clientData,
    'shouldShowAppDownloadLinkTreatment',
    false,
  )

  const advanceToPreview = e => {
    e.preventDefault()
    tracking(Events.public_identity_my_profile_preview_pressed())
    props.history.push(`/seller/${businessHandle}/dashboard/preview`)
  }

  const advanceToEdit = e => {
    e.preventDefault()
    tracking(Events.public_identity_my_profile_edit_pressed())
    props.history.push(`/seller/${businessHandle}/settings`)
  }

  const trackActivityClick = () => {
    tracking(Events.public_identity_my_profile_activity_pressed())
  }

  React.useEffect(() => {
    tracking(Events.public_identity_my_profile_screen_shown())
    if (clientData.activityWidgetJs && document.body) {
      const widgetScript = document.createElement('script')
      widgetScript.src = clientData.activityWidgetJs
      document.body.append(widgetScript)
    }

    // Set widget eventListener on activity widget
    const activityElement = document.getElementById('widget-analytics-data')
    if (activityElement) {
      tracking(Events.public_identity_edit_profile_activity_loading_occurred())
      activityElement.addEventListener('click', trackActivityClick)
    }

    return () => {
      // Remove widget eventListener on dismount
      if (activityElement) {
        activityElement.removeEventListener('click', trackActivityClick)
      }
    }
  }, [])

  React.useEffect(() => {
    if (
      isWidgetSPFEnabled &&
      widgetDivRef &&
      widgetDivRef.current &&
      document.createRange
    ) {
      // eslint-disable-next-line no-unsanitized/method
      const fragment = document
        .createRange()
        .createContextualFragment(clientData.activityWidgetHtml)
      // eslint-disable-next-line no-unused-expressions
      widgetDivRef.current.append(fragment)
    }
  }, [])

  const handleGetAppClick = () => {
    tracking(Events.public_identity_my_profile_app_download_pressed())
    window.location.href =
      'https://www.paypal.com/cgp/app-redirect?intent=seller_profile&src=dashboard&cgp_web_redir=download'
  }

  return (
    <Container data-test-id="seller-dashboard-screen">
      {isMobile ? (
        <BodyText className={`${Heading}`} strong role="heading" aria-level="1">
          {l10n('title')}
        </BodyText>
      ) : (
        <HeadingText
          size="sm"
          className={`${Heading}`}
          role="heading"
          aria-level="1"
        >
          {l10n('title')}
        </HeadingText>
      )}
      <EditBackground
        {...{ profilePhotoUrl, coverPhotoUrl, coverPhotoVerticalPan, ...props }}
        data-test-id="seller-settings-photos"
        allowEdit={false}
      />
      <ContentBlock>
        <SellerName role="heading" aria-level="2">
          {businessName}
        </SellerName>
        <HandleLink href="preview" onClick={advanceToPreview}>
          {`@${businessHandle}`}
        </HandleLink>
        <div className="edit">
          <Link
            className="edit"
            href="settings"
            onClick={advanceToEdit}
            leftIcon={<V2EditIcon size="sm" />}
            theme="v2"
            data-test-id="seller-dashboard-edit-settings"
          >
            {l10n('edit')}
          </Link>
        </div>
        <div className="preview">
          <Link
            href="preview"
            onClick={advanceToPreview}
            leftIcon={<V2ViewIcon size="sm" />}
            theme="v2"
            data-test-id="seller-dashboard-preview-profile"
          >
            {l10n('preview')}
          </Link>
        </div>

        <Divider secondary />
        <ShareLinkTxt id="shareLinkTitle">{l10n('shareLink')}</ShareLinkTxt>
        <SocialShare
          copyLink={props.buttonsData.copyLink}
          sellerName={businessName}
          eventFunction={Events.public_identity_my_profile_share_pressed}
        />
        <Divider secondary />
        {shouldShowAppDownload && (
          <AppSection>
            <img
              style={{ width: '100px' }}
              src={EMPTY_CARD_ACTIVITY_IMAGE.EMPTY_CARD_ACTIVITY}
              alt="empty card"
            />
            <AppSectionText>
              <HeadingText style={{ fontSize: '1.75rem' }}>
                {l10n('appSectionHeaderText')}
              </HeadingText>
              <BodyText>{l10n('appSectionBodyText')}</BodyText>
            </AppSectionText>
            <Button onClick={handleGetAppClick}>
              {l10n('appSectionButton')}
            </Button>
          </AppSection>
        )}
        <Divider secondary />
        <ActivityWidgetWrapper>
          {isWidgetSPFEnabled ? (
            <ActivityWidget
              data-test-id="activityWidget"
              innerRef={widgetDivRef}
            />
          ) : (
            <ActivityWidget
              data-test-id="activityWidget"
              dangerouslySetInnerHTML={{
                __html: clientData.activityWidgetHtml,
              }}
            />
          )}
        </ActivityWidgetWrapper>
      </ContentBlock>
    </Container>
  )
}
export default Dashboard
