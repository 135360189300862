// @flow

import { GraphQLClient } from 'graphql-request'
import clientData from './client-data'

type Client = {|
  request: (query: string, variables?: {}) => Promise<*>,
|}

const client: Client = new GraphQLClient(
  `${window.location.origin}${clientData.requestURI}/v1/graphql`,
  {
    credentials: 'include',
    mode: 'cors',
    headers: {
      'x-csrf-token': clientData.csrf,
    },
  },
)

export { client }

export type { Client }
