export const deactivateProfile = `
 mutation DeactivateProfile ($slugName: String!) {
  deactivateProfile (slug: $slugName) {
    isProfileStatusActive
    privacySettings {
      emailSearchEnabled
      phoneSearchEnabled
    }
  }
}`
