import glamorous from 'glamorous'
import React, { forwardRef } from 'react'
import { BodyText, Button } from '@paypalcorp/pp-react'

import getContent from 'pp-react-l10n'
const l10n = getContent('pages/seller/details')

const SuggestionsList = glamorous.ul({
  listStyleType: 'none',
  margin: 0,
})

const SuggestionRow = glamorous.li({
  textAlign: 'left',
  marginTop: '1.5rem',
})

const SuggestionStyle = glamorous.button({
  padding: '0',
})

const Availability = forwardRef((props, ref) => {
  const { onSelect, suggestions } = props
  const suggestionListItems = suggestions.map(suggestion => {
    return (
      <SuggestionRow key={`id-${suggestion}`}>
        <Button
          tertiary
          as={SuggestionStyle}
          name="businessHandle"
          value={suggestion}
          onClick={onSelect}
        >
          {suggestion}
        </Button>
      </SuggestionRow>
    )
  })

  return (
    <>
      <div ref={ref}>
        <BodyText>{l10n('main.availability.heading')}</BodyText>
        <SuggestionsList>{suggestionListItems}</SuggestionsList>
      </div>
    </>
  )
})

export default Availability
