import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'unstated'
import { BrowserRouter as Router } from 'react-router-dom'

import App from './app'
import clientData from './utils/client-data'

render(
  <Provider>
    <Router basename={clientData.requestURI}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root'),
)
