import React from 'react'
import PropTypes from 'prop-types'
import glamorous from 'glamorous'
import { css } from 'glamor'
import getContent from 'pp-react-l10n'
import {
  Sheet,
  HeadingText,
  BodyText,
  v2grey600,
  white,
} from '@paypalcorp/pp-react'
import SocialShare from '../../seller/social/share'
import { tracking } from 'lib/analytics'
import Events from 'lib/analytics/event-tags'
const l10n = getContent('pages/seller/success')

const Container = css({
  minHeight: '21rem',
  // pp-react Sheet component needs a custom height for the design, otherwise it takes the full screen
  height: '21rem',
  background: white,
  borderRadius: 12,
  top: '50%',
  left: '50%',
  marginBottom: '15rem',
  transform: 'translate(-50%, -50%)',
  '@media(max-width: 752px)': {
    transform: 'translateX(-50%)',
    top: 'auto',
    bottom: 0,
    marginBottom: 0,
    borderRadius: '12px 12px 0 0',
  },
})

const Heading = css({
  '@media(max-width: 752px)': {
    display: 'none',
  },
})

const MobileHeading = css({
  display: 'none',
  '@media(max-width: 752px)': {
    display: 'block',
  },
})

const SubContainer = glamorous.div({
  '@media(max-width: 752px)': {
    width: '70%',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  '@media(max-width: 425px)': {
    width: '90%',
  },
})

const SubHeading = css({
  marginTop: 16,
  width: '100%',
  color: v2grey600,
  textAlign: 'center',
  '@media(max-width: 752px)': {
    padding: '10px 20px 0',
  },
})

const Links = glamorous.div({
  marginTop: 30,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
})

ShareProfilePopup.propTypes = {
  header: PropTypes.string.isRequired,
  mobileHeader: PropTypes.string,
  subheader: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  handleDisplay: PropTypes.func,
  copyLink: PropTypes.string.isRequired,
  sellerName: PropTypes.string.isRequired,
}

ShareProfilePopup.defaultProps = {
  header: '',
  mobileHeader: '',
  subheader: '',
  isOpen: false,
  handleDisplay: () => {},
  copyLink: '',
  sellerName: '',
}

function ShareProfilePopup(props) {
  function onPopupClose() {
    tracking(Events.public_identity_profile_share_close_pressed())
    props.handleDisplay(false)
  }
  return (
    <Sheet
      isOpen={props.isOpen}
      onClose={onPopupClose}
      onOpen={() =>
        tracking(Events.public_identity_profile_share_screen_shown())
      }
      title={l10n('shareYourProfile')}
      containerClassName={`${Container}`}
      center={true}
      hideTitle={true}
    >
      <HeadingText
        size="sm"
        role="heading"
        aria-level="1"
        className={`${Heading}`}
      >
        {props.header}
      </HeadingText>
      <HeadingText
        size="md"
        role="heading"
        aria-level="1"
        className={`${MobileHeading}`}
      >
        {/* Design requires different title content for web vs mobile web. The mobileHeader is NOT a required prop and it'll default to header in that case. */}
        {props.mobileHeader || props.header}
      </HeadingText>
      <SubContainer>
        <BodyText className={`${SubHeading}`}>{props.subheader}</BodyText>
        <Links>
          <SocialShare
            copyLink={props.copyLink}
            sellerName={props.sellerName}
            displayInPopup
          />
        </Links>
      </SubContainer>
    </Sheet>
  )
}

export default ShareProfilePopup
