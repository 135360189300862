// @flow

// Skeleton for upload component found in docs of the react-avatar-editor package.
// https://github.com/mosch/react-avatar-editor/blob/master/docs/App.jsx
import React from 'react'
import AvatarEditor from 'react-avatar-editor'
import glamorous from 'glamorous'
import getContent from 'pp-react-l10n'
import { svgImages } from 'components/index'
import SVG from 'components/svg-logo'
import Events from 'lib/analytics/event-tags'
import { tracking } from 'lib/analytics'
import { CaptionText, Link, Button, grey600 } from '@paypalcorp/pp-react'

import { SELLER_FLOW } from 'utils/constants'
import { showServiceError } from '../error/util'
import {
  getBadPhotoError,
  getUploadPhotoError,
} from '../../seller/error/ui-error-states'

const l10n = getContent('pages/seller/photos')

const FirstRow = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
})
const SecondRow = glamorous.div({ marginTop: '0.75rem' })

const DragToPosition = glamorous.div({
  color: grey600,
  display: 'flex',
  alignItems: 'center',
})

const ChangePhoto = glamorous.div({
  color: 'blue',
})
const ChangeInput = glamorous.input({
  width: 0.1,
  height: 0.1,
  opacity: 0,
  overflow: 'hidden',
  position: 'absolute',
  zIndex: -1,
  '&:focus + label': {
    outline: '1px auto rgb(0, 95, 204)',
  },
})

const SVGStyling = glamorous(SVG, {
  forwardProps: ['svgString'],
  rootEl: 'span',
})({
  paddingRight: 10,
})

const LinkText = glamorous.span({
  '&:hover, &:visited, &:active, &:focus': {
    textDecoration: 'none',
  },
})

const Slider = glamorous.input({
  marginTop: '1.25rem',
  width: '100%',
  cursor: 'grab',
  '&:active': {
    cursor: 'grabbing',
  },
})

const ButtonContainer = glamorous.div({
  marginTop: '3rem',
})

class ProfilePhotoPan extends React.Component<ProfilePhotoPanProps> {
  constructor(props) {
    super(props)
    tracking(Events.public_identity_profile_picture_edit_screen_shown({}))
  }

  state = {
    allowZoomOut: false,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0,
    borderRadius: 100,
    preview: null,
    width: 200,
    height: 200,
  }

  handleNewImage = e => {
    tracking(Events.public_identity_profile_picture_edit_change_pressed({}))

    this.props.sellerProfileState.processImage(
      {
        scan: true,
      },
      {
        isNewFile: true,
        fileEvent: e,
        mediaCategory: 'PROFILE_IMAGE',
        handleActions: {
          onScan: {
            onSuccess: () => {},
            onError: msg => {
              this.showBadPhotoError(msg)
            },
            onServiceError: e => {
              showServiceError(e)
            },
          },
        },
      },
    )
  }

  showBadPhotoError = decisionMsg => {
    this.props.history.push(getBadPhotoError(decisionMsg))
  }

  showPhotoUploadError = (isMediaservError = true) => {
    this.props.history.push(getUploadPhotoError(isMediaservError))
  }

  setCachedPhoto = imageUrl => {
    const {
      location: { state: { flow = SELLER_FLOW.create } = {} } = {},
    } = this.props

    this.props.sellerProfileState.updateProfilePhoto(
      imageUrl,
      () => {
        this.props.sellerProfileState.changeSetDefaultProfilePictureState()
        this.props.history.push({
          pathname: '/seller/photos',
          state: {
            flow,
          },
        })
      },
      () => {
        this.showPhotoUploadError()
      },
    )
  }

  updateProfilePhoto = imageUrl => {
    const {
      location: { state: { flow = SELLER_FLOW.create } = {} } = {},
    } = this.props

    this.props.sellerProfileState.updateProfilePhoto(
      imageUrl,
      async () => {
        const {
          sellerProfileState: {
            state: {
              photoSettings: { proposedProfilePhotoUrl = '' } = {},
              sellerProfileDetails: { id: businessHandle = '' } = {},
            } = {},
            editSellerSettings,
          } = {},
        } = this.props

        const valueObj = [
          {
            op: 'replace',
            path: '/user_info/profile_photo_url',
            value: proposedProfilePhotoUrl,
          },
        ]

        try {
          await editSellerSettings(valueObj, businessHandle)
          this.props.history.push({
            pathname: `/seller/${businessHandle}/settings`,
            state: {
              flow,
            },
          })
        } catch (err) {
          // Error is due to failure in PATCH call for edit and NOT due to mediaserv
          // Both errors lead to the same UI but distinction needed for instrumentation
          this.showPhotoUploadError(false)
        }
      },
      () => {
        // Error is due to failure in mediaserv
        this.showPhotoUploadError()
      },
    )
  }

  handleSave = flow => {
    tracking(Events.public_identity_profile_picture_edit_save_pressed({}))
    // toDataURL accepts a DOMString, without one, it will default the image to a png
    const img = this.editor.getImageScaledToCanvas().toDataURL('image/jpeg')
    if (flow === SELLER_FLOW.create) {
      return this.setCachedPhoto(img)
    } else {
      return this.updateProfilePhoto(img)
    }
  }

  handleScale = e => {
    const scale = parseFloat(e.target.value)
    this.setState({ scale })
  }

  setEditorRef = editor => {
    if (editor) {
      this.editor = editor
    }
  }

  handlePositionChange = position => {
    this.setState({ position })
  }

  componentDidMount() {
    // fix the weird css in IOS where the photo does not respect it's width

    // set the max width to 100% then check to see what it has actually become,
    // e.g. if max-width is 208 due to the 100% of parent width, then the new square should be 208x208
    // then due to the square nature of the canvas, set the height to the same pixel length

    const canvas = this.wrapper
    canvas.style.maxWidth = '100%'

    canvas.style.borderRadius = '10px'

    setTimeout(() => {
      const newCanvas = this.wrapper
      const actualWidth = newCanvas.offsetWidth
      newCanvas.style.maxHeight = `${actualWidth}px`
    }, 0)
  }

  render() {
    const {
      sellerProfileState: {
        state: {
          photoSettings: {
            proposedProfilePhoto = '',
            cachedProfilePhoto = '',
          } = {},
          sellerProfileDetails: {
            user_info: { profile_photo_url: profilePhotoUrl = '' } = {},
          } = {},
        } = {},
      } = {},
      location: { state: { flow = SELLER_FLOW.create } = {} } = {},
    } = this.props

    return (
      <div
        ref={c => (this.wrapper = c)}
        id="custom-ppme-avatar"
        style={{
          maxWidth: '100%',
          width: 250,
          margin: '0 auto',
          textAlign: 'center',
        }}
      >
        <AvatarEditor
          color={[203, 210, 214, 0.7]} // color={[255, 255, 255, 0.4]}
          ref={this.setEditorRef}
          scale={parseFloat(this.state.scale)}
          width={this.state.width}
          height={this.state.height}
          position={this.state.position}
          onPositionChange={this.handlePositionChange}
          rotate={parseFloat(this.state.rotate)}
          borderRadius={this.state.borderRadius}
          onSave={this.handleSave}
          image={proposedProfilePhoto || cachedProfilePhoto || profilePhotoUrl}
          style={{ cursor: 'all-scroll', marginBottom: 13 }}
          crossOrigin="anonymous"
        />
        <br />

        <FirstRow>
          <DragToPosition>
            <SVGStyling svgString={`${svgImages.dragIcon}`} />
            <CaptionText>{l10n('profile.drag')}</CaptionText>
          </DragToPosition>
        </FirstRow>
        <SecondRow>
          <ChangePhoto>
            <ChangeInput
              onChange={this.handleNewImage}
              type="file"
              name="change"
              id="file"
              className="inputfile"
              data-test-id="change-profile-photo"
            />
            <label htmlFor="file">
              <Link as={LinkText}>{l10n('profile.change')}</Link>
            </label>
          </ChangePhoto>
        </SecondRow>

        <Slider
          name="scale"
          type="range"
          onChange={this.handleScale}
          min={this.state.allowZoomOut ? '0.1' : '1'}
          max="2"
          step="0.01"
          defaultValue="1"
          aria-valuemin={this.state.allowZoomOut ? '0.1' : '1'}
          aria-valuemax="2"
          aria-valuenow={this.state.scale}
          aria-valuetext={`${Math.round(this.state.scale * 100 * 100) / 100}%`}
          aria-label={l10n('profile.zoom')}
        />
        <br />
        <ButtonContainer>
          <Button
            size="lg"
            name="save"
            onClick={() => this.handleSave(flow)}
            data-test-id="save-profile-photo"
          >
            {l10n('profile.save')}
          </Button>
        </ButtonContainer>
        <br />
        {!!this.state.preview && (
          <img
            alt=""
            src={this.state.preview.img}
            style={{
              borderRadius: `${
                (Math.min(this.state.preview.height, this.state.preview.width) +
                  10) *
                (this.state.preview.borderRadius / 2 / 100)
              }px`,
            }}
          />
        )}
        {!!this.state.preview && (
          <ImageWithRect
            width={
              this.state.preview.scale < 1
                ? this.state.preview.width
                : (this.state.preview.height * 478) / 270
            }
            height={this.state.preview.height}
            image="avatar.jpg"
            rect={this.state.preview.rect}
            style={{
              margin: '10px 24px 32px',
              padding: 5,
              border: '1px solid #CCC',
            }}
          />
        )}
      </div>
    )
  }
}

// Used to display the cropping rect
class ImageWithRect extends React.Component<ImageWithRectProps> {
  componentDidMount() {
    this.redraw()
  }

  componentDidUpdate() {
    this.redraw()
  }

  setCanvas = canvas => {
    if (canvas) {
      this.canvas = canvas
    }
  }

  handleImageLoad = () => {
    const ctx = this.canvas.getContext('2d')
    const { rect, width, height } = this.props

    ctx.clearRect(0, 0, width, height)

    ctx.strokeStyle = 'red'

    if (rect && (rect.width > 1 || rect.height > 1)) {
      ctx.drawImage(
        this.imgElement,
        Math.round(-rect.x * (width / rect.width)),
        Math.round(-rect.y * (height / rect.height)),
        Math.round(width / rect.width),
        Math.round(height / rect.height),
      )

      if (rect) {
        ctx.strokeRect(1, 1, Math.round(width) - 2, Math.round(height) - 2)
      }
    } else {
      ctx.drawImage(this.imgElement, 0, 0, width, height)

      if (rect) {
        ctx.strokeRect(
          Math.round(rect.x * width) + 0.5,
          Math.round(rect.y * height) + 0.5,
          Math.round(rect.width * width),
          Math.round(rect.height * height),
        )
      }
    }
  }

  redraw() {
    const img = new Image()

    img.src = this.props.image
    img.onload = this.handleImageLoad
    this.imgElement = img
  }

  render() {
    return (
      <canvas
        ref={this.setCanvas}
        style={{ cursor: 'all-scroll', marginBottom: 13 }}
        width={this.props.width}
        height={this.props.height}
      />
    )
  }
}

export default ProfilePhotoPan
