import React, { useState } from 'react'
import glamorous from 'glamorous'
import { v2blue400 } from '@paypalcorp/pp-react'

import HandleDetailsDialog from './handle-details-dialog'

import Events from '../../../lib/analytics/event-tags'
import { tracking } from '../../../lib/analytics'
import { phone6PlusPortraitMaxAndSmaller } from '../../../lib/styles/media-queries'

// content
import getContent from 'pp-react-l10n'
const l10n = getContent('pages/seller/details')

const LinkButton = glamorous.button('vx_btn-link', {
  color: v2blue400,
  fontFamily: 'PayPalSansBig-Regular, "Helvetica Neue", Arial, sans-serif',
  fontSize: '0.875rem',
  [phone6PlusPortraitMaxAndSmaller]: {
    textAlign: 'left',
  },
})

const HandleMoreInfoLink = () => {
  const [showDialog, setShowDialog] = useState(false)

  return (
    <>
      <LinkButton
        type="button"
        onClick={() => {
          tracking(Events.public_identity_profile_create_terms_pressed({}))
          setShowDialog(!showDialog)
        }}
      >
        {l10n('main.learnMore')}
      </LinkButton>
      <HandleDetailsDialog
        showDialog={showDialog}
        // closedBy is a paramter to be passed to extract the method in which
        // the dialog is closed
        onClose={(e, closedBy) => {
          if (closedBy === 'closeButton') {
            tracking(Events.public_identity_profile_terms_close_pressed({}))
          } else {
            tracking(Events.public_identity_profile_terms_dismiss_pressed({}))
          }
          setShowDialog(!showDialog)
        }}
      />
    </>
  )
}

export default HandleMoreInfoLink
