export const NEW_ARRIVALS_PREVIEW_TYPE = 'newArrivalProducts'
export const PAYPAL_SANS_BIG_REGULAR_FONT =
  'PayPalSansBig-Regular,"Helvetica Neue",Arial,sans-serif'
const BASE_SAMPLE_URL =
  'https://uideploy--staticcontent--4d0fbe1fedb5c--ghe.preview.dev.paypalinc.com/ppme_shops/samples/'
const EARRING_1 = 'earring-1.png'
const EARRING_2 = 'earring-2.png'
const PRODUCT_NAME_1 = 'Double Name Ring, Two Name Ring for your love'
const PRODUCT_NAME_2 = 'Custom Name Necklace Figaro'
export const HARD_CODED_PRODUCTS_PREVIEW = [
  {
    id: '1',
    name: PRODUCT_NAME_1,
    photoUrl: `${BASE_SAMPLE_URL}${EARRING_1}`,
    price: {
      amount: '23.62',
      currencyCode: 'USD',
    },
  },
  {
    id: '2',
    name: 'Custom Name Necklace Figaro for your loved one',
    photoUrl: `${BASE_SAMPLE_URL}${EARRING_2}`,
    price: {
      amount: '39.95',
      currencyCode: 'USD',
    },
  },
  {
    id: '3',
    name: PRODUCT_NAME_1,
    photoUrl: `${BASE_SAMPLE_URL}${EARRING_1}`,
    price: {
      amount: '23.62',
      currencyCode: 'USD',
    },
  },
  {
    id: '4',
    name: PRODUCT_NAME_2,
    photoUrl: `${BASE_SAMPLE_URL}${EARRING_2}`,
    price: {
      amount: '39.95',
      currencyCode: 'USD',
    },
  },
  {
    id: '5',
    name: PRODUCT_NAME_1,
    photoUrl: `${BASE_SAMPLE_URL}${EARRING_1}`,
    price: {
      amount: '23.62',
      currencyCode: 'USD',
    },
  },
  {
    id: '6',
    name: PRODUCT_NAME_2,
    photoUrl: `${BASE_SAMPLE_URL}${EARRING_2}`,
    price: {
      amount: '39.95',
      currencyCode: 'USD',
    },
  },
  {
    id: '7',
    name: PRODUCT_NAME_2,
    photoUrl: `${BASE_SAMPLE_URL}${EARRING_1}`,
    price: {
      amount: '39.95',
      currencyCode: 'USD',
    },
  },
  {
    id: '8',
    name: PRODUCT_NAME_1,
    photoUrl: `${BASE_SAMPLE_URL}${EARRING_2}`,
    price: {
      amount: '23.62',
      currencyCode: 'USD',
    },
  },
  {
    id: '9',
    name: PRODUCT_NAME_2,
    photoUrl: `${BASE_SAMPLE_URL}${EARRING_1}`,
    price: {
      amount: '39.95',
      currencyCode: 'USD',
    },
  },
  {
    id: '10',
    name: PRODUCT_NAME_2,
    photoUrl: `${BASE_SAMPLE_URL}${EARRING_2}`,
    price: {
      amount: '39.95',
      currencyCode: 'USD',
    },
  },
  {
    id: '11',
    name: PRODUCT_NAME_2,
    photoUrl: `${BASE_SAMPLE_URL}${EARRING_1}`,
    price: {
      amount: '39.95',
      currencyCode: 'USD',
    },
  },
  {
    id: '12',
    name: PRODUCT_NAME_2,
    photoUrl: `${BASE_SAMPLE_URL}${EARRING_2}`,
    price: {
      amount: '39.95',
      currencyCode: 'USD',
    },
  },
  {
    id: '13',
    name: PRODUCT_NAME_2,
    photoUrl: `${BASE_SAMPLE_URL}${EARRING_1}`,
    price: {
      amount: '39.95',
      currencyCode: 'USD',
    },
  },
  {
    id: '14',
    name: PRODUCT_NAME_2,
    photoUrl: `${BASE_SAMPLE_URL}${EARRING_2}`,
    price: {
      amount: '39.95',
      currencyCode: 'USD',
    },
  },
  {
    id: '15',
    name: PRODUCT_NAME_2,
    photoUrl: `${BASE_SAMPLE_URL}${EARRING_1}`,
    price: {
      amount: '39.95',
      currencyCode: 'USD',
    },
  },
]
