// @flow

import 'vxpattern-lib-paypal/dist/vx-lib.js'
import { init as initL10n } from 'utils/l10n'
import { init as initClientData } from 'utils/client-data'
import { init as initGlamor } from 'utils/glamor-plugins'
import { init as initUserAttributes } from 'utils/user-attributes'
import setupAnalytics from 'utils/analytics'

initL10n()
initClientData()
initUserAttributes()
initGlamor()
setupAnalytics()
