import React, { useState, useRef, useEffect } from 'react'
import glamorous from 'glamorous'
import { css } from 'glamor'
import { HeadingText, BodyText, TextArea } from '@paypalcorp/pp-react'
import getContent from 'pp-react-l10n'
import Events from 'lib/analytics/event-tags'
import { tracking } from 'lib/analytics'
import { phone6PlusPortraitMaxAndSmaller } from 'lib/styles/media-queries'
import { showServiceError } from './../error/util'
import SaveButton from '../shared/save-button'

const l10n = getContent('pages/seller/settings')

const Container = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
})

const Heading = css({
  marginTop: 80,
  [phone6PlusPortraitMaxAndSmaller]: {
    marginTop: 20,
  },
})

const SubHeading = css({
  textAlign: 'left',
  margin: '16px 0 20px',
})

function BusinessDescriptionSetting(props) {
  const {
    sellerProfileState: {
      state: {
        sellerProfileDetails: {
          id: businessHandle = '',
          personalization_settings: { description = '' } = {},
        } = {},
      } = {},
    } = {},
  } = props

  useEffect(() => {
    tracking(Events.public_identity_edit_about_screen_shown({}))
  }, [])

  const [value, setValue] = useState(description)
  const [isErrorTextArea, setErrorTextArea] = useState(false)
  const { editSellerSettings } = props.sellerProfileState
  const { current: initialValue } = useRef(value)
  const isChanged = value !== initialValue
  const maxCharacters = 200
  const errorTextArea = isErrorTextArea && l10n('edit.description.error')

  const handleChangeInput = e => {
    setValue(e.target.value)
    setErrorTextArea(e.target.value.length > maxCharacters)
  }

  const handleSave = async () => {
    tracking(Events.public_identity_edit_about_save_pressed({}))

    const valueObj = [
      {
        op: 'replace',
        path: '/personalization_settings/description',
        value,
      },
    ]

    try {
      await editSellerSettings(valueObj, businessHandle)
      props.history.goBack()
    } catch (err) {
      showServiceError(
        err,
        props.history,
        'seller-profile-settings-edit-business-description',
      )
    }
  }

  return (
    <Container data-test-id="seller-settings-description-screen">
      <HeadingText
        size="md"
        role="heading"
        aria-level="1"
        className={`${Heading}`}
      >
        {l10n('description')}
      </HeadingText>
      <BodyText className={`${SubHeading}`}>
        {l10n('edit.description.subheader')}
      </BodyText>
      <TextArea
        name="description"
        label={l10n('description')}
        value={value}
        onChange={handleChangeInput}
        showCharacterCounter
        maxCharacters={maxCharacters}
        errorText={errorTextArea}
        autoComplete="off"
        data-test-id="seller-settings-description-input"
      />
      {isChanged && !isErrorTextArea && <SaveButton handleSave={handleSave} />}
    </Container>
  )
}

export default BusinessDescriptionSetting
