import { Container } from 'unstated'
import { get } from 'lodash'
import clientData from 'utils/client-data'
import { client } from 'utils/client'
import to from 'await-to-js'
import { editSellerProfileSettings } from 'gql/mutation/edit-seller-settings.js'
import { activateProfile } from 'gql/mutation/activate-profile'
import { deactivateProfile } from 'gql/mutation/deactivate-profile'
import { normalizeImage } from '../../utils/image-utils'
import { addLoader, removeLoader } from '../../utils/loader'
import { createSellerProfileMutation } from '../../gql/mutation/create-seller-profile'
import { screenPhoto } from '../../gql/mutation/screen-photo'
import { uploadScannedImageData } from '../../gql/mutation/upload-scanned-photo'

const imageSizeId = {
  coverPhoto: 48,
  profilePhoto: 139,
}

class SellerProfileState extends Container {
  state = {
    businessName: '',
    businessHandle: '',
    businessDescription: '',
    shopCatalog: get(clientData, 'shop.shopCatalogDetails', []),
    isValidBusinessHandle: false,
    isSuggestedBusinessHandle: false,
    sellerProfileCreated: false,
    sellerProfileDetails: get(clientData, 'sellerProfileDetails', {}),
    socialMedia: {
      instagramHandle: null,
      facebookUrl: null,
      twitterHandle: null,
      websiteUrl: null,
    },
    contactsOption: {
      phone: null,
      email: null,
      location: null,
      locationFormat: null,
    },
    photoSettings: {
      hasSeenDialog: false,
      // profile
      profilePhoto: get(
        clientData,
        'sellerProfileDetails.user_info.profile_photo_url',
        'https://www.paypalobjects.com/p2p_seller_profiles/storeImage.jpg',
      ),
      userHasProfilePicture: get(
        clientData,
        'sellerProfileDetails.user_info.profile_photo_url',
      )
        ? true
        : false,
      proposedProfilePhoto: undefined,
      cachedProfilePhoto: undefined,
      cachedDataProfilePhoto: undefined,
      hasSetDefaultProfilePicture: false,
      // cover
      initialCoverPhoto: get(
        clientData,
        'sellerProfileDetails.personalization_settings.cover_photo_url',
        'https://www.paypalobjects.com/p2p_seller_profiles/background_covers/blue.jpg',
      ),
      coverPhoto: get(
        clientData,
        'sellerProfileDetails.personalization_settings.cover_photo_url',
        undefined,
      ),
      coverPhotoPan: get(
        clientData,
        'sellerProfileDetails.personalization_settings.cover_photo_vertical_pan',
      ),
      // get(clientData, 'sellerProfileDetails.personalization_settings.cover_photo_url') !== 'https://www.paypalobjects.com/p2p_seller_profiles/background_covers/blue.jpg',
      hasCoverPhoto: undefined,
      cachedCoverPhoto: undefined,
      proposedCoverPhoto: undefined,
      isCoverDraggable: false,
      suggestedCoverPhotos: get(
        clientData,
        'defaultPhotos.suggestedCoverPhotos',
        [],
      ),
    },
  }

  createSellerProfile = async (onSuccess = () => {}, onError = () => {}) => {
    addLoader()

    // TODO: Edits needed for address and other parameters that maybe passed
    // during seller profile creation
    const variables = {
      businessHandle: this.state.businessHandle,
      businessDescription: this.state.businessDescription,
      businessName: this.state.businessName,
      photoData: {
        profilePhotoUrl: this.state.photoSettings.proposedProfilePhotoUrl,
        coverPhotoUrl: this.state.photoSettings.cachedCoverPhoto,
        coverPhotoVerticalPan: this.state.photoSettings.coverPhotoPan,
      },
      socialData: {
        facebookUrl: this.state.socialMedia.facebookUrl || null,
        instagramUsername: this.state.socialMedia.instagramHandle || null,
        twitterHandle: this.state.socialMedia.twitterHandle || null,
        website: this.state.socialMedia.websiteUrl || null,
      },
      privacySettings: {
        location: this.state.contactsOption.locationFormat || [],
        displayEmailEnabled: this.state.contactsOption.email !== 'donotshow',
        displayMobilePhoneEnabled:
          this.state.contactsOption.phone !== 'donotshow',
      },
    }

    try {
      const profile = await client.request(createSellerProfileMutation, {
        input: variables,
      })

      removeLoader()
      onSuccess(profile)
    } catch (e) {
      removeLoader()
      onError(e)
    }
  }

  updateBusinessDetails = details => {
    this.setState({
      ...this.state,
      ...details,
    })
  }

  editSellerSettings = async (values, sellerSlugName) => {
    addLoader()

    const [err, response] = await to(
      client.request(editSellerProfileSettings, {
        settings: values,
        slugName: sellerSlugName,
      }),
    )

    if (err) {
      removeLoader()
      throw err
    }

    const {
      editSellerProfileSettings: {
        isProfileStatusActive = false,
        userInfo: {
          alternateFullName = '',
          businessCategory = '',
          displayAddress = '',
          displayEmail = '',
          displayMobilePhone = '',
          instagramUsername = '',
          facebookUrl = '',
          twitterHandle = '',
          website = '',
          profilePhotoUrl = '',
        } = {},
        personalizationSettings: {
          description = '',
          coverPhotoUrl = '',
          coverPhotoVerticalPan = 0,
        } = {},
        privacySettings: {
          emailSearchEnabled = false,
          phoneSearchEnabled = false,
          displayEmailEnabled = false,
          displayMobilePhoneEnabled = false,
          location = [],
        },
      } = {},
    } = response

    const profileStatus = isProfileStatusActive ? 'ACTIVE' : 'INACTIVE'
    this.setState({
      ...this.state,
      sellerProfileDetails: {
        ...this.state.sellerProfileDetails,
        status: profileStatus,
        user_info: {
          alternate_full_name: alternateFullName,
          business_category: businessCategory,
          display_email: displayEmail,
          display_mobile_phone: displayMobilePhone,
          display_address: displayAddress,
          instagram_username: instagramUsername,
          facebook_url: facebookUrl,
          twitter_handle: twitterHandle,
          website: website,
          profile_photo_url: profilePhotoUrl,
        },
        personalization_settings: {
          cover_photo_url: coverPhotoUrl,
          cover_photo_vertical_pan: coverPhotoVerticalPan,
          description,
        },
        privacy_settings: {
          email_search_enabled: emailSearchEnabled,
          phone_search_enabled: phoneSearchEnabled,
          display_email_enabled: displayEmailEnabled,
          display_mobile_phone_enabled: displayMobilePhoneEnabled,
          location: location,
        },
      },
    })

    removeLoader()
  }

  turnOnSellerProfile = async sellerSlugName => {
    addLoader()

    const [err, response] = await to(
      client.request(activateProfile, {
        slugName: sellerSlugName,
      }),
    )

    if (err) {
      removeLoader()
      throw err
    }

    const { activateProfile: { isProfileStatusActive = false } = {} } = response

    const profileStatus = isProfileStatusActive ? 'ACTIVE' : 'INACTIVE'
    this.setState({
      ...this.state,
      sellerProfileDetails: {
        ...this.state.sellerProfileDetails,
        status: profileStatus,
      },
    })

    removeLoader()
  }

  turnOffSellerProfile = async sellerSlugName => {
    addLoader()

    const [err, response] = await to(
      client.request(deactivateProfile, {
        slugName: sellerSlugName,
      }),
    )

    if (err) {
      removeLoader()
      throw err
    }

    const {
      deactivateProfile: { isProfileStatusActive = false } = {},
    } = response

    const profileStatus = isProfileStatusActive ? 'ACTIVE' : 'INACTIVE'
    this.setState({
      ...this.state,
      sellerProfileDetails: {
        ...this.state.sellerProfileDetails,
        status: profileStatus,
      },
    })

    removeLoader()
  }

  setValidBusinessHandle = flag => {
    this.setState({
      isValidBusinessHandle: flag,
    })
  }

  // photo upload funcs
  loadCachedProfilePhoto = () => {
    const cachedProfilePhoto = this.state.photoSettings.cachedProfilePhoto
    this.setState({
      ...this.state,
      photoSettings: {
        ...this.state.photoSettings,
        proposedProfilePhoto: cachedProfilePhoto,
      },
    })
  }

  updateProfilePhoto = async (
    imageDataUrl,
    onSuccess = () => {},
    onError = () => {},
    onScanError = () => {},
  ) => {
    const {
      proposedProfilePhoto,
      profilePhotoHasPassedMediaScreen: hasPassedMediaScreen,
      proposedProfilePhotoFileName: fileName = 'file.jpg',
    } = this.state.photoSettings

    let scanId = this.state.photoSettings.scannedIdProfilePhoto || ''

    addLoader()
    // Do we have a scanId?
    if (scanId === '') {
      // No??? Better scan it then.
      await this.scanImage({
        fileName: fileName,
        imageDataUrl,
        mediaCategory: 'PROFILE_IMAGE',
        handleActions: {
          onError: onScanError,
        },
      })
    }

    // getting the scan ID from the store after the scan above
    // if initially there was no scanned Id.  There would be no
    // valid scan id on an existing profile photo that the user
    // wants to crop/size.
    scanId = this.state.photoSettings.scannedIdProfilePhoto || ''

    // save to media serv
    try {
      const uploadResponse = await client.request(uploadScannedImageData, {
        input: {
          filename: fileName,
          imageDataUrl,
          hasPassedMediaScreen,
          scanId,
          imageSizeId: imageSizeId.profilePhoto,
          mediaCategory: 'PROFILE_IMAGE',
        },
      })

      this.setState({
        ...this.state,
        photoSettings: {
          ...this.state.photoSettings,
          cachedDataProfilePhoto: imageDataUrl,
          cachedProfilePhoto: proposedProfilePhoto,
          proposedProfilePhoto: undefined,
          proposedProfilePhotoUrl: get(
            uploadResponse,
            'uploadScannedPhoto.uploadedPhotoUrl',
            undefined,
          ),
          userHasProfilePicture: true,
        },
      })

      removeLoader()
      onSuccess()
    } catch (e) {
      removeLoader()
      onError()
    }
  }

  updatePhotoSettings = newSettings => {
    this.setState({
      ...this.state,
      photoSettings: {
        ...this.state.photoSettings,
        ...newSettings,
      },
    })
  }

  // this handles the user clicks 'back' without saving the currently
  // selected file that was 'uploaded' to the browser
  clearProposedProfilePhoto = () => {
    this.setState({
      ...this.state,
      photoSettings: {
        ...this.state.photoSettings,
        proposedProfilePhoto: undefined,
      },
    })
  }

  clearSavedProfilePhoto = () => {
    this.setState({
      ...this.state,
      photoSettings: {
        ...this.state.photoSettings,
        cachedDataProfilePhoto: undefined,
        proposedProfilePhoto: undefined,
        cachedProfilePhoto: undefined,
        hasSetDefaultProfilePicture: true,
        proposedProfilePhotoUrl: undefined,
        userHasProfilePicture: false,
      },
    })
  }

  changeSetDefaultProfilePictureState = () => {
    this.setState({
      ...this.state,
      photoSettings: {
        ...this.state.photoSettings,
        hasSetDefaultProfilePicture: false,
      },
    })
  }

  scanProfilePhoto = (
    event,
    onProposed = () => {},
    onError = () => {},
    onServiceError = undefined, // Initialized "onServiceError = undefined" to solve SonarLint issue and to not change the argument order.
  ) => {
    // this loader actually competes with the dialog, there is a loading
    // spinner within the dialog component
    addLoader()
    const fileReader = new FileReader()
    let file
    fileReader.onload = async onLoadEvent => {
      const url = onLoadEvent.target.result

      const variables = {
        filename: file.name,
        file: url,
        mediaCategory: 'PROFILE_IMAGE',
      }

      try {
        const response = await client.request(screenPhoto, variables)
        // now to check whether the photo scanned passed or not
        const {
          scanId = '',
          hasPassedMediaScreen = false,
          decisionMsg = '',
        } = response.screenPhoto

        if (hasPassedMediaScreen) {
          this.setState({
            ...this.state,
            photoSettings: {
              ...this.state.photoSettings,
              proposedProfilePhotoFileName: file.name,
              proposedProfilePhoto: url,
              scannedIdProfilePhoto: scanId,
              profilePhotoHasPassedMediaScreen: hasPassedMediaScreen,
            },
          })
          removeLoader()
          onProposed()
        } else {
          removeLoader()
          onError(decisionMsg)
        }
      } catch (e) {
        removeLoader()
        // something went wrong from the service
        if (onServiceError) {
          onServiceError(e)
        } else {
          onError()
        }
      }
    }

    file = event.target.files[0]

    if (file) {
      if (validateImage(file)) {
        fileReader.readAsDataURL(file)
      } else {
        removeLoader()
        onError()
      }
    } else {
      onError()
    }
  }

  // cover photo functions
  pan = (pan, deltaPan = '0', isDragging = false) => {
    this.setState({
      ...this.state,
      photoSettings: {
        ...this.state.photoSettings,
        coverPhotoPan: pan,
        deltaPan,
        isDragging,
      },
    })
  }

  setDraggableCoverPhoto = (draggable = false) => {
    this.setState({
      ...this.state,
      photoSettings: {
        ...this.state.photoSettings,
        isCoverDraggable: draggable,
      },
    })
  }

  updateCachedCoverPhoto = () => {
    const imageData = this.state.photoSettings.proposedCoverPhoto

    this.setState({
      ...this.state,
      photoSettings: {
        ...this.state.photoSettings,
        cachedCoverPhoto: imageData,
        proposedCoverPhoto: undefined,
        isCoverDraggable: false,
        cachedCoverPan: this.state.photoSettings.coverPhotoPan,
      },
    })
  }

  userImagePreviewUrl = (imagePreviewUrl, filename, isImageDataUrl = false) => {
    this.setState({
      ...this.state,
      photoSettings: {
        ...this.state.photoSettings,
        isCoverDraggable: isImageDataUrl,
        coverFilename: filename,
        ...(!isImageDataUrl && { cachedCoverPhoto: imagePreviewUrl }),
        proposedCoverPhoto: imagePreviewUrl,
      },
    })
  }

  // This function is used by on cover selection, and is a combination of both
  // a call to scan the image for violation AND a call to upload the photo to
  // mediaserv
  loadImage(
    fileSelectEvent,
    onSuccess,
    onImageScanError = () => {},
    onUploadError = () => {},
  ) {
    addLoader()
    const fileReader = new FileReader()
    let file
    fileReader.onload = async onLoadEvent => {
      const url = onLoadEvent.target.result
      const options = { mimeType: file.type }

      const variables = {
        filename: file.name,
        file: url,
        mediaCategory: 'COVER_IMAGE',
      }

      try {
        const response = await client.request(screenPhoto, variables)

        const {
          scanId = '',
          hasPassedMediaScreen = false,
          decisionMsg = '',
        } = response.screenPhoto

        // First check whether it passed the media scan
        if (hasPassedMediaScreen) {
          // Normalize the image before uploading it up to media serv
          normalizeImage(url, 1600, 1600, options, async normalizedUrl => {
            try {
              const uploadResponse = await client.request(
                uploadScannedImageData,
                {
                  input: {
                    filename: file.name,
                    imageDataUrl: normalizedUrl,
                    hasPassedMediaScreen,
                    scanId,
                    imageSizeId: imageSizeId.coverPhoto,
                    mediaCategory: 'COVER_IMAGE',
                  },
                },
              )

              this.userImagePreviewUrl(
                uploadResponse.uploadScannedPhoto.uploadedPhotoUrl,
                file && file.name,
                true,
              )
              removeLoader()

              if (onSuccess) {
                onSuccess()
              }
            } catch (e) {
              removeLoader()
              onUploadError()
            }
          })
        } else {
          removeLoader()
          onImageScanError(decisionMsg)
        }
      } catch (e) {
        removeLoader()
        onImageScanError()
      }
    }

    file = fileSelectEvent.target.files[0]

    if (file) {
      fileReader.readAsDataURL(file)
    }
  }

  /*
   *  Image processing function that will call the scan function
   *  and / or the upload function.
   *
   *  Scan does scanning only for bad photos.
   *  Upload will save the photo to mediaserv systems IF AND ONLY
   *  IF there is a valid scan ID
   *
   *  Why not do scan and upload at the same time? A profile photo
   *  on the web can be chosen via the browser file input but won't
   *  be ultimately chosen by the user to be used.  The goal was to
   *  keep from uploading unncessary images.
   */
  processImage = async (action, params) => {
    const { scan = false, upload = false } = action

    const {
      isNewFile = false,
      fileEvent: fileSelectEvent,
      mediaCategory = 'PROFILE_IMAGE',
      handleActions: { onScan = {}, onUpload = {} } = {},
    } = params

    // use the existing image OR set to null so we can load new data
    let imageDataUrl = params.imageDataUrl || null
    let fileName = 'file.jpg'
    if (isNewFile) {
      // a new file should ALWAYS have a file event tied to it
      const file = get(fileSelectEvent, 'target.files[0]')
      fileName = file.name
      try {
        imageDataUrl = await SellerProfileState.readUploadedFileAsDataUrl(file)
      } catch (err) {
        throw err
      }
    }

    // common parameters needed for scanning and uploading
    const commonParams = {
      fileName: fileName,
      imageDataUrl,
      mediaCategory,
    }

    if (scan) {
      this.scanImage({
        ...commonParams,
        handleActions: onScan,
      })
    }
    if (upload) {
      this.uploadImage({
        ...{ commonParams },
        handleActions: onUpload,
      })
    }
  }

  // This function only calls the scanning service on mediaserv.
  scanImage = async params => {
    const { fileName, imageDataUrl, mediaCategory, handleActions } = params

    const {
      onSuccess = () => {},
      onError = () => {},
      onServiceError = () => {},
    } = handleActions

    const variables = {
      filename: fileName,
      file: imageDataUrl,
      mediaCategory: mediaCategory,
    }

    addLoader()

    try {
      const response = await client.request(screenPhoto, variables)
      // now to check whether the photo scanned passed or not
      const {
        scanId = '',
        hasPassedMediaScreen = false,
        decisionMsg = '',
      } = response.screenPhoto

      if (hasPassedMediaScreen) {
        this.setState({
          ...this.state,
          photoSettings: {
            ...this.state.photoSettings,
            proposedProfilePhotoFileName: fileName,
            proposedProfilePhoto: imageDataUrl,
            scannedIdProfilePhoto: scanId,
            profilePhotoHasPassedMediaScreen: hasPassedMediaScreen,
          },
        })
        removeLoader()
        onSuccess()
      } else {
        removeLoader()
        onError(decisionMsg)
      }
    } catch (e) {
      removeLoader()
      // something went wrong from the service
      if (onServiceError) {
        onServiceError(e)
      } else {
        onError()
      }
    }
  }

  // This function only calls the upload service on mediaserv.
  uploadImage = () => {
    // TODO: Fill in when refactor.
  }

  /*
   * A static function that returns a promise so that we can use
   * async/await to read a image file.
   */
  static readUploadedFileAsDataUrl = async inputFile => {
    const temporaryFileReader = new FileReader()

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort()
        reject(new DOMException('Problem parsing input file.'))
      }

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result)
      }
      temporaryFileReader.readAsDataURL(inputFile)
    })
  }
}

// TODO: There is much more robust validation/manipulation that should be done here.
// Such as modifying the image to "1080p" for smaller upload size similar to cover photo.
function validateImage(image) {
  const MAX_IMAGE_FILE_SIZE = 12000000 // 12 MB
  return image.size < MAX_IMAGE_FILE_SIZE
}

export default SellerProfileState
