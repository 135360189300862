import { useState } from 'react'
import _ from 'lodash'
import axios from 'axios'
import clientData from '../../../utils/client-data'
import { slugAvailabilityAndSuggestions } from 'gql/mutation/slug-availability-and-suggestions'
import { handleAvailabilityAndSuggestions } from 'gql/mutation/handle-availability-and-suggestions'

/**
 * This custom hook takes profileType and businessName (applicable for seller profile only)  as input and creates internal states for -
 * 1. suggestions => List of suggestions based on the given preferred_id.
 * 2. isCheckingHandleAvailability => To be able to show the loader while fetch call is happening.
 * 3. isHandleAvailable => if the preferred_id is taken already or not.
 *
 * These states and the function fetchSuggestionsAndAvailability() are exposed to be re-used between personal and seller profile pages
 * along with other required stated which are being used by user-input-handle.js
 * @param {*} profileType Seller or Personal.
 * @param {*} businessName  applicable for seller profile only
 * @returns
 */
export default function useSuggestionsAPI(profileType, businessName) {
  const [suggestions, setSuggestions] = useState([])
  const [
    isCheckingHandleAvailability,
    setIsCheckingHandleAvailability,
  ] = useState(false)
  const [isHandleAvailable, setIsHandleAvailable] = useState(false)

  /**
   * Suggestions and Availability API calls based on preferredId.
   * A new cancelToken must be attached everytime when user input changes so that we can cancel the appropriate network call when we need to.
   * @param {*} preferredId
   * @param {*} cancelToken
   */
  const fetchSuggestionsAndAvailability = async (preferredId, cancelToken) => {
    setIsCheckingHandleAvailability(true) // right icon spinner.

    const cancelPayload = {
      cancelToken: cancelToken.token,
    }
    const graphqlEndpoint = `${window.location.origin}${clientData.requestURI}/v1/graphql`

    axios.defaults.headers.post['x-csrf-token'] = _.get(clientData, 'csrf')
    try {
      const profileInput = getProfile(preferredId)
      const availabilityPayload = {
        query: _.get(profileInput, 'suggestionsQuery', ''),
        variables: _.get(profileInput, 'variables', ''),
      }

      const availabilityResponse = await axios.post(
        graphqlEndpoint,
        availabilityPayload,
        cancelPayload,
      )
      setIsCheckingHandleAvailability(false)
      const availabilityData = _.get(
        availabilityResponse,
        _.get(profileInput, 'readQuery', ''),
        {},
      )
      const isUserNameHandleAvailable = _.get(
        availabilityData,
        'isPreferredIdAvailable',
        false,
      )
      setIsHandleAvailable(isUserNameHandleAvailable)

      if (!isUserNameHandleAvailable) {
        const suggestedSlugs = _.get(availabilityData, 'suggestedSlugs', [])
        setSuggestions(suggestedSlugs)
      }
    } catch (error) {
      setIsCheckingHandleAvailability(true)
    }
    return true
  }

  /**
   * Between seller profile and personal profile payload objects, the difference is mainly with the object names.
   * Also, the mutation for graphQl.
   * This function returns the required configs based on the profile type.
   * @param {*} preferredId
   * @returns
   */
  const getProfile = preferredId => {
    const profile = {
      seller: {
        suggestionsQuery: handleAvailabilityAndSuggestions,
        variables: {
          handle: {
            preferred_id: preferredId,
            business_name: businessName,
          },
        },
        readQuery: 'data.data.handleAvailabilityAndSuggestions',
      },
      personal: {
        suggestionsQuery: slugAvailabilityAndSuggestions,
        variables: {
          slug: {
            preferred_id: preferredId,
          },
        },
        readQuery: 'data.data.slugAvailabilityAndSuggestions',
      },
    }

    return profile[profileType]
  }

  return {
    suggestions,
    setSuggestions,
    isCheckingHandleAvailability,
    isHandleAvailable,
    setIsHandleAvailable,
    fetchSuggestionsAndAvailability,
  }
}
