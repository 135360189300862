import glamorous from 'glamorous'
import loadable from 'react-loadable'

const Loading = glamorous.div('vx_has-spinner-small', {
  width: '100%',
  height: '100%',
})

const l = loader => {
  return loadable({
    loader,
    loading: Loading,
  })
}

/* ROUTES: GENERAL */
export const AnimatedSwitchPagination = l(() =>
  import(/* webpackChunkName: "ppme-shared" */ './pagination'),
)
export const SimpleLayout = l(() =>
  import(/* webpackChunkName: "ppme-shared" */ 'screens/simplelayout'),
)

export const SessionTimer = l(() =>
  import(/* webpackChunkName: "ppme-shared" */ 'screens/session-timer'),
)

/* ROUTES: SLUG */
export const ProfileDetails = l(() =>
  import(/* webpackChunkName: "ppme-slug" */ 'screens/my-profile'),
)
export const SlugProfile = l(() =>
  import(/* webpackChunkName: "ppme-slug" */ 'screens/recipient-slug'),
)
export const MainComponent = l(() =>
  import(/* webpackChunkName: "ppme-slug" */ 'screens'),
)

/* ROUTE: GRAB */
export const Grab = l(() =>
  import(/* webpackChunkName: "ppme-grab" */ 'screens/grab/grab'),
)
export const Picture = l(() =>
  import(/* webpackChunkName: "ppme-grab" */ 'screens/grab/picture'),
)
export const Taken = l(() =>
  import(/* webpackChunkName: "ppme-grab" */ 'screens/grab/taken'),
)
export const Success = l(() =>
  import(/* webpackChunkName: "ppme-grab" */ 'screens/grab/success'),
)

export const CreateForExistingUser = l(() =>
  import(
    /* webpackChunkName: "ppme-grab" */ 'screens/grab/create-for-existing-user'
  ),
)

export const CreateUserNameForPersonalProfile = l(() =>
  import(/* webpackChunkName: "ppme-grab" */ 'screens/personal-profile-handle'),
)

export const VisaPlusProfile = l(() =>
  import(/* webpackChunkName: "ppme-grab" */ 'screens/visa-plus'),
)

export const GrabLandingPage = l(() =>
  import(/* webpackChunkName: "ppme-grab" */ 'screens/grab/landing'),
)

/* ROUTES: SETTINGS */
export const SettingsContainer = l(() =>
  import(/* webpackChunkName: "ppme-settings" */ 'screens/settings/container'),
)
export const ProfileSettings = l(() =>
  import(
    /* webpackChunkName: "ppme-settings" */ 'screens/settings/profile-settings'
  ),
)
export const TurnOffConfirmation = l(() =>
  import(
    /* webpackChunkName: "ppme-settings" */ 'screens/settings/turn-off-confirmation'
  ),
)
export const Location = l(() =>
  import(/* webpackChunkName: "ppme-settings" */ 'screens/settings/location'),
)
export const Username = l(() =>
  import(/* webpackChunkName: "ppme-settings" */ 'screens/settings/username'),
)
export const About = l(() =>
  import(/* webpackChunkName: "ppme-settings" */ 'screens/settings/about'),
)
export const CoverSelect = l(() =>
  import(
    /* webpackChunkName: "ppme-settings" */ 'screens/settings/cover-select'
  ),
)
export const CoverPhoto = l(() =>
  import(
    /* webpackChunkName: "ppme-settings" */ 'screens/settings/cover-photo'
  ),
)
export const CoverPan = l(() =>
  import(/* webpackChunkName: "ppme-settings" */ 'screens/settings/cover-pan'),
)
export const LandingPage = l(() =>
  import(
    /* webpackChunkName: "ppme-settings" */ 'screens/settings/landing-page'
  ),
)
