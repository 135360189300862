import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { debounce, forEach } from 'lodash'
import glamorous from 'glamorous'
import {
  TextInput,
  grey200,
  grey600,
  v2blue400,
  v2blue500,
  v2green400,
  v2blue600,
  white,
  Icon,
} from '@paypalcorp/pp-react'
import {
  phone6PlusPortraitMaxAndSmaller,
  phone6PortraitMaxAndSmaller,
} from 'lib/styles/media-queries'
import getContent from 'pp-react-l10n'
import { SELLER_SOCIAL, buttonsInitialData } from '../../../utils/constants'

const l10n = getContent('components/social_media_link')

// start temporary implementation until we upgrade PPME to PPUI V2

const getWebsiteIcon = color => {
  return `url("data:image/svg+xml; charset=utf8, ${encodeURIComponent(
    `<svg xmlns='http://www.w3.org/2000/svg' fill='${color}' viewBox='0 0 24 24'><path d='M19.778 4H4.222C3 4 2 5 2 6.222v11.556C2 19 3 20 4.222 20h15.556C21 20 22 19 22 17.778V6.222A2.222 2.222 0 0 0 19.778 4zM14.7 13.489L13.433 14l1.223 1.222c.233.234.233.6 0 .834l-.2.2a.584.584 0 0 1-.834 0L12.4 15.033l-.511 1.267a.294.294 0 0 1-.545 0l-2.2-5.167a.298.298 0 0 1 .39-.389l5.166 2.19c.244.11.244.455 0 .555zm5.411-5.056H3.89a.223.223 0 0 1-.222-.222V6.222a.55.55 0 0 1 .555-.555h15.556a.55.55 0 0 1 .555.555v1.99c0 .121-.1.221-.222.221z'/></svg>`,
  )}")`
}

const IconWebsite = glamorous.span({
  display: 'inline-block', // NOSONAR
  height: 28,
  '::before': {
    width: '1.875rem',
    display: 'inline-block',
    position: 'relative',
    left: 0,
    top: 2,
    lineHeight: 1,
    content: getWebsiteIcon(grey600),
  },
  '::after': {
    top: '17px !important',
    right: '-11px !important',
  },
})
// end

const ShareButton = glamorous.label(
  {
    position: 'relative',
    textAlign: 'center',
    marginRight: 16,
    '@media(max-width: 546px)': {
      marginRight: 12,
    },
    [phone6PlusPortraitMaxAndSmaller]: {
      marginRight: 6,
    },
    '@media(max-width: 393px)': {
      marginRight: 3,
    },
    [phone6PortraitMaxAndSmaller]: {
      marginRight: 5,
      // button size for 375
      '& > span': {
        width: 75,
        height: 38,
      },
    },
    display: 'inline-block',
    ':focus-within': {
      borderRadius: 53,
      // boxShadow: `0 0 0 0.25rem ${v2blue300}`,
      boxShadow: `0 0 0 0.125rem ${v2blue500} inset, 0 0 0 0.375rem ${v2blue400}29`,
    },
    '& > span': {
      display: 'inline-block',
      backgroundColor: grey200,
      boxSizing: 'border-box',
      border: 'none',
      borderRadius: 53,
      lineHeight: '53px',
      verticalAlign: 'middle',
      // button size for desktop/ipad
      width: 85,
      height: 40,
      // button size for medium/large size phone
      '@media((min-width: 325px) and (max-width: 414px))': {
        width: 76,
        height: 38,
      },
      // button size for small size phone
      '@media(max-width: 325px)': {
        width: 55,
        height: 38,
      },
      '& > span': {
        color: grey600,
      },
      '&:hover': {
        backgroundColor: v2blue600,
        '& > span': {
          color: white,
        },
        '& > span.website::before': {
          content: getWebsiteIcon(white),
        },
      },
    },
    '& input[type="radio"]': {
      opacity: 0,
      zIndex: -100,
      position: 'absolute',
      top: '50%',
      right: '50%',
    },
    '& input[type="radio"]:checked  + span': {
      backgroundColor: v2blue600,
      '& > span': {
        color: white,
      },
      '& > span::after': {
        // change the check mark to white with blue border on hover
        color: white,
        background:
          'radial-gradient(circle, rgba(0, 112, 186, 1) 70%, rgba(0, 112, 186, 0) 50%) no-repeat 0 1px',
      },
      '& > span.website::before': {
        content: getWebsiteIcon(white),
      },
    },
  },
  // when doneState is true add a green check mark to the button
  ({ doneState }) =>
    doneState && {
      '& > span > span': {
        position: 'relative',
      },
      '& > span > span::after': {
        zIndex: 100,
        position: 'absolute',
        top: 13,
        right: -10,
        color: v2green400,
        fontFamily: 'PPUI-Icons !important',
        lineHeight: 1,
        fontSize: 20,
        // CheckCircleAltIcon from PPUI
        content: '\\e90e',
        // The font is transparent, so we draw a white circle behind the check mark
        background:
          'radial-gradient(circle, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 50%)',
      },
      '&:hover > span > span::after': {
        // change the check mark to white with blue border on hover
        color: white,
        background:
          'radial-gradient(circle, rgba(0, 112, 186, 1) 70%, rgba(0, 112, 186, 0) 50%) no-repeat 0 1px',
      },
    },
)

const Container = glamorous.div({
  margin: '10px 0',
  textAlign: 'left',
  '& .inputField': {
    margin: '1rem 0',
  },
  '& .visible': {
    display: 'block',
  },
  '& .hidden': {
    display: 'none',
  },
})

SocialMediaLink.propTypes = {
  buttonsInitialData: PropTypes.object,
  handleValidState: PropTypes.func,
  handleChangeInput: PropTypes.func,
}

SocialMediaLink.defaultProps = {
  buttonsInitialData: buttonsInitialData,
  handleValidState: () => {},
  handleChangeInput: () => {},
}

/**
 * This is a helper method to propagate the valid/error state
 * to the parent component, we use it to hide and show the Next button
 * @param {Object} buttonsData - contains the buttons data from state
 * @param {Function} handleValidState - a handler from the parent component
 */
const propagateValidationUp = (buttonsData, handleValidState) => {
  // propagate valid state up
  const validatedButtons = []
  const errorButtons = []

  forEach(buttonsData, buttonValue => {
    validatedButtons.push(buttonValue.doneState)
    errorButtons.push(buttonValue.showError)
  })

  if (validatedButtons.includes(true) && !errorButtons.includes(true)) {
    handleValidState(true)
  } else {
    handleValidState(false)
  }
}

function SocialMediaLink(props) {
  const selectDefaultButton = 'instagram'
  const [buttonsData, setButtonsData] = useState(props.buttonsInitialData)
  const [currentButton, setCurrentButton] = useState(selectDefaultButton)
  const delayedValidation = useCallback(
    debounce((fn, ...args) => fn(...args), 1000),
    [],
  )
  useEffect(() => {
    const initialValue = { target: { value: selectDefaultButton } }
    handleButtonClick(initialValue)
  }, [selectDefaultButton])
  // This is needed to hide/show the Next button on the parent component
  useEffect(() => {
    propagateValidationUp(buttonsData, props.handleValidState)
  })

  /**
   * This method handles button clicks
   * @param {Event} e - click event object
   */
  const handleButtonClick = e => {
    const buttonClicked = e.target.value

    //if error shown do not proceed
    if (currentButton && buttonsData[currentButton].showError) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }

    // track current button selected
    setCurrentButton(buttonClicked)

    // show relevant input field
    setButtonsData(prevState => {
      // reset visibility state
      const buttonsData = Object.assign({}, prevState)
      for (const button in buttonsData) {
        buttonsData[button].visibleInput = false
        if (
          buttonsData[button].textValue &&
          buttonsData[button].textValue !== ''
        ) {
          buttonsData[button].doneState = true
        }
      }
      // make selected input field visible
      buttonsData[buttonClicked].visibleInput = true
      return buttonsData
    })
  }

  /**
   * This method handles input field typing
   * @param {Event} e - change event object
   */
  const handleInputTyping = e => {
    e.persist()
    // on tab out validate right away
    if (e.type === 'blur') {
      validateInput(e)
    } else {
      // when typing validate with a delay
      delayedValidation(validateInput, e)
    }
  }

  /**
   * This helper method validates input field values
   * @param {Event} e - change event object
   */
  const validateInput = e => {
    const inputValue = e.target.value
    const buttonName = e.target.id.substr(6)
    // if validation doesn't pass show the error text
    if (inputValue !== '') {
      const socialValidationTest = buttonsData[buttonName].validation.test(
        inputValue,
      )
      const faceboobMaxCharTest =
        buttonName === 'facebook' &&
        inputValue.toString().length > SELLER_SOCIAL.facebook.MAX_CHAR_LIMIT
      // Additional Validation for facebook for character length limit
      // set the current button state to erroneous
      if (!socialValidationTest || faceboobMaxCharTest) {
        setButtonsData(prevState => ({
          ...prevState,
          [buttonName]: {
            ...prevState[buttonName],
            showError: true,
            doneState: false,
          },
        }))
      } else {
        // show the green checkmark on the button if validated
        // if valid data, update to unstated state
        // as it causes clearing values when moved b/w page
        props.handleChangeInput(buttonName, inputValue)

        setButtonsData(prevState => ({
          ...prevState,
          [buttonName]: {
            ...prevState[buttonName],
            showError: false,
            doneState: true,
          },
        }))
      }
    } else {
      if (inputValue === '') {
        // if field is cleared, need to update that as well
        props.handleChangeInput(buttonName, inputValue)
      }

      // reset the button's state
      setButtonsData(prevState => ({
        ...prevState,
        [buttonName]: {
          ...prevState[buttonName],
          showError: false,
          doneState: false,
          textValue: inputValue,
        },
      }))
    }
  }

  return (
    <Container data-test-id="social-media-link">
      <p id="socialMediaLinkTitle">{l10n('headerText')}</p>
      {Object.keys(buttonsData).map(buttonName => (
        <ShareButton
          key={buttonName}
          data-test-id={`${buttonName}Lbl`}
          doneState={buttonsData[buttonName].doneState}
          aria-describedby="socialMediaLinkTitle"
        >
          <input
            name="shareRadioButton"
            type="radio"
            value={buttonName}
            id={`share-${buttonName}`}
            defaultChecked={buttonName === 'instagram' ? 'checked' : ''}
            aria-label={
              buttonsData[buttonName].doneState
                ? l10n(buttonsData[buttonName].ariaLabelDone)
                : l10n(buttonsData[buttonName].ariaLabel)
            }
            onClick={handleButtonClick}
          />
          <span role="presentation" aria-hidden="true">
            {/* temporary implementation until we upgrade to PPUI V2 */}
            {buttonName === 'website' ? (
              <IconWebsite className="website" />
            ) : (
              <Icon name={`share-${buttonName}`} />
            )}
          </span>
        </ShareButton>
      ))}
      {Object.keys(buttonsData).map(buttonName => (
        <TextInput
          data-test-id={`input-${buttonName}`}
          key={buttonName}
          maxLength="350"
          id={`input-${buttonName}`}
          className={`inputField ${
            buttonsData[buttonName].visibleInput ? 'visible' : 'hidden'
          }`}
          defaultValue={buttonsData[buttonName].textValue}
          onChange={handleInputTyping}
          onBlur={handleInputTyping}
          label={l10n(buttonsData[buttonName].label)}
          helperText={
            buttonsData[buttonName].helperText
              ? l10n(buttonsData[buttonName].helperText)
              : ''
          }
          errorText={
            buttonsData[buttonName].showError &&
            l10n(buttonsData[buttonName].error)
          }
        />
      ))}
    </Container>
  )
}

export default SocialMediaLink
