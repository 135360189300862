/*
 * This plugin for glamor will prepend #id to the beginning of each selector
 * which glamor generates. So a selector like `.css-123abc, [data-css-123abc]`
 * will be changed to: `#id .css-123abc, #id [data-css-123abc]`.
 * This gives the selector higher specificity and should make it impervious
 * to other stylesheets on the page (component styles should always win a
 * conflict war with stylesheets).
 */
export default getSpecificityPlugin

/**
 * Gets the plugin function that uses the given id
 * @param {String} id the ID to prepend selectors with
 * @return {Function} the glamor plugin function
 */
function getSpecificityPlugin(id) {
  return specificityPlugin

  function specificityPlugin({ selector, style }) {
    selector = selector
      .split(',')
      .map(sel => `#${id} ${sel.trim()}`)
      .join(', ')
    return { selector, style }
  }
}
