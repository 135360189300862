// @flow
import _ from 'lodash'
import { useState, useCallback } from 'react'

/**
 * All re-usable client-side validation.
 * @returns
 */
export default function useCharacterValidation() {
  const [fieldError, setFieldError] = useState({ type: 'none' })

  const delayedValidation = useCallback(
    _.debounce(value => {
      const err = validate(value)
      setFieldError({ type: err })
    }, 500),
    [],
  )

  const validate = value => {
    if (value.length === 0) {
      return 'empty'
    } else if (value.length < 3) {
      return 'tooShort'
    } else if (value.length > 20) {
      return 'tooLong'
    } else if (!validateChar(value)) {
      return 'invalid'
    } else {
      return 'success'
    }
  }

  const validateChar = value => {
    const SLUG_VALIDATION_REGEX = /^[a-z0-9]*$/i
    if (SLUG_VALIDATION_REGEX.test(value)) {
      return true
    }
    return false
  }

  return [fieldError, delayedValidation, setFieldError]
}
