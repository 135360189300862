import React, { useEffect } from 'react'
import clientData from 'utils/client-data'
import glamorous from 'glamorous'
import { css } from 'glamor'
import {
  HeadingText,
  BodyText,
  V2ChevronRightIcon,
  white,
  Link,
  grey300,
  grey500,
  v2grey600,
  Tooltip,
} from '@paypalcorp/pp-react'
import getContent from 'pp-react-l10n'
import Events from 'lib/analytics/event-tags'
import {
  phoneLandscapeMaxAndSmaller,
  phoneLandscapeMinAndSmaller,
} from 'lib/styles/media-queries'
import { tracking } from 'lib/analytics'
import EditBackground from '../shared/edit-background'
import { getDomain } from '../../../utils/generate-urls'
const l10n = getContent('pages/seller/settings')

const Container = glamorous.div({
  margin: '-30px 0 0 0',
  fontSize: '1rem',
})

const Heading = css({
  textAlign: 'center',
  position: 'absolute',
  width: '60%',
  left: '50%',
  marginLeft: '-30%',
  top: -85,
  lineHeight: '2.125rem',
  fontSize: '1.25rem',
  [phoneLandscapeMaxAndSmaller]: {
    lineHeight: '2rem',
  },
})

const title = css({
  marginTop: 320,
  [phoneLandscapeMaxAndSmaller]: {
    marginTop: 300,
  },
  [phoneLandscapeMinAndSmaller]: {
    marginTop: 230,
  },
})

const tooltipIconStyle = css({
  position: 'absolute',
  top: '0px',
  right: '-7px',
})

const EditItem = glamorous.div(
  {
    position: 'relative',
    margin: '16px 0',
    width: '100%',
    cursor: 'pointer',
  },
  ({ noEdit }) =>
    noEdit && {
      cursor: 'default',
    },
)

const editLabel = css({
  fontSize: '0.875rem',
  color: v2grey600,
})

const editValue = css({
  width: 'calc(100% - 40px)',
  marginTop: 2,
  overflowWrap: 'break-word',
})

const socialMediaText = css({
  marginTop: 0,
})

const rightArrow = css({
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
})

const extraPadding = css({
  padding: '16px 0',
})

const noMargin = css({
  margin: 0,
})

const customMargin = css({
  margin: '16px 0',
  paddingBottom: 10,
})

const DottedSeparater = glamorous.div({
  backgroundImage: `linear-gradient(to right, ${grey500} 10%, ${white} 5%)`,
  backgroundPosition: 'top',
  backgroundSize: '7px 1px',
  backgroundRepeat: 'repeat-x',
  height: 1,
  marginTop: 16,
})

const Separater = glamorous.div({
  width: '116%',
  height: 1,
  borderBottom: `1px solid ${grey300}`,
  margin: '26px 0',
  transform: 'translateX(-8%)',
})

function SellerSettings(props) {
  const {
    sellerProfileState: {
      state: {
        sellerProfileDetails: {
          id: businessHandle = '',
          status = 'INACTIVE',
          user_info: {
            alternate_full_name: businessName = '',
            display_email: email = '',
            display_mobile_phone: phone = '',
            display_address: businessLocation = '',
            profile_photo_url: profilePhotoUrl = '',
          } = {},
          personalization_settings: {
            cover_photo_url: coverPhotoUrl = '',
            cover_photo_vertical_pan: coverPhotoVerticalPan = '',
            description = '',
          } = {},
          privacy_settings: {
            display_email_enabled: displayEmailEnabled = false,
            display_mobile_phone_enabled: displayMobilePhoneEnabled = false,
          } = {},
        } = {},
      } = {},
    } = {},
  } = props
  const { isMobile } = clientData
  const profileStatus = status === 'ACTIVE'

  useEffect(() => {
    tracking(
      Events.public_identity_edit_profile_screen_shown({
        status: status.toLowerCase(),
      }),
    )
  }, [])

  const businessMap = [
    {
      label: l10n('businessName'),
      value: businessName,
      onClick: () => {
        tracking(Events.public_identity_edit_profile_business_name_pressed({}))
        props.history.push(`/seller/${businessHandle}/settings/name`)
      },
      testId: 'name',
    },
    {
      label: l10n('businessHandle'),
      value: `@${businessHandle}`,
      onClick: () => {},
      testId: 'handle',
    },
    {
      label: l10n('description'),
      value: description,
      onClick: () => {
        tracking(Events.public_identity_edit_profile_about_pressed({}))
        props.history.push(`/seller/${businessHandle}/settings/description`)
      },
      testId: 'description',
    },
  ]

  const contactDetailsMap = [
    {
      label: l10n('phone'),
      value: displayMobilePhoneEnabled
        ? phone
        : l10n('edit.phone.option.hide', { phone }),
      onClick: () => {
        tracking(Events.public_identity_edit_profile_phone_pressed({}))
        props.history.push(`/seller/${businessHandle}/settings/phone`)
      },
      testId: 'phone',
    },
    {
      label: l10n('email'),
      value: displayEmailEnabled
        ? email
        : l10n('edit.email.option.hide', { email }),
      onClick: () => {
        tracking(Events.public_identity_edit_profile_email_pressed({}))
        props.history.push(`/seller/${businessHandle}/settings/email`)
      },
      testId: 'email',
    },
    {
      label: l10n('businessLocation'),
      value: businessLocation
        ? businessLocation
        : l10n('edit.location.option.none'),
      onClick: () => {
        tracking(Events.public_identity_edit_profile_location_pressed({}))
        props.history.push(`/seller/${businessHandle}/settings/location`)
      },
      testId: 'location',
    },
  ]

  const onEditProfileStatus = e => {
    e.preventDefault()
    tracking(Events.public_identity_edit_profile_status_pressed({}))
    props.history.push({
      pathname: `/seller/${businessHandle}/settings/profile`,
      state: {
        fromSellerSettings: true,
      },
    })
  }

  const onEditSocialMedia = e => {
    e.preventDefault()
    tracking(Events.public_identity_edit_profile_social_pressed({}))
    props.history.push(`/seller/${businessHandle}/settings/social`)
  }

  const redirectToSearchPrivacy = () => {
    const host = getDomain(clientData)
    tracking(Events.public_identity_edit_profile_privacy_pressed({}))
    window.location.href = `${host}/myaccount/privacy/profiles/search?activetab=seller`
  }

  return (
    <Container data-test-id="seller-settings-screen">
      {isMobile ? (
        <BodyText className={`${Heading}`} strong role="heading" aria-level="1">
          {l10n('editProfile')}
        </BodyText>
      ) : (
        <HeadingText
          size="sm"
          className={`${Heading}`}
          role="heading"
          aria-level="1"
        >
          {l10n('editProfile')}
        </HeadingText>
      )}
      <EditBackground
        {...{ profilePhotoUrl, coverPhotoUrl, coverPhotoVerticalPan, ...props }}
        data-test-id="seller-settings-photos"
      />
      <BodyText strong className={`${title}`}>
        {l10n('profileDetails')}
      </BodyText>
      {profileStatus
        ? businessMap.map((item, i) => {
            return (
              <EditItem
                key={i}
                onClick={item.onClick}
                noEdit={i === 1}
                data-test-id={`seller-settings-${item.testId}`}
              >
                <BodyText className={`${editLabel}`}>{item.label}</BodyText>
                <BodyText className={`${editValue}`}>{item.value}</BodyText>
                {i !== 1 ? (
                  <V2ChevronRightIcon size="sm" className={`${rightArrow}`} />
                ) : (
                  <Tooltip
                    position="left"
                    className={`${tooltipIconStyle}`}
                    theme="v2"
                    description={l10n('cannotEditHandle')}
                  />
                )}
                <DottedSeparater />
              </EditItem>
            )
          })
        : null}
      {!clientData.isP2PHideProfilePageSettings &&
        <EditItem
          onClick={onEditProfileStatus}
          data-test-id="seller-settings-status"
        >
          <BodyText strong>{`${l10n('profileStatus')} ${
            profileStatus ? l10n('On') : l10n('Off')
          }`}</BodyText>
          <BodyText className={`${editValue}`}>
            {profileStatus
              ? l10n('edit.status.option.onText')
              : l10n('edit.status.option.offText')}
          </BodyText>
          <V2ChevronRightIcon size="sm" className={`${rightArrow}`} />
          {profileStatus ? <DottedSeparater /> : null}
        </EditItem>
      }
      {profileStatus ? (
        <>
          {!clientData.isP2PHideProfilePageSettings &&
            <>
              <EditItem
                onClick={redirectToSearchPrivacy}
                data-test-id="seller-settings-privacy"
              >
                <BodyText strong>{l10n('searchPrivacy')}</BodyText>
                <BodyText className={`${editValue}`}>
                  {l10n('searchPrivacyText')}
                </BodyText>
                <V2ChevronRightIcon size="sm" className={`${rightArrow}`} />
              </EditItem>
              <Separater />
            </>
          }
          <BodyText>
            {l10n('primaryAccountInfo', {
              primaryAccountLink: (
                <Link
                  href="/myaccount/settings"
                  onClick={() =>
                    tracking(
                      Events.public_identity_edit_profile_settings_pressed({}),
                    )
                  }
                >
                  {l10n('primaryAccountSettings')}
                </Link>
              ),
            })}
          </BodyText>
        </>
      ) : null}
      {profileStatus
        ? contactDetailsMap.map((item, i) => {
            return (
              <EditItem
                key={i}
                className={`${i === 2 ? customMargin : ''}`}
                onClick={item.onClick}
                data-test-id={`seller-settings-${item.testId}`}
              >
                <BodyText className={`${editLabel}`}>{item.label}</BodyText>
                <BodyText className={`${editValue}`}>{item.value}</BodyText>
                <V2ChevronRightIcon size="sm" className={`${rightArrow}`} />
                {i !== 2 ? <DottedSeparater /> : null}
              </EditItem>
            )
          })
        : null}
      {profileStatus ? (
        <>
          <Separater className={`${noMargin}`} />
          <EditItem
            className={`${extraPadding}`}
            onClick={onEditSocialMedia}
            data-test-id="seller-settings-social"
          >
            <BodyText strong className={`${socialMediaText}`}>
              {l10n('socialMedia')}
            </BodyText>
            <V2ChevronRightIcon size="sm" className={`${rightArrow}`} />
          </EditItem>
          <Separater className={`${noMargin}`} />
        </>
      ) : null}
    </Container>
  )
}

export default SellerSettings
