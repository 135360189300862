/**
 * @fileOverview Analytics – link and error tracking
 * @name Analytics Widget
 * @author dquock, jamuferguson
 *
 * This file is a wrapper around site catalyst and fpti
 * and allows us to easily fire off tracking calls to
 * both at the same time.
 *
 * Access FPTI performance info from PAYPAL.analytics.perf
 * https://dev.paypal.com/wiki/General/FPTI-PerformanceTracking
 *
 */

import debug from 'debug'
import _ from 'lodash'

// NOTE: it's not safe to shadow window.fpti at the module level
// because it gets reset after 500ms in pa.js (see: pageTracking.dust)
const PAYPAL = window.PAYPAL || {}

// New environment will be stored, incase want to overrite default env for any external flows.
let newEnv

// instantiate the view (with a backup for mocks mode)
const emptyAnalytics = {
  logActivity() {},
  recordClick() {},
  recordImpression() {},
}

const analytics = PAYPAL.analytics
  ? new PAYPAL.analytics.Analytics()
  : emptyAnalytics

const analyticsDebug = debug('ppme:analytics')
const RECORDING_ANALYTICS = 'Recording analytics:'

/*************
 * Public API
 *************/

/**
 Setting new environment
 * @param {String} env - new environment set by external flow.
 */
export function overrideEnvironment(env) {
  newEnv = env
}

/**
 * Set FPTI/SC variables and fire tracking beacons
 *
 * @param {String} linkName - The name of the link
 * @param {String} pageGroup - The page group (prev. pageName)
 * @param {String} pageName - The actual page name (prev. pageName2)
 * @param {String} trackType - i.e "link"
 * @param {String} transactionDetailsLinks
 */
export const trackLink = whenReady(
  (linkName, pageGroup, pageName, trackType, transactionDetailsLinks) => {
    const fpti = window.fpti || {}
    const finalPageGroup = newEnv
      ? getPageNameForEnvironment(pageGroup, newEnv)
      : pageGroup
    const finalPageName = newEnv
      ? getPageNameForEnvironment(pageName, newEnv)
      : pageName

    analyticsDebug(RECORDING_ANALYTICS, [
      linkName,
      finalPageGroup,
      finalPageName,
      trackType,
      transactionDetailsLinks,
    ])

    // set linkname
    fpti.link = linkName

    // set pageNames
    fpti.page = finalPageName
    fpti.pglk = linkName && `${pageGroup}|${linkName}`
    fpti.pgrp = finalPageGroup

    clearErrors(fpti)

    try {
      if (trackType === 'link') {
        analytics.recordClick()
      } else {
        analytics.recordImpression()
      }
    } catch (exception) {
      debug('Analytics Exception Found:')(
        'Analytics Exception Found:',
        exception,
      )
    }
  },
)

/**
 * Wrapper around trackLink that lets us send additional FPTI keys
 * @param linkName - the link name for the trackLink call
 * @param pageGroup - the page group for the trackLink call
 * @param {String} pageName - The actual page name (prev. pageName2)
 * @param data - key value pairs we want to send to FPTI then remove
 */
export const trackLinkWithData = whenReady(
  // eslint-disable-next-line max-params
  (linkName, pageGroup, pageName, data, trackType, transactionDetailsLinks) => {
    const fpti = window.fpti || {}

    // apply extra fields to window.fpti
    Object.assign(fpti, data)

    trackLink(linkName, pageGroup, pageName, trackType, transactionDetailsLinks)

    // remove those additional keys
    Object.keys(data).forEach(key => {
      delete fpti[key]
    })
  },
)

/**
 * Link tracking
 * @param {Object} event - click event || the clicked jQuery object of the input used to submit a form
 * if event isn't defined or is null, explicitly specify params
 * @param {String} linkName - name attribute from the link
 * @param {String} pageGroup - data-pagename the link is going to
 * @param {String} pageName - data-pagename2 the link is going to
 * @param {String} trackType - page-level or not
 * @param {String} transactionDetailsLinks - activity transaction details links
 */
export const trackElement = whenReady(element => {
  const linkName =
    element.getAttribute('name') || (element && element.innerHTML)
  const trackType = element.dataset['track-type'] || 'link'

  const pageGroup = element.dataset.pagename
  const pageName = element.dataset.pagename2 || `${pageGroup}:::${trackType}`

  const nextPageGroup = element.dataset.nextpagegroup
  const nextPageName = element.dataset.nextpagename

  const transactionDetailsLinks = element.dataset.transactiondetailslinks
  const extra = element.dataset.extra || {}

  // Don't want beacons firing without a destination
  if (pageGroup) {
    trackLinkWithData(
      linkName,
      pageGroup,
      pageName,
      extra,
      trackType,
      transactionDetailsLinks,
    )
  }

  // Allow for impressions for pages without async function calls
  if (nextPageGroup) {
    trackImpression(nextPageGroup, nextPageName, extra)
  }
})

/**
 * Track Impression
 * Impression beacon will be fired when `trackType` is not specified
 * @param {string} error - The page name that fires impression beacon
 */
export const trackImpression = whenReady((pageGroup, pageName, data = {}) => {
  // linkName is not needed for impression
  trackLinkWithData('', pageGroup, pageName, data)
})

/**
 *  Tracks errors on the page such as on a form
 *  @param {Object} error - The object containing the props to set
 */
export const trackError = whenReady(options => {
  const fpti = window.fpti || {}

  analyticsDebug(RECORDING_ANALYTICS, options)

  // set error props
  fpti.erpg = options.message // error message
  fpti.erfd = options.fieldId // form field with error (field1|field2|field3)
  fpti.eccd = options.code // error code

  analytics.recordImpression()
})

/**
 * Log client-side JS errors
 * @param error {Error} A valid JS Error (sometimes null watch out)
 * @param source {string} filename/line/col "file.js 23:39"
 */
export const trackJSError = whenReady((error, source) => {
  if (!PAYPAL.analytics) {
    return
  }
  const fpti = window.fpti || {}
  const data = {
    page: fpti.page,
    pgrp: fpti.pgrp,
    comp: fpti.comp,
    erpg: (error && error.message) || 'Script error',
    // uicomp: '',
    // error_code: '',
    error_type: 'WINDOW_ONERROR', // TODO: unique one for promise errors?
    error_description: getShortStack(error),
    error_source: source,
  }
  debug('Logging JS Error')('Logging JS Error', data)

  // see: https://engineering.paypalcorp.com/confluence/display/FPTI/Client-Side+JavaScript+Implementation#Client-SideJavaScriptImplementation-SubmittingClient-sideerrors
  analytics.logActivity(data)
})

/**
 * Log instances of missing content
 * @param missingData {object} e.g. { key: 'transfer/recipient/header.title', url: 'myaccount/transfer/send' }
 */
export const trackMissingContent = whenReady(missingContentData => {
  if (!PAYPAL.analytics) {
    return
  }
  const fpti = window.fpti || {}
  const data = {
    page: fpti.page,
    pgrp: fpti.pgrp,
    comp: fpti.comp,
    erpg: `Missing content for key: ${missingContentData.key}`,
    error_type: 'MISSING_CONTENT',
    error_description: JSON.stringify(missingContentData),
    error_source: missingContentData.url,
  }
  debug('Logging instance of missing content')(
    'Logging instance of missing content',
    data,
  )

  // see: https://engineering.paypalcorp.com/confluence/display/FPTI/Client-Side+JavaScript+Implementation#Client-SideJavaScriptImplementation-SubmittingClient-sideerrors
  analytics.logActivity(data)
})

/**
 *  Tracks link on a page
 *  keeps the pagenames the same, but tracks the link
 *  @param {String} linkName linkName to track
 */
export const trackAction = whenReady(linkName => {
  const fpti = window.fpti || {}
  analyticsDebug(RECORDING_ANALYTICS, linkName)

  // set linkname
  fpti.link = linkName
  fpti.pglk = `${fpti.pgrp}|${linkName}`

  clearErrors(fpti)
  analytics.recordClick()
})

/**
 * Simple back button tracking
 */
export function trackBackButton() {
  trackAction('back')
}

/**************
 * Private API
 **************/

/**
 * Get a reasonably short stack trace so we don't hit issues
 * with going over the limit of chars allowed in an FPTI GET
 */
function getShortStack(err) {
  const stack = err && err.stack

  // Tries to remove the http://www.paypalobjects.com/ stuff from the stack traces.
  return (
    (stack && stack.replace(/http.*\/(\w+.\.js)/g, '$1').slice(0, 500)) || ''
  )
}

// clear out error props, in case they exist
function clearErrors(fpti) {
  delete fpti.erpg // error message
  delete fpti.erfd // form field with error (field1|field2|field3)
  delete fpti.eccd // error code
}

/**
 * Updating name with new environment
 * @param {String} env - new environment set by external flow.
 * @param {String} name - pageGroup or pageName
 */
export function getPageNameForEnvironment(name, env) {
  if (env) {
    const nameArray = name.split(':')
    nameArray[2] = env
    return nameArray.join(':')
  }
  return name
}

/**
 * PA.js has a 500ms delay between its inception and setup initialization, and we need to know when
 * the setup is really complete before firing our analytic calls. PA.js allows us to overwrite their
 * noop setupComplete function with our own function, which we do in pageTracking.dust -
 * Our logic simply specifies that when setupComplete is invoked, set _.isReady to true.
 */
function analyticsReady(callback) {
  const analyticsIsReady = _.get(PAYPAL, 'analytics._isReady')
  if (analyticsIsReady) {
    return callback()
  }
  return setTimeout(() => analyticsReady(callback), 150)
}

/**
 * Wrap any anonymous function in the analyticsReady function
 */
function whenReady(fn) {
  return (...args) => analyticsReady(() => fn(...args))
}

/**************
 * Clientside performance tracking (CPL)
 **************/

// Currently, end tracking is called where getting the page name is not easy
// Page names need to be passed to track impressions. This is a hack grabbing the page name
// when start tracking is called and setting to last lastPageName in order to pass it to endCPLTracking.
export function startCPLTracking(pageGroup, pageName, data = {}) {
  if (_.get(PAYPAL, 'analytics.startCPLTracking')) {
    let options = {}
    const { ulData: { fnSessionId: sessionID = '' } = {} } = PAYPAL
    if (pageName && pageGroup) {
      options = { flid: sessionID, pgrp: pageGroup, page: pageName }
    }
    // creating this key on PAYPAL.analytics to store our beacon info
    // becauase we call endCPLTracking with no arguments
    PAYPAL.analytics.beaconTrackingData = data
    PAYPAL.analytics.startCPLTracking(options)
  }
}

// Sometimes we don't have the data necessary for the next page's impression,
// because startCPLTracking begins at the time of click from a prior page.
export function addCPLData(data) {
  if (_.get(PAYPAL, 'analytics.beaconTrackingData')) {
    PAYPAL.analytics.beaconTrackingData = {
      ...PAYPAL.analytics.beaconTrackingData,
      ...data,
    }
  }
}

// Sets beacon data points directly on fpti - data points will persist until overwritten
export function setPersistentBeaconData(key, value) {
  if (window.fpti) {
    window.fpti[key] = value
  }
}

/**
 *  Tracks links/buttons on a page
 *  @param {String} eventName event name to track
 *  @param {String} [eventType] event type to track im,ac,cl
 *  @param {String} [eventData] additonal custom data as a map
 */
export const tracking = whenReady((data = {}) => {
  analytics.record({
    data: {
      page_title: document.title,
      page_url: window.location.toString(),
      ...data,
    },
  })
})

// TODO: remove comments once CPL tracking is integrated
// export function endCPLTracking(pageGroup, pageName, data = {}) {
//   // End tracking is only called if start tracking has been called. `cpl` and `cpl.started` are
//   // only available after start tracking call.
//   const CPLTrackingHasStarted = _.get(PAYPAL, 'analytics.cpl.started')
//   const endCPLTrackingExists = _.get(PAYPAL, 'analytics.endCPLTracking')
//   if (CPLTrackingHasStarted && endCPLTrackingExists) {
//     const beaconTrackingData = PAYPAL.analytics.beaconTrackingData // our personal beacon storage created in startCPLTracking
//     const beaconData = PAYPAL.analytics.cpl.beaconData || {} // Where the beacons are fired from
//     PAYPAL.analytics.cpl.beaconData = { ...beaconData, ...beaconTrackingData }
//
//     let options = {}
//     const { ulData: { fnSessionId: sessionID = '' } = {} } = PAYPAL
//     if (pageName && pageGroup) {
//       options = {
//         pageData: { flid: sessionID, pgrp: pageGroup, page: pageName },
//       }
//     }
//     PAYPAL.analytics.endCPLTracking(options)
//
//     // We add data to our tracking calls, but CPL persisted the data by dropping it on window.FPTI
//     // We don't want ALL previous tracking data on all future calls, so let's pluck out the data we just used
//     window.fpti = _.pickBy(window.fpti, function(value, key) {
//       let addedBeaconKeys = Object.keys(beaconTrackingData)
//       return !addedBeaconKeys.includes(key)
//     })
//   }
// }
