export const handleAvailabilityAndSuggestions = `
    mutation HandleAvailabilityAndSuggestions($handle: PreferredIdTyped) {
      handleAvailabilityAndSuggestions(handle: $handle) {
        suggestedSlugs
        isPreferredIdAvailable
        userTypedPreferredId
        slugUnavailabilityReasonCode
      }
    }
  `
