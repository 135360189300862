/* eslint-disable i18n-scan/no-inline-concatenation */
// @flow

import React, { Fragment } from 'react'
import { withRouter } from 'react-router'
import { Route, Switch, Redirect } from 'react-router-dom'
import type { RouterHistory } from 'react-router'
import clientData from 'utils/client-data'
import { get } from 'lodash'
import Events from 'lib/analytics/event-tags'

import {
  AnimatedSwitchPagination,
  SimpleLayout,
  SessionTimer,
  MainComponent,
  ProfileDetails,
  SlugProfile,
  Grab,
  Taken,
  Success,
  Picture,
  SettingsContainer,
  ProfileSettings,
  Username,
  TurnOffConfirmation,
  About,
  Location,
  CoverSelect,
  CoverPan,
  LandingPage,
  CreateUserNameForPersonalProfile,
  CreateForExistingUser,
  GrabLandingPage,
} from './async-loading'

import ErrorScreen from './screens/error'
import SellerRoutes from './seller-routes'
import VisaPlusRoutes from './visa-plus-routes'

const overpanelViews = [
  'picture',
  'grab',
  'settings',
  'taken',
  'error',
  'success',
  'location',
  'cover',
  'cover/select',
  'cover/pan',
  'status',
  'confirm',
  'username',
  'about',
  'personalmessage',
  'review',
  'welcome',
  'profile',
  'create',
  'createusername',
  'preference',
  'landing',
  'session-timeout',
]

const sellerRoutes = SellerRoutes()
const visaPlusRoutes = VisaPlusRoutes()

type AppProps = {
  history: RouterHistory,
}

class App extends React.Component<AppProps> {
  componentDidMount() {
    const isUserLoggedIn = get(clientData, 'isUserLoggedIn')
    const intent = get(clientData, 'recipientSlugDetails.slugDetails.intent')
    const shopId = get(clientData, 'recipientSlugDetails.slugDetails.shopId')
    const accountType = get(clientData, 'user.userAccountType') || ''

    const currentPath = this.props.history.location.pathname
    if (intent === 'COMMERCE' || currentPath.split('/')[1] === 'seller') {
      Events.updateCommonProps({
        profile_type: 'seller',
        store_id: shopId,
        acct_type: isUserLoggedIn ? accountType.toLowerCase() : 'guest',
      })
    } else {
      Events.updateCommonProps({
        profile_type: 'personal',
      })
    }
  }

  render() {
    return (
      <div>
        <SessionTimer />

        <Switch>
          {visaPlusRoutes}
          {sellerRoutes}
          {/* ANIMATED ROUTING WITHIN OVERPANEL DESIGN */}
          <Route exact path={`/my/(${overpanelViews.join('|')})`}>
            <SimpleLayout>
              <AnimatedSwitchPagination>
                {/* GRAB */}
                <GrabRoute exact path="/my/grab" component={Grab} />
                <GrabRoute exact path="/my/success" component={Success} />
                <GrabRoute exact path="/my/picture" component={Picture} />
                <GrabRoute exact path="/my/taken" component={Taken} />
                <GrabRoute
                  exact
                  path="/my/landing"
                  component={GrabLandingPage}
                />
                <GrabRoute
                  exact
                  path="/my/create"
                  component={CreateForExistingUser}
                />
                {/* Personal Profile Handle */}
                <GrabRoute
                  exact
                  path="/my/createusername"
                  component={CreateUserNameForPersonalProfile}
                />

                {/* SETTINGS */}
                <SettingsRoute
                  exact
                  path="/my/welcome"
                  component={LandingPage}
                />
                <SettingsRoute
                  exact
                  path="/my/settings"
                  component={ProfileSettings}
                />
                <SettingsRoute
                  exact
                  path="/my/confirm"
                  component={TurnOffConfirmation}
                />

                <SettingsRoute exact path="/my/username" component={Username} />

                <SettingsRoute exact path="/my/about" component={About} />

                <SettingsRoute exact path="/my/location" component={Location} />

                <SettingsRoute
                  exact
                  path="/my/cover/select"
                  component={CoverSelect}
                />

                <SettingsRoute
                  exact
                  path="/my/cover/pan"
                  component={CoverPan}
                />

                <SettingsRoute
                  exact
                  path="/my/profile"
                  component={ProfileDetails}
                />

                {/* ERROR */}
                <Route exact path="/my/error" component={ErrorScreen} />

                {/* UNMATCHED CATCH-ALL ROUTES */}
                <Route path="/my">
                  <Redirect to="/my/profile" />
                </Route>
                <Route path="/grab">
                  <Redirect to="/my/grab" />
                </Route>
              </AnimatedSwitchPagination>
            </SimpleLayout>
          </Route>

          <Route>
            <MainComponent>
              <Switch>
                <Route
                  exact
                  path={'/:slug(\\w{2,20})/:amountInfo?'}
                  component={SlugProfile}
                />
              </Switch>
            </MainComponent>
          </Route>

          <Redirect to="/" />
        </Switch>
      </div>
    )
  }
}

type ReactComponent = () => React.Element | React.Component

function SettingsRoute({
  component: Comp,
  ...props
}: {
  component: ReactComponent,
}) {
  return (
    <Route
      exact
      render={routerProps => (
        <Fragment>
          <SettingsContainer
            {...routerProps}
            body={<Comp {...routerProps} />}
            slugDetails={clientData.slugDetails}
            renderErrorScreen={<ErrorScreen />}
          />
        </Fragment>
      )}
      {...props}
    />
  )
}

function GrabRoute({ component: Comp }: { component: ReactComponent }) {
  return <Route exact render={routerProps => <Comp {...routerProps} />} />
}

export default withRouter(App)
