/*
 * This plugin converts all glamor style objects to right-to-left
 * using rlt-css-js. Should only be used when the user's language
 * direction is rtl.
 */
import rtlConvert from 'rtl-css-js'

export default rtlPlugin

function rtlPlugin({ selector, style }) {
  return { selector, style: rtlConvert(style) }
}
