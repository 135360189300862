import React, { useState, useRef, useEffect } from 'react'
import glamorous from 'glamorous'
import to from 'await-to-js'
import { css } from 'glamor'
import { HeadingText, RadioButton } from '@paypalcorp/pp-react'
import getContent from 'pp-react-l10n'
import { SELLER_SETTING_TYPES as types } from 'utils/constants'
import clientData from 'utils/client-data'
import { addLoader } from 'utils/loader'
import Events from 'lib/analytics/event-tags'
import { tracking } from 'lib/analytics'
import { phone6PlusPortraitMaxAndSmaller } from 'lib/styles/media-queries'
import { showServiceError } from './../../error/util'
import SaveButton from '../../shared/save-button'

const l10n = getContent('pages/seller/settings')

const Container = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
})

const Heading = css({
  marginTop: 80,
  marginBottom: 40,
  [phone6PlusPortraitMaxAndSmaller]: {
    marginTop: 20,
  },
})

const Legend = glamorous.span({
  border: 0,
  clip: 'rect(1px, 1px, 1px, 1px)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  width: '1px',
})

function ProfileStatusSetting(props) {
  const {
    sellerProfileState: {
      state: {
        sellerProfileDetails: {
          id: businessHandle = '',
          status = 'INACTIVE',
        } = {},
      } = {},
    } = {},
    history: {
      location: { state: { fromSellerSettings = false } = {} } = {},
    } = {},
  } = props

  useEffect(() => {
    tracking(Events.public_identity_profile_edit_status_screen_shown({}))
  }, [])

  const profileStatus = status === 'ACTIVE' ? types.SHOW : types.HIDE
  const { turnOnSellerProfile } = props.sellerProfileState
  const [value, setValue] = useState(profileStatus)
  const { current: initialValue } = useRef(value)
  const isChanged = value !== initialValue
  let redirectToAccountOnSave = false
  let redirectToDashboardOnSave = false

  if (
    clientData.flowCancelUrl?.includes('/myaccount/settings') &&
    !fromSellerSettings
  ) {
    // Flow: Turn on profile. Entry point: /myaccount/settings
    redirectToAccountOnSave = true
  } else if (
    clientData.flowCancelUrl?.includes(
      '/myaccount/transfer/homepage/request',
    ) &&
    !fromSellerSettings
  ) {
    // Flow: Share seller profile after turning on profile. Entry point: p2p request page sidebar
    redirectToDashboardOnSave = true
  }

  const handleChangeInput = e => {
    setValue(e.target.value)
  }

  const handleSave = async () => {
    tracking(Events.public_identity_profile_edit_status_save_pressed({}))
    if (value === types.SHOW) {
      // activate profile
      const [err] = await to(turnOnSellerProfile(businessHandle))
      if (err) {
        showServiceError(
          err,
          props.history,
          'seller-profile-settings-edit-status',
        )
      } else {
        if (redirectToAccountOnSave) {
          addLoader()
          window.location.href = clientData.flowReturnUrl
        } else if (redirectToDashboardOnSave) {
          props.history.replace(`/seller/${businessHandle}/dashboard`)
        } else {
          props.history.goBack()
        }
      }
    } else {
      // redirect to confirm before deactivating profile
      props.history.push(`/seller/${businessHandle}/settings/profile/confirm`)
    }
  }

  const options = [
    {
      label: l10n('edit.status.option.on'),
      value: types.SHOW,
      helperText: l10n('edit.status.option.onText'),
    },
    {
      label: l10n('edit.status.option.off'),
      value: types.HIDE,
      helperText: l10n('edit.status.option.offText'),
    },
  ]

  return (
    <Container data-test-id="seller-settings-status-screen">
      <HeadingText
        size="md"
        role="heading"
        aria-level="1"
        className={`${Heading}`}
      >
        {l10n('edit.status.header')}
      </HeadingText>
      <RadioButton
        name="profileStatus"
        label={<Legend>{l10n('edit.status.header')}</Legend>}
        value={value}
        options={options}
        onChange={handleChangeInput}
        data-test-id="seller-settings-status-input"
      />
      {isChanged && <SaveButton handleSave={handleSave} />}
    </Container>
  )
}

export default ProfileStatusSetting
