import React, { useState, useRef, useCallback, useEffect } from 'react'
import glamorous from 'glamorous'
// import clientData from 'utils/client-data'
import { isEqual, debounce } from 'lodash'
import { css } from 'glamor'
import { HeadingText, BodyText, TextInput } from '@paypalcorp/pp-react'
import getContent from 'pp-react-l10n'
import Events from 'lib/analytics/event-tags'
import { tracking } from 'lib/analytics'
import { phone6PlusPortraitMaxAndSmaller } from 'lib/styles/media-queries'
import { showServiceError } from './../error/util'
import SaveButton from '../shared/save-button'
import { normalizeFacebookURL } from '../../shared/socialUtils'
import { SELLER_SOCIAL, SELLER_REGEX } from '../../../utils/constants'

const l10n = getContent('pages/seller/settings')
const l10nSocial = getContent('components/social_media_link')

const Container = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
})

const Heading = css({
  marginTop: 80,
  [phone6PlusPortraitMaxAndSmaller]: {
    marginTop: 20,
  },
})

const SubHeading = css({
  textAlign: 'left',
  margin: '16px 0 20px',
})

const FormInput = css({
  marginTop: 24,
})

const initializeSocialsData = (instagramValue, facebookValue, twitterValue) => {
  return {
    instagram: {
      ariaLabel: 'instagramRadio',
      ariaLabelDone: 'instagramRadioDone',
      label: 'instagramInputLabel',
      validation: SELLER_REGEX.instagram,
      error: 'userNameInputInputError',
      showError: false,
      visibleInput: false,
      doneState: false,
      path: '/user_info/instagram_username',
      value: instagramValue ? instagramValue : '',
    },
    facebook: {
      ariaLabel: 'facebookRadio',
      ariaLabelDone: 'facebookRadioDone',
      label: 'facebookInputLabel',
      validation: SELLER_REGEX.facebook,
      error: 'fbInputInputError',
      showError: false,
      visibleInput: false,
      doneState: false,
      path: '/user_info/facebook_url',
      value: facebookValue ? facebookValue : '',
    },
    twitter: {
      ariaLabel: 'twitterRadio',
      ariaLabelDone: 'twitterRadioDone',
      label: 'twitterInputLabel',
      validation: SELLER_REGEX.twitter,
      error: 'userNameInputInputError',
      showError: false,
      visibleInput: false,
      doneState: false,
      path: '/user_info/twitter_handle',
      value: twitterValue ? twitterValue : '',
    },
  }
}

const initialValidation = socialsData => {
  Object.keys(socialsData).forEach(socialName => {
    const value = socialsData[socialName].value
    if (value !== '') {
      const socialValidationTest = socialsData[socialName].validation.test(
        value,
      )
      // Additional Validation for facebook for character length limit
      const faceboobMaxCharTest =
        socialName === 'facebook' &&
        value.length > SELLER_SOCIAL.facebook.MAX_CHAR_LIMIT
      if (!socialValidationTest || faceboobMaxCharTest) {
        socialsData[socialName] = {
          ...socialsData[socialName],
          showError: true,
          doneState: false,
        }
      } else {
        socialsData[socialName] = {
          ...socialsData[socialName],
          showError: false,
          doneState: true,
        }
      }
    } else {
      socialsData[socialName] = {
        ...socialsData[socialName],
        showError: false,
        doneState: false,
      }
    }
  })
  if (checkFormValid(socialsData)) {
    return true
  }
  return false
}

// Check if form is valid
const checkFormValid = data => {
  const validatedButtons = []
  const errorButtons = []
  Object.keys(data).forEach(socialName => {
    validatedButtons.push(data[socialName].doneState)
    errorButtons.push(data[socialName].showError)
  })
  if (validatedButtons.includes(true) && !errorButtons.includes(true)) {
    return true
  }
  return false
}

function SocialSetting(props) {
  const {
    sellerProfileState: {
      state: {
        sellerProfileDetails: {
          id: businessHandle = '',
          user_info: {
            instagram_username: instagramValue = '',
            facebook_url: facebookValue = '',
            twitter_handle: twitterValue = '',
          } = {},
        } = {},
      } = {},
    } = {},
  } = props

  useEffect(() => {
    tracking(Events.public_identity_profile_edit_social_screen_shown({}))
  }, [])

  const [socialsData, setSocialsData] = useState(
    initializeSocialsData(instagramValue, facebookValue, twitterValue),
  )
  const [isFormValid, setIsFormValid] = useState(initialValidation(socialsData))
  const { editSellerSettings } = props.sellerProfileState

  const { current: initialValues } = useRef(socialsData)
  const [isChanged, setIsChanged] = useState(
    !isEqual(initialValues, socialsData),
  )
  const delayedValidation = useCallback(
    debounce((fn, ...args) => fn(...args), 1000),
    [],
  )

  useEffect(() => {
    setIsFormValid(checkFormValid(socialsData))
    setIsChanged(!isEqual(initialValues, socialsData))
  }, [socialsData])

  const handleInputTyping = async e => {
    e.persist()
    const inputValue = e.target.value
    const socialName = e.target.id.substr(6)
    setSocialsData(prevState => ({
      ...prevState,
      [socialName]: {
        ...prevState[socialName],
        value: inputValue,
      },
    }))
    // on tab out validate right away
    if (e.type === 'blur') {
      validateInput(e)
    } else {
      // when typing validate with a delay
      await delayedValidation(validateInput, e)
    }
  }

  /**
   * This helper method validates input field values
   * @param {Event} e - change event object
   */
  const validateInput = e => {
    const inputValue = e.target.value
    const socialName = e.target.id.substr(6)
    // if validation doesn't pass show the error text
    if (
      inputValue !== '' &&
      !socialsData[socialName].validation.test(inputValue)
    ) {
      setSocialsData(prevState => ({
        ...prevState,
        [socialName]: {
          ...prevState[socialName],
          showError: true,
          doneState: false,
        },
      }))
    } else if (inputValue !== '') {
      setSocialsData(prevState => ({
        ...prevState,
        [socialName]: {
          ...prevState[socialName],
          showError: false,
          doneState: true,
        },
      }))
    } else {
      setSocialsData(prevState => ({
        ...prevState,
        [socialName]: {
          ...prevState[socialName],
          showError: false,
          doneState: false,
        },
      }))
    }
  }

  const handleSave = async () => {
    const valueObj = []
    Object.keys(socialsData).forEach(socialName => {
      const field = socialsData[socialName]

      if (field.value !== initialValues[socialName].value) {
        let fieldValue = field.value
        if (socialName === 'facebook') {
          // Normalize the facebook URL to standard format
          fieldValue = normalizeFacebookURL(field.value)
        }
        valueObj.push({
          op: 'replace',
          path: field.path,
          value: fieldValue,
        })
        tracking(
          Events.public_identity_profile_edit_social_save_pressed({
            social_platform: socialName,
          }),
        )
      }
    })

    try {
      await editSellerSettings(valueObj, businessHandle)
      props.history.goBack()
    } catch (err) {
      showServiceError(
        err,
        props.history,
        'seller-profile-settings-edit-social',
      )
    }
  }

  return (
    <Container data-test-id="seller-settings-social-screen">
      <HeadingText
        size="md"
        role="heading"
        aria-level="1"
        className={`${Heading}`}
      >
        {l10n('socialMedia')}
      </HeadingText>
      <BodyText className={`${SubHeading}`}>
        {l10nSocial('headerText')}
      </BodyText>
      {Object.keys(socialsData).map(socialName => (
        <TextInput
          key={socialName}
          maxLength="350"
          id={`input-${socialName}`}
          value={socialsData[socialName].value}
          onChange={handleInputTyping}
          onBlur={handleInputTyping}
          className={`${FormInput}`}
          label={l10nSocial(socialsData[socialName].label)}
          errorText={
            socialsData[socialName].showError &&
            l10nSocial(socialsData[socialName].error)
          }
          data-test-id={`seller-settings-${socialName}-input`}
        />
      ))}
      {isChanged && isFormValid && <SaveButton handleSave={handleSave} />}
    </Container>
  )
}

export default SocialSetting
