export const editSellerProfileSettings = `
  mutation EditSellerSettings ($settings: [PatchOptions]!, $slugName: String!) {
    editSellerProfileSettings (editSlugDetails: $settings, slug: $slugName) {
      isProfileStatusActive
      userInfo {
        alternateFullName
        displayAddress
        displayEmail
        displayMobilePhone
        website
        instagramUsername
        facebookUrl
        twitterHandle
        profilePhotoUrl
      }
      privacySettings {
        emailSearchEnabled
        phoneSearchEnabled
        displayEmailEnabled
        displayMobilePhoneEnabled
        location
      }
      personalizationSettings {
        description
        coverPhotoUrl
        coverPhotoVerticalPan
      }
    }
  }
`
