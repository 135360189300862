import React from 'react'
import { Redirect } from 'react-router-dom'

import { UI_ERROR_STATES } from './error/ui-error-states'

function WithExperienceCheck(Component) {
  const UI_ERROR = UI_ERROR_STATES

  return function WithExperienceCheckComponent({
    showExperience,
    errorType,
    ...props
  }) {
    const errObj = UI_ERROR[`${errorType}`]

    if (showExperience) {
      return <Component {...props} />
    }
    return <Redirect to={errObj} />
  }
}

export { WithExperienceCheck }
