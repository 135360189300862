import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { Button } from '@paypalcorp/pp-react'
import getContent from 'pp-react-l10n'
import { phone6PlusPortraitMaxAndSmaller } from 'lib/styles/media-queries'

const l10n = getContent('pages/seller/settings')

const SaveBtn = css({
  textAlign: 'center',
  width: 300,
  marginTop: 100,
  alignSelf: 'center',
  [phone6PlusPortraitMaxAndSmaller]: {
    marginTop: 140,
    width: 280,
  },
  '@media(max-width: 374px)': {
    marginTop: 100,
    width: 265,
  },
  '@media(max-width: 325px)': {
    marginTop: 60,
    width: 250,
  },
})

SaveButton.defaultProps = {
  handleSave: () => {},
}

SaveButton.propTypes = {
  handleSave: PropTypes.func.isRequired,
}

function SaveButton(props) {
  return (
    <Button
      className={`${SaveBtn}`}
      onClick={props.handleSave}
      data-test-id="seller-settings-save-button"
    >
      {l10n('saveButton')}
    </Button>
  )
}

export default SaveButton
