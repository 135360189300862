// @flow

import React from 'react'
import { css } from 'glamor'
import glamorous from 'glamorous'
import { Icon, grey700, blue100, V2CameraIcon } from '@paypalcorp/pp-react'
import getContent from 'pp-react-l10n'

import { getDisplayCoverPhotoUrl } from 'utils/image-utils'

const l10n = getContent('pages/profile')

const Button = glamorous.button({
  height: '7.875rem',
  width: '15.375rem',
  background: 'none',
  color: 'inherit',
  border: 'none',
  padding: '0',
  font: 'inherit',
  cursor: 'pointer',
  outline: 'inherit',
  boxShadow: '0 3px 6px 0 rgba(0,0,0,0.15)',
  borderRadius: '0.75rem',
  '&:focus': {
    outline: '1px auto rgb(0, 95, 204)',
  },
})

const Photo = glamorous.div(({ photoUrl }) => ({
  height: '100%',
  width: '100%',
  borderRadius: 'inherit',
  background: `url(${photoUrl})`,
  backgroundSize: 'cover',
  backgroundPositionX: '50%',
  backgroundPositionY: '0%',
  backgroundRepeat: 'no-repeat',
}))

const Container = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  backgroundColor: blue100,
  borderRadius: 'inherit',
})

const Camera = css({ color: grey700, marginBottom: '0.15rem' })

const Text = glamorous.div({ color: grey700 })

type Props = {
  photoUrl?: string,
  photoLabel?: string,
  onSelect: string,
}

function CoverOption(props: Props) {
  const { photoUrl, photoLabel, isSeller = false } = props

  const onSelect = () => {
    props.onSelect(photoUrl)
  }

  return (
    <Button onClick={onSelect}>
      {photoUrl ? (
        <Photo
          photoUrl={getDisplayCoverPhotoUrl(photoUrl)}
          role="img"
          aria-label={photoLabel}
        />
      ) : (
        <Container>
          {!isSeller ? (
            <Icon name="camera" size="xl" className={`${Camera}`} />
          ) : (
            <V2CameraIcon size="xl" />
          )}
          <Text>{l10n('cover.upload')}</Text>
        </Container>
      )}
    </Button>
  )
}

export default CoverOption
