// @flow

import React, { useState, useEffect } from 'react'
import Hammer from 'react-hammerjs'
import { css } from 'glamor'
import glamorous from 'glamorous'
import {
  phoneLandscapeMaxAndSmaller,
  phoneLandscapeMinAndSmaller,
  phone6PlusPortraitMaxAndSmaller,
  tabletLandscapeMaxAndSmaller,
} from 'lib/styles/media-queries'

import getContent from 'pp-react-l10n'
import { Icon, grey100, V2CameraIcon } from '@paypalcorp/pp-react'

import { getDisplayCoverPhotoUrl } from 'utils/image-utils'

const l10n = getContent('pages/seller/photos')

export const CONTAINER_HEIGHT_PX = '210'
export const CONTAINER_WIDTH_PX = '494'

const ContainerBase = {
  position: 'absolute',
  width: '143.5%',
  left: '-21.65%',

  [tabletLandscapeMaxAndSmaller]: {
    left: '-20.65%',
    width: '141.5%',
  },
  [phoneLandscapeMaxAndSmaller]: {
    left: '-13.75%',
    width: '128%',
  },
  [phoneLandscapeMinAndSmaller]: {
    left: '-4.5%',
    width: '109%',
  },
  [phone6PlusPortraitMaxAndSmaller]: {
    left: 0,
    width: '100%',
  },
}

const Container = glamorous.div({
  ...ContainerBase,
})

const DraggableContainer = css({
  ...ContainerBase,
  cursor: 'move',
  cursor: 'grab', // eslint-disable-line no-dupe-keys
  '&:active': {
    cursor: 'grabbing',
  },
})

const FadeIn = css.keyframes({ from: { opacity: 0 }, to: { opacity: 1 } })
const Photo = glamorous.div(
  ({ photoUrl, photoVerticalPan, fadeIn = true }) => ({
    height: `${CONTAINER_HEIGHT_PX}`,
    width: '100%',
    borderRadius: '20px 20px 0px 0px',
    background: `url(${photoUrl})`,
    backgroundSize: 'cover',
    backgroundPositionX: '50%',
    backgroundPositionY: `${photoVerticalPan}%`,
    backgroundRepeat: 'no-repeat',
    ...(fadeIn && { animation: `${FadeIn} 0.5s` }),
    // media
    [phone6PlusPortraitMaxAndSmaller]: {
      height: '160px',
    },
  }),
)

const EditContainer = glamorous.div({
  '@media (max-width: 30.875em)': {
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    textAlign: 'center',
    top: 0,
  },
})

const EditButton = glamorous.button({
  position: 'absolute',
  bottom: '10px',
  right: '10px',
  border: 'none',
  padding: '0.15rem',
  cursor: 'pointer',
  outline: 'inherit',
  color: grey100,
  background: 'rgba(0, 0, 0, 0.3)',
  backgroundColor: '#222324', // @ppui-v2-color-illustration-black 2.0 styles
  borderRadius: '50%',
  margin: '0 0.5rem 0.5rem 0',
  '&:focus': {
    outline: '1px auto rgb(0, 95, 204)',
  },
  opacity: 0.8,
  height: '40px',
  width: '40px',
})

// hide text visually without hiding it from assistive technology
const EditText = glamorous.div({
  border: '0',
  clip: 'rect(0 0 0 0)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: '0',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px',
})

const DragButton = glamorous.div({
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translateX(-50%)',
  padding: '0 1.75rem',
  color: 'white',
  background: 'rgba(0, 0, 0, 0.7)',
  borderRadius: '99999rem',
  display: 'flex',
  alignItems: 'center',
  [phone6PlusPortraitMaxAndSmaller]: {
    padding: '0 1rem',
  },
})

const ChevronContainer = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '0.5rem',
})

const ChevronUpIcon = css({
  marginBottom: '-0.75rem',
})

const DragText = glamorous.span({
  textAlign: 'center',
  fontWeight: '500',
  fontSize: '0.8125rem',
})

type Props = {
  photoUrl: string,
  photoVerticalPan: string,
  isEditable?: boolean,
  onEdit?: () => any,
  isDraggable?: boolean,
  onDrag?: () => any,
  onDragEnd?: () => any,
  onDragCancel?: () => any,
}

function CoverPhoto(props: Props) {
  const {
    photoUrl,
    photoVerticalPan,
    isEditable = false,
    onEdit,
    isDraggable = false,
    onDrag = () => {},
    onDragEnd = () => {},
    onDragCancel = () => {},
  } = props

  const [isLoaded, setIsLoaded] = useState(false)
  const [isPhotoCached, setIsPhotoCached] = useState(false)

  useEffect(() => {
    const photoImage = new Image()
    photoImage.onload = () => {
      setIsLoaded(true)
    }
    photoImage.onerror = () => {
      // image failed to load so do nothing
    }
    photoImage.src = photoUrl
    if (photoImage.complete) {
      setIsPhotoCached(true)
    }

    return () => {
      photoImage.onload = () => {}
    }
  }, [])

  return (
    <WithDrag
      isDraggable={isDraggable}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
    >
      {isLoaded && (
        <>
          <Photo
            photoUrl={getDisplayCoverPhotoUrl(photoUrl)}
            photoVerticalPan={photoVerticalPan}
            role="img"
            aria-label={l10n('a11y.cover.alt')}
            fadeIn={!isPhotoCached}
          />
          {isEditable && (
            <EditContainer>
              <EditButton onClick={onEdit} data-test-id="edit-cover-photo">
                <V2CameraIcon size="sm" />
                <EditText>{l10n('a11y.cover.camera')}</EditText>
              </EditButton>
            </EditContainer>
          )}
          {isDraggable && (
            <DragButton>
              <ChevronContainer aria-hidden="true">
                <Icon name="chevron-up" className={`${ChevronUpIcon}`} />
                <Icon name="chevron-down" />
              </ChevronContainer>
              <DragText>{l10n('cover.drag')}</DragText>
            </DragButton>
          )}
        </>
      )}
    </WithDrag>
  )
}

function WithDrag({
  isDraggable = false,
  onDrag,
  onDragEnd,
  onDragCancel,
  children,
}) {
  return isDraggable ? (
    <Hammer
      onPan={onDrag}
      onPanEnd={onDragEnd}
      onPanCancel={onDragCancel}
      options={{ recognizers: { pan: { threshold: 0 } } }}
    >
      <div className={`${DraggableContainer}`}>{children}</div>
    </Hammer>
  ) : (
    <Container>{children}</Container>
  )
}

export default CoverPhoto
