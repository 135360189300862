// @flow

// libs
import React, { useEffect } from 'react'
import { Button, HeadingText, BodyText } from '@paypalcorp/pp-react'

import { css } from 'glamor'
import glamorous from 'glamorous'

import getContent from 'pp-react-l10n'
import Events from 'lib/analytics/event-tags'
import { tracking } from 'lib/analytics'
const l10n = getContent('pages/seller/error')

// custom error pages
import ErrorCreateHandle from './error/create-handle'

const illustrationsUrl = {
  warning:
    'https://www.paypalobjects.com/paypal-ui/illustrations/focused/svg/empty-warning.svg',
  critical:
    'https://www.paypalobjects.com/paypal-ui/illustrations/focused/svg/empty-critical.svg',
}

const Container = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const HeadingContainer = css({
  marginTop: '36px',
  textAlign: 'center',
  fontSize: '1.75rem',
})

const BodyTextContainer = glamorous.div({
  margin: '36px 16px',
})

const ButtonContainer = glamorous.div({
  marginTop: '36px',
})

const ErrorScreen = props => {
  const {
    location: {
      state: {
        error: {
          headerText,
          headerKey = 'generic',
          headerSize = 'lg',
          subHeaderText,
          subHeaderKey = 'generic',
          subHeaderSize = 'sm',
          paragraphs = [],
          ctaButtonText,
          ctaButtonKey = 'generic',
          ctaButtonClick: ctaClick,
          customCompName,
          illustrationKey = 'critical',
          instrumentationObj: {
            error: { fromScreen, name, message } = {},
          } = {},
        } = {},
      } = {},
      actionHandlers: { ctaButtonClick, trackErrorImpression } = {},
    } = {},
  } = props || props.history

  useEffect(() => {
    if (trackErrorImpression) {
      trackErrorImpression()
    } else {
      tracking(
        Events.public_identity_failed_customer_interaction_occurred({
          screen: fromScreen,
          error_description: message,
          error_code: name,
        }),
      )
    }
  }, [])

  const renderHeader = () => {
    return (
      <HeadingText
        role="heading"
        aria-level="1"
        size={headerSize}
        className={`${HeadingContainer}`}
      >
        {headerText || l10n(`error.header.${headerKey}`)}
      </HeadingText>
    )
  }

  const renderSubHeader = () => {
    return (
      (subHeaderText || subHeaderKey) && (
        <HeadingText
          role="heading"
          aria-level="2"
          size={subHeaderSize}
          className={`${HeadingContainer}`}
        >
          {subHeaderText || l10n(`error.subHeader.${subHeaderKey}`)}
        </HeadingText>
      )
    )
  }

  const renderBodyText = () => {
    return (
      <BodyTextContainer>
        {paragraphs.map(line => {
          return <BodyText key>{line}</BodyText>
        })}
      </BodyTextContainer>
    )
  }

  const renderCTA = () => {
    return (
      <ButtonContainer>
        <Button
          onClick={
            ctaButtonClick
              ? () => {
                  ctaButtonClick(props)
                }
              : ctaClick
              ? ctaClick
              : buttonAction
          }
        >
          {ctaButtonText || l10n(`error.button.${ctaButtonKey}`)}
        </Button>
      </ButtonContainer>
    )
  }

  const buttonAction = () => {
    tracking(
      Events.public_identity_failed_customer_interaction_retry_pressed({
        screen: fromScreen,
        error_code: name,
        error_description: message,
      }),
    )
    return props.history.goBack()
  }

  const renderGenericView = () => {
    return (
      <>
        {renderHeader()}
        {renderSubHeader()}
        {renderBodyText()}
        {renderCTA()}
      </>
    )
  }

  const hasCustomComp = !!customCompName

  const renderCustomComp = () => {
    if (customCompName === 'ErrorCreateHandle') {
      return <ErrorCreateHandle {...props} />
    }
    return null
  }

  return (
    <Container>
      <img
        src={illustrationsUrl[illustrationKey]}
        alt=""
        style={{
          width: '120px',
        }}
      />
      {!hasCustomComp ? renderGenericView() : renderCustomComp()}
    </Container>
  )
}

export default ErrorScreen
