import { init as initL10n } from 'pp-react-l10n'

function init() {
  const contentNode = document.getElementById('l10n-content')
  initL10n({ contentNode })
  // one reason we're removing this is so cypress doesn't capture
  // the script tag when searching for text on the page.
  contentNode.parentElement.removeChild(contentNode)
}

export { init }
