// @flow

// libs
import React, { useEffect } from 'react'
import getContent from 'pp-react-l10n'
import get from 'lodash/get'
import { withRouter } from 'react-router-dom'
import glamorous from 'glamorous'
import type { RouterHistory } from 'react-router'
import { IconInfoLarge } from 'components'
import { Button, Link, orange500 } from '@paypalcorp/pp-react'

import Events from 'lib/analytics/event-tags'
import { trackError, tracking } from 'lib/analytics'

const l10n = getContent('pages/error')

const Container = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const SubHeader = glamorous.p({
  fontSize: 15,
  color: '#2c2e2f',
  textAlign: 'center',
  width: 300,
})

const Header = glamorous.div({
  fontSize: '1.875rem',
  fontWeight: '300',
  color: '#2c2e2f',
  textAlign: 'center',
})

const ButtonContainer = glamorous.div({
  marginTop: '2.25rem',
})

const HISTORY_LOCATION_STATE_ERROR = 'history.location.state.error'
const HISTORY_LOCATION_STATE_CODE = 'history.location.state.code'
const NOT_ACCEPTABLE = '406'

type Props = {
  history: RouterHistory,
  slug?: string,
  error?: string,
}

function ErrorScreen(props: Props) {
  useEffect(() => {
    const message =
      props.error || get(props, HISTORY_LOCATION_STATE_ERROR, 'generic')
    const code = get(props, 'history.location.state.code', '500')
    trackError({
      message,
      code,
    })
    tracking(
      Events.public_identity_failed_customer_interaction_occurred({
        error_description: message,
        error_code: code,
        screen: '/my/settings',
      }),
    )
  }, [])

  const errorKey =
    props.error || get(props, HISTORY_LOCATION_STATE_ERROR, 'generic')

  const errorTitle =
    props.error || get(props, HISTORY_LOCATION_STATE_ERROR, 'header')

  const errorCode = props.error || get(props, HISTORY_LOCATION_STATE_CODE)

  const getButtonText = () => {
    const error = get(props, HISTORY_LOCATION_STATE_ERROR)

    if (error) {
      if (error === 'creation') {
        return l10n('error.button.try')
      }

      return l10n(`error.button.${error}`)
    }

    return l10n('error.button.try')
  }

  const buttonAction = () => {
    return props.history.goBack()
  }

  if (errorCode === NOT_ACCEPTABLE) {
    return (
      <Container>
        <img
          alt=""
          width={137}
          src={
            'https://www.paypalobjects.com/paypal-ui/pictograms/grayscale/svg/grayscale-1.svg'
          }
        />
        <Header role="heading" aria-level="1" css={{ marginTop: '2.25rem' }}>
          {l10n('error.header.restricted')}
        </Header>
        <SubHeader css={{ marginTop: '2.25rem' }}>
          {l10n('error.header.email')}
        </SubHeader>
        <ButtonContainer>
          <Link href="/myaccount/summary">{l10n('error.link.homePage')}</Link>
        </ButtonContainer>
      </Container>
    )
  }

  return (
    <Container>
      <IconInfoLarge aria-hidden="true" color={orange500} />
      <Header role="heading" aria-level="1" css={{ marginTop: '2.25rem' }}>
        {l10n(`error.title.${errorTitle}`)}
      </Header>
      <SubHeader css={{ marginTop: '2.25rem' }}>
        {l10n(`error.subheader.${errorKey}`)}
      </SubHeader>
      <ButtonContainer>
        <Button onClick={buttonAction}>{getButtonText()}</Button>
      </ButtonContainer>
    </Container>
  )
}

export default withRouter(ErrorScreen)
