import React, { useState, useEffect } from 'react'
import clientData from 'utils/client-data'
import glamorous from 'glamorous'
import { get } from 'lodash'
import { css } from 'glamor'
import {
  HeadingText,
  BodyText,
  Button,
  Link,
  v2grey600,
} from '@paypalcorp/pp-react'
import getContent from 'pp-react-l10n'
import Events from 'lib/analytics/event-tags'
import { tracking } from 'lib/analytics'
import { phone6PlusPortraitMaxAndSmaller } from 'lib/styles/media-queries'
import ShareProfilePopup from '../shared/components/share-profile-popup'
const l10n = getContent('pages/seller/success')

const Container = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '10px',
  [phone6PlusPortraitMaxAndSmaller]: {
    margin: 0,
  },
})

const Heading = css({
  marginTop: 180,
  textAlign: 'center',
  width: '100%',
  [phone6PlusPortraitMaxAndSmaller]: {
    marginTop: 120,
    width: '90%',
  },
  '@media(max-width: 374px)': {
    marginTop: 60,
  },
  '@media(max-width: 325px)': {
    marginTop: 10,
  },
})

const SubHeading = css({
  marginTop: 16,
  width: 380,
  color: v2grey600,
  textAlign: 'center',
  [phone6PlusPortraitMaxAndSmaller]: {
    width: 274,
  },
})

const DoneBtn = css({
  textAlign: 'center',
  width: 300,
  marginTop: 200,
  [phone6PlusPortraitMaxAndSmaller]: {
    marginTop: 140,
    width: 280,
  },
  '@media(max-width: 374px)': {
    marginTop: 100,
    width: 265,
  },
  '@media(max-width: 325px)': {
    marginTop: 60,
    width: 250,
  },
})

const ShareLink = css({
  marginTop: 40,
  [phone6PlusPortraitMaxAndSmaller]: {
    marginTop: 24,
  },
})

function Success(props) {
  const {
    history: {
      location: { state: { businessName = '', businessHandle = '' } = {} } = {},
    } = {},
    sellerProfileState: { updateBusinessDetails = () => {} } = {},
  } = props
  const [displaySharePopup, setdisplaySharePopup] = useState(false)

  useEffect(() => {
    tracking(Events.public_identity_profile_success_screen_shown({}))
    updateBusinessDetails({ sellerProfileCreated: true })
  }, [])

  const trackClick = () => {
    tracking(Events.public_identity_profile_success_done_pressed({}))
  }

  const handleDisplay = value => {
    if (value) {
      tracking(Events.public_identity_profile_success_share_pressed({}))
    }
    setdisplaySharePopup(value)
  }

  const copyLink = `https://paypal.me/${businessHandle}?country.x=${get(
    clientData,
    'locality.country',
  )}&locale.x=${get(clientData, 'locality.locale')}`

  return (
    <Container data-test-id="seller-success">
      <HeadingText
        size="md"
        role="heading"
        aria-level="1"
        className={`${Heading}`}
      >
        {l10n('header', { business: businessName })}
      </HeadingText>
      <BodyText className={`${SubHeading}`}>{l10n('subheader')}</BodyText>
      <Link
        href={`/paypalme/seller/${businessHandle}/dashboard`}
        data-test-id="seller-success-done-link"
      >
        <Button
          className={`${DoneBtn}`}
          onClick={trackClick}
          data-test-id="seller-success-done-btn"
        >
          {l10n('doneButton')}
        </Button>
      </Link>
      <Link
        title="Primary"
        className={`${ShareLink}`}
        onClick={() => handleDisplay(true)}
        data-test-id="seller-success-share-popup"
      >
        {l10n('shareYourProfile')}
      </Link>
      <ShareProfilePopup
        isOpen={displaySharePopup}
        handleDisplay={handleDisplay}
        header={l10n('popup.header')}
        mobileHeader={l10n('popup.mobileHeader')}
        subheader={l10n('popup.subheader')}
        copyLink={copyLink}
        sellerName={businessName}
      />
    </Container>
  )
}

export default Success
