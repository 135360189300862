// @flow
/**
 * Grabs the data from client-data and populates the relevant information
 * for Amplitude instrumentation
 */

import debug from 'debug'
import clientData from './client-data'

const userAttributes: UserAttributes = {}

function init() {
  // user atrributes for Amplitude
  const { user = {}, appVersion } = clientData
  const sessionID = clientData.sessionID || ''
  const deviceInfo = clientData.deviceInfo || {}
  const data = {
    comp: 'ppmenodeweb', // app_name
    app_version: appVersion, // app_build_number
    channel: clientData.isMobile ? 'mweb' : 'web',
    acnt:
      typeof user.userAccountType === 'string'
        ? user.userAccountType.toLowerCase()
        : 'unknown', // account_type
    user_country: user.userCountry,
    session_id: sessionID,
    encr_cust_id: user.customerId, // encr_customer_id
    browser_type: deviceInfo.browserName || 'Generic',
    browser_version: deviceInfo.browserVersion || '0',
    operating_system: deviceInfo.osName || 'Generic',
    operating_system_version: deviceInfo.osVersion || '0',
    utmTags: clientData.utmTags,
    partnerTags: clientData.partnerTags,
  }

  try {
    Object.assign(userAttributes, data)
    debug('ppme:userAttributes:success')('load', userAttributes)
  } catch (error) {
    debug('ppme:userAttributes:error')('load', error)
  }
}

export default userAttributes
export { init }
