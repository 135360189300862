// @flow
import getContent from 'pp-react-l10n'
import clientData from './client-data'

const {
  locality: { country = 'US' },
} = clientData

export function getLoginUrl({ returnUri } = {}) {
  const host = getDomain(clientData)
  let url = `${host}/signin`
  if (returnUri) {
    url += `?returnUri=${encodeURIComponent(returnUri)}`
  }
  return url
}

function generateUrlLoggedInState(flow = 'homepage', amount, currencyCode) {
  const host = getDomain(clientData)
  let url = `${host}/myaccount/transfer/${flow}/external/profile?flowContextData=${clientData.encryptedFlowData}`
  if (amount && currencyCode) {
    url += `&amount=${encodeURIComponent(
      amount,
    )}&currencyCode=${encodeURIComponent(currencyCode)}`
  }
  return url
}
function generateUrlLoggedOutState(
  flow = 'homepage',
  slugDetails = {},
  amount,
  currencyCode,
) {
  const encryptedFlow = clientData.encryptedFlowData
  const host = getDomain(clientData)

  const {
    locality: { locale, country },
  } = clientData
  const { userInfo: { displayName = '' } = {} } = slugDetails
  const l10n = getContent('pages/shared')
  let returnUri = `${host}/myaccount/transfer/${flow}/external/profile?flowContextData=${encryptedFlow}`
  if (amount && currencyCode) {
    returnUri += `&amount=${encodeURIComponent(
      amount,
    )}&currencyCode=${encodeURIComponent(currencyCode)}`
  }
  const onboardDataRegularSignUp = JSON.stringify({
    'country.x': country,
    'locale.x': locale,
    intent: 'paypalme',
    redirect_url: encodeURIComponent(returnUri),
    sendMoneyText: encodeURIComponent(`${l10n('msg.send')} ${displayName}`),
  })
  const onboardData = `onboardData=${encodeURIComponent(
    onboardDataRegularSignUp,
  )}`

  return `${host}/signin?returnUri=${encodeURIComponent(
    returnUri,
  )}&${onboardData}`
}

export function getSendUrl({
  isLoggedIn = false,
  slugDetails = {},
  amount,
  currencyCode,
}) {
  if (isLoggedIn) {
    return generateUrlLoggedInState('homepage', amount, currencyCode)
  } else {
    return generateUrlLoggedOutState(
      'homepage',
      slugDetails,
      amount,
      currencyCode,
    )
  }
}

export function generatePpshopsUrl(isGuest, flowContextData) {
  const host = getDomain(clientData)
  return isGuest
    ? `${host}/myaccount/transfer/guestLogin/sendMoney/shops?flowContextData=${flowContextData}`
    : `${host}/myaccount/transfer/homepage/external/shops?flowContextData=${flowContextData}`
}

export function getRequestUrl({
  isLoggedIn = false,
  slugDetails = {},
  amount,
  currencyCode,
}) {
  if (isLoggedIn) {
    return generateUrlLoggedInState('request', amount, currencyCode)
  } else {
    return generateUrlLoggedOutState(
      'request',
      slugDetails,
      amount,
      currencyCode,
    )
  }
}

export function getReportLink() {
  const host = getDomain(clientData)
  return `${host}/${country.toLowerCase()}/smarthelp/contact-us?email=paypalme`
}

export function getDomain(clientData) {
  let host = 'https://www.paypal.com'
  if (clientData.isDev) {
    host = 'https://localhost.paypal.com'
  }

  if (clientData.isStage) {
    host = window.location.origin
  }

  if (clientData.isProd) {
    host = 'https://www.paypal.com'
  }
  return host
}
