// @flow

import React from 'react'
import { Subscribe } from 'unstated'
import SellerProfileState from '../state'

type Props = {
  childProps: {
    body: React$Element,
  },
}

class SellerProfileStateContainer extends React.Component<Props> {
  render() {
    const { childProps } = this.props
    return React.cloneElement(childProps.body, { ...this.props })
  }
}

export default (props: Props) => (
  <Subscribe to={[SellerProfileState]}>
    {sellerProfileState => (
      <SellerProfileStateContainer
        sellerProfileState={sellerProfileState}
        childProps={props}
      />
    )}
  </Subscribe>
)
