export const slugAvailabilityAndSuggestions = `
    mutation SlugAvailabilityAndSuggestions($slug: PreferredIdTyped) {
      slugAvailabilityAndSuggestions(slug: $slug) {
        suggestedSlugs
        isPreferredIdAvailable
        userTypedPreferredId
        slugUnavailabilityReasonCode
      }
    }
  `
