import React, { useEffect } from 'react'
import { get } from 'lodash'
import { css } from 'glamor'
import glamorous from 'glamorous'
import { Dialog, Sheet, BodyText, Link } from '@paypalcorp/pp-react'

import clientData from '../../../utils/client-data'

import Events from '../../../lib/analytics/event-tags'
import { tracking } from '../../../lib/analytics'
import { phone6PlusPortraitMaxAndSmaller } from '../../../lib/styles/media-queries'

// content
import getContent from 'pp-react-l10n'
const l10n = getContent('pages/seller/details')

const DialogHeading = glamorous.h3({
  textAlign: 'center',
})

const DialogParagraphContainer = glamorous.div({
  marginBottom: '3rem',
  textAlign: 'center',
  [phone6PlusPortraitMaxAndSmaller]: {
    textAlign: 'left',
  },
})

const customBodyText = css({
  margin: '1rem 5rem',
  [phone6PlusPortraitMaxAndSmaller]: {
    margin: '1rem 0rem',
  },
})

const HandleDetailsDialog = props => {
  const { showDialog, onClose = () => {} } = props

  useEffect(() => {
    showDialog &&
      tracking(Events.public_identity_profile_terms_screen_shown({}))
  }, [showDialog])

  // dialog / sheet
  const isMobile = get(clientData, 'isMobile', false)
  const country = get(clientData, 'locality.country', '').toLowerCase()

  const acceptableUsePolicyLink = (
    <Link
      href={`https://www.paypal.com/${country}/webapps/mpp/ua/acceptableuse-full`}
      target="_blank"
      onClick={() => {
        tracking(Events.public_identity_profile_terms_policy_pressed({}))
      }}
    >
      {l10n('dialog.acceptableUseText')}
    </Link>
  )

  const userAgreementLink = (
    <Link
      href={`https://www.paypal.com/${country}/webapps/mpp/ua/useragreement-full`}
      target="_blank"
      onClick={() => {
        tracking(Events.public_identity_profile_terms_agreement_pressed({}))
      }}
    >
      {l10n('dialog.userAgreementText')}
    </Link>
  )

  const renderPopupContent = () => {
    return (
      <>
        {!isMobile && <DialogHeading>{l10n('dialog.heading')}</DialogHeading>}
        <DialogParagraphContainer>
          <BodyText className={`${customBodyText}`}>
            {l10n('dialog.paragraph1')}
          </BodyText>
          <BodyText className={`${customBodyText}`}>
            {l10n('dialog.paragraph2', {
              acceptableUsePolicyLink,
              userAgreementLink,
            })}
          </BodyText>
        </DialogParagraphContainer>
      </>
    )
  }

  const renderDialog = () => {
    return (
      <Dialog onClose={onClose} isOpen={showDialog} closeOnBackgroundClick>
        {renderPopupContent()}
      </Dialog>
    )
  }

  const renderSheet = () => {
    return (
      <Sheet
        onClose={onClose}
        isOpen={showDialog}
        closeOnBackgroundClick
        title={l10n('dialog.heading')}
      >
        {renderPopupContent()}
      </Sheet>
    )
  }

  return <>{isMobile ? renderSheet() : renderDialog()}</>
}

export default HandleDetailsDialog
