// @flow

const ERROR_PATH = '/seller/error'

const GENERIC_KEY_VALUE = {
  l10nKey: 'generic',
  path: `${ERROR_PATH}`,
  subHeaderKeyNeeded: false,
}

// NOTE:
//  - Add special content or UI for specific error codes below
const SERVICE_ERROR_CODES = {
  ID_ALREADY_TAKEN: {
    ...GENERIC_KEY_VALUE,
    l10nKey: 'idAlreadyTaken',
    path: `${ERROR_PATH}/create/handle`,
    illustrationKey: 'critical',
    customComponentName: 'ErrorCreateHandle',
  },
}

export function showServiceError(
  e: GraphQLError = {},
  history: RouterHistory,
  fromScreen = 'coming from unknown screen',
) {
  const { response: { errors: [error = {}] = [], status = '500' } = {} } = e

  const { name = '', message = '' } = error

  // only handle specific error codes, otherwise fallback to generic
  const errObj = SERVICE_ERROR_CODES[name]
  const hasCustomComp = (errObj && !!errObj.customComponentName) || false
  if (errObj) {
    history.push({
      pathname: errObj.path,
      state: {
        error: {
          ...(hasCustomComp
            ? {
                illustrationKey: errObj.illustrationKey,
                customCompName: errObj.customComponentName,
              }
            : {
                headerKey: `${errObj.l10nKey}`,
                subHeaderKey: errObj.subHeaderKeyNeeded
                  ? `${errObj.l10nKey}`
                  : null,
                ctaButtonKey: `${errObj.l10nKey}`,
              }),
        },
      },
    })
  } else {
    history.push({
      pathname: ERROR_PATH,
      state: {
        error: {
          headerKey: 'generic',
          subHeaderKey: 'generic',
          ctaButtonKey: 'generic',
          status: status,
          instrumentationObj: {
            decisionMsg: '',
            photoFlow: 'profile',
            isMediaservError: false,
            error: {
              fromScreen,
              name,
              message,
            },
          },
        },
      },
    })
  }
}
