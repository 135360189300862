import React, { useState, useEffect } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import PropTypes from 'prop-types'
import glamorous from 'glamorous'
import { get } from 'lodash'
import {
  V2CopyIcon,
  V2ShareFacebookIcon,
  V2ShareMessengerIcon,
  V2ShareTwitterIcon,
  v2blue400,
  v2blue500,
  v2black,
} from '@paypalcorp/pp-react'
import sharing from 'consumerweb-sharing'
import getContent from 'pp-react-l10n'
const l10n = getContent('pages/seller/dashboard')
const sharedL10n = getContent('pages/shared')
import { tracking } from 'lib/analytics'
import {
  smallAndSmaller,
  phoneLandscapeMaxAndSmaller,
} from 'lib/styles/media-queries'
import Events from 'lib/analytics/event-tags'

const ShareContainer = glamorous.div(({ displayInPopup }) => ({
  borderRadius: '50%',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  textAlign: 'center',
  ...(displayInPopup && { justifyContent: 'center' }),
  [phoneLandscapeMaxAndSmaller]: {
    justifyContent: 'space-between',
  },
}))

const ShareButton = glamorous.a(({ displayInPopup }) => ({
  display: 'inline-block',
  margin: '0 40px 0 0',
  textDecoration: 'none',
  minWidth: 50,
  minHeight: 64,
  wordBreak: 'break-all',
  textAlign: 'center',
  verticalAlign: 'top',
  ...(displayInPopup && {
    margin: '0 20px',
    minWidth: 60,
  }),
  '& > span': {
    color: v2blue400,
    '&:hover': {
      color: v2blue500,
    },
  },
  '& > p': {
    fontFamily: 'PayPalSansBig-Regular,"Helvetica Neue",Arial,sans-serif',
    margin: 0,
    color: v2black,
  },
  [smallAndSmaller]: {
    ...(displayInPopup && {
      margin: '0 16px',
    }),
  },
  [phoneLandscapeMaxAndSmaller]: {
    margin: 0,
    ...(displayInPopup && {
      margin: 0,
    }),
  },
}))

const TooltipCopied = glamorous.span({
  padding: '1px 2px 3px',
  display: 'inline-block',
  position: 'relative',
  fontWeight: 500,
  backgroundColor: '#e1e7eb',
  color: 'black !important',
  borderRadius: '2px',
  ':before': {
    content: '""',
    position: 'absolute',
    width: 0,
    height: 0,
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderBottom: '6px solid #e1e7eb',
    top: -6,
    marginLeft: -9, // to align the bottom's square center
    left: '50%',
  },
  '&.tooltip-enter': {
    opacity: 0.01,
  },
  '&.tooltip-enter.tooltip-enter-active': {
    opacity: '1',
    transition: 'opacity 300ms ease-in',
  },
  '&.tooltip-leave.tooltip-leave-active': {
    opacity: 0.01,
    transition: 'opacity 300ms ease-in',
  },
})

export default function SocialShare(props) {
  const {
    copyLink,
    sellerName,
    eventFunction = () => {},
    displayInPopup = false,
  } = props
  const handleShareButtonClick = e => {
    e.preventDefault()
    const clickedBtn = e.currentTarget
    const platform = get(clickedBtn, 'dataset.platform')
    if (eventFunction && typeof eventFunction === 'function') {
      tracking(eventFunction({ social_platform: platform }))
    } else {
      // Generic tracking
      tracking(
        Events.public_identity_profile_share_option_pressed({
          opsel: platform,
        }),
      )
    }
    if (clickedBtn.dataset.copy) {
      // if just copy to clipboard - ignore
      return false
    }
    switch (platform) {
      case 'facebook':
        sharing.shareURLByFacebook(copyLink, 575, 400)
        break
      case 'messenger':
        sharing.shareURLByMessenger(
          copyLink,
          '321983581337375',
          window.location.href,
        )
        break
      case 'twitter':
        sharing.shareByTwitter(
          `${sharedL10n('share.seller_profile.twitter', {
            display_name: sellerName,
          })} ${copyLink}`,
          575,
          400,
        )
        break
      default: // Do nothing
    }
  }

  const [isCopiedShown, setIsCopiedShown] = useState(false)

  useEffect(() => {
    const id = isCopiedShown
      ? setTimeout(() => setIsCopiedShown(false), 2000)
      : null
    return () => clearTimeout(id)
  }, [isCopiedShown, setIsCopiedShown])

  return (
    <ShareContainer displayInPopup={displayInPopup}>
      <CopyToClipboard
        text={copyLink}
        style={{ cursor: 'pointer' }}
        onCopy={() => setIsCopiedShown(true)}
      >
        <ShareButton
          aria-describedby="shareLinkTitle"
          href="/copy"
          data-platform="copy"
          data-test-id="copy-share"
          data-copy="true"
          onClick={handleShareButtonClick}
          displayInPopup={displayInPopup}
        >
          <V2CopyIcon />
          {/* used this approach, because others cause image flickering */}
          <TooltipCopied style={{ display: isCopiedShown ? 'block' : 'none' }}>
            {l10n('copied')}
          </TooltipCopied>
          <p style={{ display: !isCopiedShown ? 'block' : 'none' }}>
            {l10n('copy')}
          </p>
        </ShareButton>
      </CopyToClipboard>
      <ShareButton
        aria-describedby="shareLinkTitle"
        data-platform="facebook"
        data-test-id="facebook-share"
        href={'#'}
        onClick={handleShareButtonClick}
        displayInPopup={displayInPopup}
      >
        <V2ShareFacebookIcon />
        <p>{l10n('facebook')}</p>
      </ShareButton>
      <ShareButton
        aria-describedby="shareLinkTitle"
        data-platform="messenger"
        data-test-id="messenger-share"
        href={'#'}
        onClick={handleShareButtonClick}
        displayInPopup={displayInPopup}
      >
        <V2ShareMessengerIcon />
        <p>{l10n('messenger')}</p>
      </ShareButton>
      <ShareButton
        aria-describedby="shareLinkTitle"
        data-platform="twitter"
        data-test-id="twitter-share"
        href={'#'}
        onClick={handleShareButtonClick}
        displayInPopup={displayInPopup}
      >
        <V2ShareTwitterIcon />
        <p>{l10n('twitter')}</p>
      </ShareButton>
    </ShareContainer>
  )
}

SocialShare.propTypes = {
  copyLink: PropTypes.string.isRequired,
  sellerName: PropTypes.string.isRequired,
  eventFunction: PropTypes.func,
  displayInPopup: PropTypes.bool,
}
