import React, { useState, useRef, useEffect } from 'react'
import glamorous from 'glamorous'
import { css } from 'glamor'
import {
  HeadingText,
  BodyText,
  CaptionText,
  RadioButton,
} from '@paypalcorp/pp-react'
import getContent from 'pp-react-l10n'
import Events from 'lib/analytics/event-tags'
import { tracking } from 'lib/analytics'
import { phone6PlusPortraitMaxAndSmaller } from 'lib/styles/media-queries'
import { showServiceError } from './../error/util'
import SaveButton from '../shared/save-button'

const l10n = getContent('pages/seller/settings')

const Container = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
})

const Heading = css({
  marginTop: 80,
  [phone6PlusPortraitMaxAndSmaller]: {
    marginTop: 20,
  },
})

const SubHeading = css({
  textAlign: 'left',
  margin: '16px 0 20px',
})

const Footer = css({
  textAlign: 'left',
  margin: '20px 0 0',
})

const Legend = glamorous.span({
  border: 0,
  clip: 'rect(1px, 1px, 1px, 1px)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  width: '1px',
})

function LocationSetting(props) {
  const {
    sellerProfileState: {
      state: {
        sellerProfileDetails: {
          id: businessHandle = '',
          privacy_settings: { location: businessLocationFormat = [] } = {},
          supported_location_privacy_settings: locationOptions = [],
        } = {},
      } = {},
    } = {},
  } = props
  const { editSellerSettings } = props.sellerProfileState

  useEffect(() => {
    tracking(Events.public_identity_edit_location_screen_shown({}))
  }, [])

  /**
   * location
   *   1. ['STATE', 'CITY']
   *   2. ['STATE']
   *   3. []
   */
  const options = locationOptions.map(setting => ({
    label: setting.display_address || l10n('edit.location.option.none'),
    value: setting.format.toString(),
  }))
  const [value, setValue] = useState(
    businessLocationFormat.toString() || 'NONE',
  )
  const { current: initialValue } = useRef(value)
  const isChanged = value !== initialValue

  const handleChangeInput = e => {
    setValue(e.target.value)
  }

  const handleSave = async () => {
    let opsel = 'hide'
    if (value.includes('STATE') && value.includes('CITY')) {
      opsel = 'city'
    } else if (value.includes('STATE') && !value.includes('CITY')) {
      opsel = 'state'
    }
    tracking(
      Events.public_identity_edit_location_save_pressed({
        opsel,
      }),
    )

    const valueObj = [
      {
        op: 'replace',
        path: '/privacy_settings/location',
        value: value.split(','),
      },
    ]

    try {
      await editSellerSettings(valueObj, businessHandle)
      props.history.goBack()
    } catch (err) {
      showServiceError(
        err,
        props.history,
        'seller-profile-settings-edit-location',
      )
    }
  }

  return (
    <Container data-test-id="seller-settings-location-screen">
      <HeadingText
        size="md"
        role="heading"
        aria-level="1"
        className={`${Heading}`}
      >
        {l10n('edit.location.header')}
      </HeadingText>
      <BodyText className={`${SubHeading}`}>
        {l10n('edit.location.subheader')}
      </BodyText>
      <RadioButton
        name="businessLocation"
        label={<Legend>{l10n('businessLocation')}</Legend>}
        value={value}
        options={options}
        onChange={handleChangeInput}
        data-test-id="seller-settings-location-input"
      />
      <CaptionText className={`${Footer}`}>
        {l10n('edit.location.footer')}
      </CaptionText>
      {isChanged && <SaveButton handleSave={handleSave} />}
    </Container>
  )
}

export default LocationSetting
