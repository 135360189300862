import React, { useEffect, useState } from 'react'
import glamorous from 'glamorous'
import { BodyText, HeadingText } from '@paypalcorp/pp-react'
import HandleMoreInfoLink from '../details/handle-more-info-link'
import { showServiceError } from './util'
import { tracking } from '../../../lib/analytics'
import Events from '../../../lib/analytics/event-tags'
import { phone6PlusPortraitMaxAndSmaller } from '../../../lib/styles/media-queries'
import UserInputHandle from '../../personal-profile-handle/common-components/user-input-handle'
import { PROFILE_TYPE } from '../../../utils/constants'
// content
import getContent from 'pp-react-l10n'
const l10n = getContent('pages/seller/error')

const Container = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start', // 'center',
  marginTop: '48px',
})

const GenericInputContainerPadding = glamorous.div({
  margin: '24px 0',
  width: '100%',
})

const HeadingContainer = glamorous.div({
  margin: '12px 0',
})

const SubHeaderContainer = glamorous.div({
  marginTop: 0,
  marginBottom: 32,
})

const ButtonContainer = glamorous.div({
  display: 'flex',
  margin: 'auto',
})

const ContinueButton = glamorous.button({
  margin: 'auto',
  width: '310px',
  [phone6PlusPortraitMaxAndSmaller]: {
    width: '290px',
  },
})

const ErrorCreateHandle = props => {
  const {
    sellerProfileState: {
      state: {
        businessHandle,
        businessName,
        isValidBusinessHandle,
        isSuggestedBusinessHandle,
      } = {},
    } = {},
  } = props

  const [takenHandle, setTakenHandle] = useState(businessHandle)
  const [isSuggestedSlug, setIsSuggestedSlug] = useState(false)
  const [handleName, setHandleName] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [inputs, setInputs] = useState({
    businessHandle: '',
  })

  const helperText = l10n('main.businessHandleInput.helper')

  useEffect(() => {
    tracking(Events.public_identity_profile_username_duplicate_screen_shown({}))
  }, [])

  useEffect(() => {
    const updates = {
      ...inputs,
      businessHandle: handleName,
      isValidBusinessHandle: isValid,
      isSuggestedBusinessHandle: isSuggestedSlug,
    }
    setInputs(updates)
    props.sellerProfileState.updateBusinessDetails(updates)
  }, [handleName, isValid])

  const revealNextCTA = () => {
    return inputs.businessHandle !== '' && isValidBusinessHandle
  }

  const createSellerProfile = () => {
    const opsel = isSuggestedBusinessHandle ? 'suggested' : 'custom'

    tracking(
      Events.public_identity_profile_username_duplicate_finish_pressed({
        opsel,
      }),
    )
    props.sellerProfileState.createSellerProfile(onSuccessCreate, onErrorCreate)
  }

  const onSuccessCreate = () => {
    props.history.replace({
      pathname: '/seller/success',
      state: {
        businessHandle,
        businessName,
      },
    })
  }

  const onErrorCreate = e => {
    setTakenHandle(inputs.businessHandle)
    const updates = {
      ...inputs,
      businessHandle: '',
      isValidBusinessHandle: false,
    }
    setInputs(updates)
    props.sellerProfileState.updateBusinessDetails(updates)
    showServiceError(e, props.history)
  }

  return (
    <Container>
      <HeadingContainer>
        <HeadingText size="md" role="heading" aria-level="1">
          {l10n('create.handle.header', { handle: takenHandle })}
        </HeadingText>
      </HeadingContainer>
      <SubHeaderContainer>
        <BodyText size="md">{l10n('create.handle.subHeader')}</BodyText>
      </SubHeaderContainer>
      <HandleMoreInfoLink />
      <GenericInputContainerPadding>
        <UserInputHandle
          {...props}
          handleName={handleName}
          setHandleName={setHandleName}
          setIsValid={setIsValid}
          setIsSuggestedSlug={setIsSuggestedSlug}
          helperText={helperText}
          profileType={PROFILE_TYPE['seller']}
        />
      </GenericInputContainerPadding>
      {revealNextCTA() && (
        <ButtonContainer>
          <ContinueButton
            className="vx_btn"
            label="Next"
            onClick={createSellerProfile}
          >
            {l10n('create.handle.continue')}
          </ContinueButton>
        </ButtonContainer>
      )}
    </Container>
  )
}

export default ErrorCreateHandle
