// @flow

export const SPINNER_ID = 'core-white-overpanel-spinner'

export const addLoader = function() {
  const overlayedElement = document.getElementById(SPINNER_ID)

  if (overlayedElement) {
    overlayedElement.style.removeProperty('display')
  }
}

export const removeLoader = function() {
  const elementWithSpinner = document.getElementById(SPINNER_ID)

  if (elementWithSpinner) {
    elementWithSpinner.style.setProperty('display', 'none')
  }
}
