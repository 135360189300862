// @flow
/**
 * Grabs the data we dump from the server into the DOM and exports them as a singleton.
 * This allows us to conveniently dump server data into the dom and use this to load
 * that data into our app.
 */
import debug from 'debug'

const clientData: ClientData = {}
function init() {
  const dataNode = document.getElementById('client-data')

  const clientDataString = (dataNode && dataNode.innerHTML) || '{}'

  try {
    Object.assign(clientData, JSON.parse(clientDataString))
    // one reason we're removing this is so cypress doesn't capture
    // the script tag when searching for text on the page.
    dataNode.parentElement.removeChild(dataNode)
    debug('ppme:client-data:success')('load', clientData)
  } catch (error) {
    debug('ppme:client-data:error')('load', error)
  }
}

export default clientData
export { init }
