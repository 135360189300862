import { get } from 'lodash'
import { Container } from 'unstated'
import clientData from 'utils/client-data'

class VisaPlusProfileState extends Container {
  state = {
    evaluateProfileDetails: get(clientData, 'visaPlusData.eligibility', {}),
    profile: get(clientData, 'visaPlusData.profile', {}),
    userCIPVerified: get(clientData, 'visaPlusData.userCIPVerified', false),
  }

  /**
   * User entered payname to be sent to the server, saved in backend
   * and server response to be updated in the state.
   * @param {*} profileId
   */
  createPayname = async () => {
    const _response = await fetch(
      `${clientData.requestURI}/visa-plus/createPayname`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': clientData.csrf,
        },
      },
    )
    const { createPaynameResponse } = await _response.json()

    // NOTE: Need to check why clientData did not work here. Hence, had to return response.
    this.setState({
      ...this.state,
      profile: {
        ...this.state.profile,
        profileId: get(createPaynameResponse, 'profileId', {}),
        profileStatus: get(createPaynameResponse, 'profileStatus', {}),
        error: get(createPaynameResponse, 'error', {}),
        isSuccessful: get(createPaynameResponse, 'isSuccessful', {}),
      },
    })
  }
}

export default VisaPlusProfileState
