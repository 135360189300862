import './init-webpack'
import './css'
import fetch from 'unfetch'
import 'regenerator-runtime/runtime'

// setup polyfills
window.Promise = window.Promise || Promise
window.fetch = window.fetch || fetch

// wait for the dom to be loaded before attempting to start up the app
document.addEventListener('DOMContentLoaded', () => {
  require('./bootstrap')
  require('./render')
})
