// @flow

import React, { useState, useEffect } from 'react'
import glamorous from 'glamorous'

import SellerProfileState from '../state'

import Events from 'lib/analytics/event-tags'
import { tracking } from 'lib/analytics'
import { phone6PlusPortraitMaxAndSmaller } from 'lib/styles/media-queries'

import { isPreDefinedCoverPhoto } from 'utils/image-utils'

import { SELLER_FLOW } from '../../../utils/constants'

import CoverPhoto, {
  CONTAINER_HEIGHT_PX as COVER_PHOTO_HEIGHT_PX,
  CONTAINER_WIDTH_PX as COVER_PHOTO_WIDTH_PX,
} from './cover-photo'
import ProfileCircle from './profile-photo'

const Container = glamorous.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  height: COVER_PHOTO_HEIGHT_PX,
  [phone6PlusPortraitMaxAndSmaller]: {
    height: '160px',
  },
})

const CoverContainer = glamorous.div(({ isDraggable = false }) => ({
  zIndex: 0,
  ...(!isDraggable && { pointerEvents: 'none' }),
}))

type Props = {
  sellerProfileState: SellerProfileState,
}

const calculatePan = (pan: string, deltaPan: string) => {
  return String(Math.min(Math.max(Number(pan) + Number(deltaPan), 0), 100))
}

function CoverPan(props: Props) {
  const {
    sellerProfileState: {
      state: {
        photoSettings: {
          initialCoverPhoto = undefined,
          coverPhoto = '',
          hasCoverPhoto = false,
          cachedCoverPhoto = undefined,
          proposedCoverPhoto,
          cachedCoverPan = '50',
          coverPhotoPan = '50',
          deltaPan = '0',
          isDragging = false,
        } = {},
      } = {},
    } = {},
    location: { state: { flow = SELLER_FLOW.create } = {} } = {},
  } = props

  const { isDraggable = false } = props

  // imagePreviewUrl is used to hold the current photo the user is seeing
  let imagePreviewUrl = hasCoverPhoto ? coverPhoto : initialCoverPhoto
  if (isDraggable) {
    // here we should check the file selected photo instead of what is cached
    imagePreviewUrl = proposedCoverPhoto
  } else if (cachedCoverPhoto) {
    // in this case we want to load the cached photo because the current 'saved'
    // photo is now cached
    imagePreviewUrl = cachedCoverPhoto
  }

  const [normalizedHeight, setNormalizedHeight] = useState(0)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const img = new Image()
    img.onload = () => {
      setNormalizedHeight(img.height / (img.width / COVER_PHOTO_WIDTH_PX))

      setIsLoaded(true)
    }
    img.onerror = () => {
      // do not enable dragging due to lack of normalized height
    }
    img.src = initialCoverPhoto // imagePreviewUrl

    return () => {
      img.onload = () => {}
    }
  }, [])

  const onDrag = (e: SyntheticEvent<HTMLButtonElement>) => {
    // assumes DOM_DELTA_PIXEL delta mode but works sufficiently with DOM_DELTA_LINE
    // and currently all browsers use DOM_DELTA_PIXEL delta mode except for FireFox
    const newDeltaPan = String(
      // calculate coverPhotoPan offset as a percentage of excess height relative to container
      Math.ceil(
        // note that normalizedHeight > COVER_PHOTO_HEIGHT_PX in all cases
        ((e.deltaY || 0) / (normalizedHeight - COVER_PHOTO_HEIGHT_PX)) * -100,
      ),
    )

    // do not update state if there is no material movement yet
    if (newDeltaPan !== deltaPan) {
      props.sellerProfileState.pan(coverPhotoPan, newDeltaPan, true)
    }
  }

  const onDragEnd = () => {
    // merge deltaPan into pan in order to allow for further dragging
    props.sellerProfileState.pan(
      calculatePan(coverPhotoPan, deltaPan),
      '0',
      false,
    )
  }

  const onDragCancel = () => {
    // reset back to previous pan before dragging began
    props.sellerProfileState.pan(coverPhotoPan, '0', false)
  }

  const onBackgroundPhotoClick = () => {
    tracking(Events.public_identity_profile_photo_edit_cover_pressed({}))
    props.history.push({
      pathname: '/seller/photos/cover/select',
      state: {
        flow,
      },
    })
  }

  // NOTE: The difference between this and the ppme implementation is that,
  //       in ppme the data is saved to the server before they move on, the
  //       'saving' portion is at the end of the flow when we call create a
  //       seller profile.

  return (
    <>
      <Container>
        <CoverContainer isDraggable={!isDragging}>
          <CoverPhoto
            photoUrl={imagePreviewUrl}
            photoVerticalPan={
              isDraggable
                ? calculatePan(coverPhotoPan, deltaPan)
                : calculatePan(cachedCoverPan, deltaPan)
            }
            isDraggable={
              isDraggable &&
              !isPreDefinedCoverPhoto(imagePreviewUrl) &&
              isLoaded &&
              normalizedHeight > COVER_PHOTO_HEIGHT_PX
            }
            onDrag={onDrag}
            onDragEnd={onDragEnd}
            onDragCancel={onDragCancel}
            onEdit={onBackgroundPhotoClick}
            isEditable={!isDraggable}
          />
        </CoverContainer>
        <ProfileCircle
          {...props}
          isEditable={flow === SELLER_FLOW.create && !isDraggable}
        />
      </Container>
    </>
  )
}

export default CoverPan
